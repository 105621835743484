import React, { useEffect, useState, useMemo } from "react";
import {
  Button,
  Pane,
  Paragraph,
  Dialog,
  FilePicker,
  FileCard,
  FileUploader,
} from "evergreen-ui";
import axios from "axios";
import AddSeguro from "./AddSeguro";
import CambiarPoliza from "./CambiarPoliza";
import {
  FaFileDownload,
  FaTrashAlt,
  FaMoneyBill,
  FaFileImport,
} from "react-icons/fa";
import { motion } from "framer-motion";

const UserSeguroDetail = ({ usuario }) => {
  const [seguros, setSeguros] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAddSeguro, setShowAddSeguro] = useState(false);
  const [showChangePoliza, setShowChangePoliza] = useState(null);

  useEffect(() => {
    setLoading(true);
    axios
      .get(process.env.REACT_APP_API_URL + "/seguro/usuario/" + usuario._id)
      .then((res) => {
        console.log(res.data);
        setSeguros(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  }, [usuario._id]);

  const groupSegurosByType = useMemo(() => {
    const segurosByType = {};
    seguros.forEach((seguro) => {
      if (!segurosByType[seguro.tipo]) {
        segurosByType[seguro.tipo] = [];
      }
      segurosByType[seguro.tipo].push(seguro);
    });
    return segurosByType;
  }, [seguros]);

  const linkPoliza = (seguro) => {
    return process.env.REACT_APP_API_URL + "/storage/poliza/" + seguro.poliza;
  };

  const handleDeleteSeguro = (seguroId) => {
    axios
      .delete(process.env.REACT_APP_API_URL + "/seguro/" + seguroId)
      .then((res) => {
        console.log(res.data);
        setSeguros(seguros.filter((seg) => seg._id !== seguroId));
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleRegistrarPago = (seguroId) => {
    axios
      .post(process.env.REACT_APP_API_URL + "/seguro/pago/" + seguroId)
      .then((res) => {
        console.log(res.data);
        const updatedSeguros = seguros.map((seg) =>
          seg._id === seguroId
            ? { ...seg, siguiente_pago: res.data.siguiente_pago }
            : seg
        );
        setSeguros(updatedSeguros);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <Pane
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      padding={20}
    >
      <Pane
        display="flex"
        flexDirection="row"
        width="100%"
        padding={20}
        height=""
        gap={10}
      >
        {loading ? (
          <Paragraph
            color="gray"
            textAlign="center"
            fontWeight="bold"
            size={500}
          >
            Cargando seguros...
          </Paragraph>
        ) : seguros.length === 0 ? (
          <Paragraph
            color="gray"
            textAlign="center"
            fontWeight="bold"
            size={500}
          >
            {Math.random() > 0.5
              ? "No se encontraron seguros para este usuario"
              : "Este usuario no tiene seguros asociados"}
          </Paragraph>
        ) : (
          Object.entries(groupSegurosByType).map(([tipo, segurosOfType]) => (
            <Pane key={tipo} width="100%">
              <Paragraph
                color="#7e57c2"
                textAlign="center"
                fontWeight="bold"
                size={800}
                marginBottom={10}
                fontSize={25}
              >
                {tipo}
              </Paragraph>
              <Pane
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                gap={15}
                flexWrap="wrap"
              >
                {segurosOfType.map((seguro) => (
                  <Pane
                    key={seguro._id}
                    width="300px"
                    padding={10}
                    borderRadius={5}
                    display="flex"
                    flexDirection="column"
                    background="
                    linear-gradient(180deg, #f6f6f6 60%, #7e57c2 100%)
                    "
                    minHeight="200px"
                    gap={20}
                    justifyContent="space-around"
                  >
                    <Pane display="flex" flexDirection="column" gap={5}>
                      <Paragraph size={300} color="#555">
                        ID Seguro: {seguro._id}
                      </Paragraph>
                      <Paragraph size={400} color="#000">
                        Aseguradora: {seguro.aseguradora}
                      </Paragraph>
                      <Paragraph size={400} color="#000">
                        Producto: {seguro.producto}
                      </Paragraph>
                      <Paragraph size={400} color="#000">
                        Periodicidad de pago: {seguro.frecuencia_pago}
                      </Paragraph>
                      <Paragraph size={400} color="#000">
                        Inicio de vigencia:{" "}
                        {new Date(seguro.fecha_inicio).toLocaleDateString()}
                      </Paragraph>
                      <Paragraph size={400} color="#000">
                        Proximo pago:{" "}
                        {new Date(seguro.siguiente_pago).toLocaleDateString()}
                      </Paragraph>
                    </Pane>
                    <Pane
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-around"
                      alignItems="center"
                      gap={10}
                    >
                      <Button
                        iconBefore={FaTrashAlt}
                        color="red"
                        onClick={() => handleDeleteSeguro(seguro._id)}
                      >
                        Eliminar
                      </Button>
                      <Button
                        iconBefore={FaFileDownload}
                        onClick={() => {
                          window.open(linkPoliza(seguro), "_blank");
                        }}
                        color="green"
                      >
                        Descargar Póliza
                      </Button>
                    </Pane>
                    <Pane
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-around"
                      alignItems="center"
                      gap={10}
                    >
                      <Button
                        onClick={() => handleRegistrarPago(seguro._id)}
                        iconBefore={FaMoneyBill}
                        color="green"
                      >
                        Registrar Pago
                      </Button>
                      <Button
                        onClick={() => setShowChangePoliza(seguro._id)}
                        appearance="primary"
                        backgroundColor="#7e57c2"
                        borderColor="#7e57c2"
                        iconBefore={FaFileImport}
                      >
                        Cambiar poliza
                      </Button>
                    </Pane>
                    {showChangePoliza && (
                      <CambiarPoliza
                        showChangePoliza={showChangePoliza !== null}
                        setShowChangePoliza={setShowChangePoliza}
                        seguro={seguros.find(
                          (seg) => seg._id === showChangePoliza
                        )}
                        onUpdated={() => {
                          axios
                            .get(
                              process.env.REACT_APP_API_URL +
                                "/seguro/usuario/" +
                                usuario._id
                            )
                            .then((res) => {
                              setSeguros(res.data);
                            })
                            .catch((error) => {
                              console.error("Error reloading seguros", error);
                            });
                        }}
                      />
                    )}
                  </Pane>
                ))}
              </Pane>
            </Pane>
          ))
        )}
      </Pane>
      <Button
        appearance="primary"
        backgroundColor="#7e57c2"
        borderColor="#7e57c2"
        onClick={() => setShowAddSeguro(true)}
      >
        Agregar Seguro
      </Button>
      {showAddSeguro && (
        <AddSeguro
          showAddSeguro={showAddSeguro}
          setShowAddSeguro={setShowAddSeguro}
          usuario={usuario}
          onUpdated={() => {
            axios
              .get(
                process.env.REACT_APP_API_URL + "/seguro/usuario/" + usuario._id
              )
              .then((res) => {
                console.log(res.data);
                setSeguros(res.data);
              })
              .catch((error) => {
                console.log("error", error);
              });
          }}
        />
      )}
    </Pane>
  );
};

export default UserSeguroDetail;
