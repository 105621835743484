import React from "react";
import {
  Pane,
  Heading,
  Dialog,
  TextInput,
} from "evergreen-ui";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";

const CardNameChange = ({
  setShowCardNameChange,
  selectedUser,
  selectedCard,
  onUpdateCard,
}) => {
  const validationSchema = Yup.object({
    name: Yup.string().required("Campo requerido"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try {
        axios
          .post(
            process.env.REACT_APP_API_URL +
              "/users/" +
              selectedUser._id +
              "/cards/" +
              selectedCard._id +
              "/changename",

            {
              cardNumber: selectedCard.cardNumber,
              nombre: values.name,
            }
          )
          .then((res) => {
            console.log(res);
            setShowCardNameChange(false);
            window.location.reload();
          });
      } catch (error) {
        console.log("error", error);
      }
    },
  });

  return (
    <Dialog
      isShown={true}
      title="Cambiar nombre de tarjeta"
      onCloseComplete={() => setShowCardNameChange(false)}
      onConfirm={() => formik.handleSubmit()}
      confirmLabel="Cambiar"
      cancelLabel="Cancelar"
    >
      <Pane
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Heading>Ingresa el nuevo nombre de la tarjeta</Heading>
        <TextInput
          name="name"
          placeholder="Nombre"
          onChange={formik.handleChange}
          value={formik.values.name}
          marginBottom={20}
          isInvalid={formik.touched.name && formik.errors.name}
          validationMessage={formik.errors.name}
        />
      </Pane>
    </Dialog>
  );
};

export default CardNameChange;
