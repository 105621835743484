import React from 'react';
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { initAxiosInterceptors } from '../util/authHelper';
import {
    Container,
    Box,
    Typography,
    TextField,
    Button,
    CircularProgress,
    Alert,
    AlertTitle,
    Snackbar,
    IconButton,
} from '@mui/material';

const PasswordReset = () => {
    initAxiosInterceptors();

    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');

    const handlePasswordReset = () => {
        setLoading(true);
        setError('');
        if (password !== password2) {
            setError('Las contraseñas no coinciden');
            setLoading(false);
            return;
        }
        const data = {
            password: password,
            token: window.location.pathname.split('/')[2],
        };
        axios
            .post(process.env.REACT_APP_API_URL + '/users/resetpassword', data)
            .then((res) => {
                setLoading(false);
                setMessage(res.data.message);
                setOpen(true);
                // set a timer to redirect to login
                setTimeout(() => {

                window.location.href = '/';
                }
                , 4000);
            })
            .catch((err) => {
                setLoading(false);
                setError(err.response.data.error);
            });
    }




    return (
        <Container>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                height="100vh"
            >
                <Typography variant="h4" gutterBottom>
                    Restablecer contraseña
                </Typography>
                <TextField
                    label="Contraseña"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    onChange={(e) => setPassword(e.target.value)}
                />
                <TextField
                    label="Repetir contraseña"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    onChange={(e) => setPassword2(e.target.value)}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePasswordReset}
                >
                    {loading ? <CircularProgress size={24} /> : 'Restablecer'}
                </Button>
                {error && (
                    <Alert severity="error" style={{ marginTop: '10px' }}>
                        <AlertTitle>Error</AlertTitle>
                        {error}
                    </Alert>
                )}
                <Snackbar
                    open={open}
                    autoHideDuration={8000}
                    onClose={() => setOpen(false)}
                    message={message}
                    action={
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={() => setOpen(false)}
                        >
                            X
                        </IconButton>
                    }
                />
            </Box>
        </Container>
    );
}

export default PasswordReset;
        