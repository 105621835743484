import Axios from 'axios';

const TOKEN_KEY = 'TOKEN_KEY';

export const getToken = () => {
    return localStorage.getItem(TOKEN_KEY);
}

export const setToken = (token) => {
    localStorage.setItem(TOKEN_KEY, token);
}

export const removeToken = () => {
    localStorage.removeItem(TOKEN_KEY);
}

export const isAuth = async () => {
    const token = getToken();
    if(token) {
        const config = {
            headers: {
                Authorization : `Bearer ${token}`
            }
        }
        Axios.post(process.env.REACT_APP_API_URL+'/auth/verify', {}, config)
        .then(res => {
            return res.data;
        })
    }
}

export const initAxiosInterceptors = () => {
    Axios.interceptors.request.use(config => {
        const token = getToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    });
}

export const updateOnCloseSession = (callback) => {
    Axios.interceptors.response.use(
        response => response,
        error => {
            if (error.response.status === 401) {
                removeToken();
                window.location.reload();
                callback();
                
            }
            return Promise.reject(error);
        }
    )
}



