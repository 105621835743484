import React from "react";
import {
  Button,
  TextInput,
  Pane,
  Heading,
  SearchInput,
  toaster,
  Combobox,
  Table,
} from "evergreen-ui";
import { initAxiosInterceptors } from "../../../util/authHelper";
import { useState, useEffect } from "react";
import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";
import Card from "./card";
import { useFormik } from "formik";

initAxiosInterceptors();

const Movimientos = () => {
  const [users, setUsers] = useState({});
  const [usersNamesArray, setUsersNamesArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searcher, setSearcher] = useState("");
  const [selectedUser, setSelectedUser] = useState();
  const [userCards, setUserCards] = useState([]);
  const [movimientos, setMovimientos] = useState([{}]);

  const formik = useFormik({
    initialValues: {
      Monto: "",
      Concepto: "",
    },
    onSubmit: (values) => {
      if (selectedUser === undefined) {
        values.id_usuario = "";
      } else {
        values.id_usuario = selectedUser.id;
      }

      values.Monto = parseFloat(values.Monto);
      if (values.Concepto === "Pago de servicios") {
        values.Monto = -Math.abs(values.Monto);
      }

      console.log("values", values);
      axios
        .post(
          process.env.REACT_APP_API_URL + "/impuls",
          JSON.stringify(values),
          { headers: { "Content-Type": "application/json" } }
        )
        .then((res) => {
          console.log("res", res);
          toaster.success("Registro exitoso");
          formik.resetForm();
          fetchMovimientos();
        })
        .catch((err) => {
          console.log("err", err);
          toaster.danger("Error al registrar");
        });
    },
  });

  const tipos = [
    "Deposito a tarjetas",
    "Retiro",
    "Pago de servicios",
    "Compra de tarjetas",
  ];

  const fetchUsers = async () => {
    try {
      var users = {};
      const res = await axios.get(process.env.REACT_APP_API_URL + "/users/");
      users = res.data;
      //filter users eliminar role admin
      users = users.filter((user) => user.role !== "admin");
      setUsers(users);

      //pon los nombres de usuarios con su apellido en un arreglo de objetos para el combobox
      var usersNamesArray = [];
      users.forEach((user) => {
        usersNamesArray.push({
          label: user.nombre + " " + user.apellido,
          id: user._id,
        });
      });
      setUsersNamesArray(usersNamesArray);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchMovimientos = async () => {
    try {
      var movimientos = {};
      const res = await axios.get(process.env.REACT_APP_API_URL + "/impuls/");
      movimientos = res.data;
      //filter users eliminar role admin

      //ordena los movimientos por fecha dada por mongoDB
      movimientos.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      setMovimientos(movimientos);

      setLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchMovimientos();
  }, []);

  useEffect(() => {
    var ArregloNumeros = ["No hay tarjetas registradas"];
    if (selectedUser) {
      users.filter((user) => {
        if (user._id === selectedUser.id) {
          const cards = user.cards;
          ArregloNumeros = [];
          cards.forEach((card) => {
            ArregloNumeros.push(card.last4);
          });
          console.log("userCards", ArregloNumeros);
        }
      });
      setUserCards(ArregloNumeros);
    }

    setUserCards(ArregloNumeros);
  }, [selectedUser, users]);

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <Pane>
      <Pane
        elevation={3}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        padding={35}
        color="white"
        alignItems="center"
        borderRadius={10}
        zIndex={1}
        className="impuls-header"
      >
        <Heading size={800} color="white">
          {" "}
          Panel de administración de ImpulsPay{" "}
        </Heading>
      </Pane>

      <Pane
        display="grid"
        gridTemplateColumns="300px 1fr"
        paddingTop={20}
        gap={20}
        height="100%"
      >
        <Pane
          display="flex"
          elevation={3}
          borderRadius={10}
          flexDirection="column"
          padding={35}
          gap={20}
        >
          <Heading>Registrar movimiento</Heading>

          <motion.div
            style={{ display: "flex", flexDirection: "column", gap: 10 }}
          >
            <TextInput
              name="Monto"
              placeholder="Monto"
              onChange={formik.handleChange}
              value={formik.values.Monto}
              whiteSpace="nowrap"
              width={"105%"}
            />
            <TextInput
              name="Concepto"
              placeholder="Concepto"
              onChange={formik.handleChange}
              value={formik.values.Concepto}
              width={"105%"}
            />
            <Combobox
              items={tipos}
              onChange={(selected) => {
                formik.setFieldValue("Tipo", selected);
              }}
              placeholder="Tipo"
            />
            {formik.values.Tipo === "Deposito a tarjetas" ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                  width: "105%",
                }}
              >
                <Combobox
                  items={usersNamesArray}
                  onChange={(selected) => {
                    console.log("selected", selected);
                    setSelectedUser(selected);
                  }}
                  placeholder="Usuario"
                  itemToString={(item) => (item ? item.label : "")}
                />
                {userCards.length > 0 ? (
                  <Combobox
                    items={userCards}
                    onChange={(selected) => {
                      console.log("selected", selected);
                      formik.setFieldValue("Tarjeta", selected);
                    }}
                    placeholder="Tarjeta"
                  />
                ) : null}
              </div>
            ) : null}
            <Button
              appearance="primary"
              intent="success"
              type="submit"
              marginTop={10}
              width={"105%"}
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              Registrar
            </Button>
          </motion.div>
        </Pane>
        <Pane
          display="flex"
          elevation={3}
          borderRadius={10}
          flexDirection="column"
          padding={35}
          gap={20}
        >
          <Heading>Movimientos</Heading>

          <Table
          >
            <Table.Head>
              <Table.TextHeaderCell>Usuario</Table.TextHeaderCell>
              <Table.TextHeaderCell>Tarjeta</Table.TextHeaderCell>
              <Table.TextHeaderCell>Monto</Table.TextHeaderCell>
              <Table.TextHeaderCell>Concepto</Table.TextHeaderCell>
              <Table.TextHeaderCell>Tipo</Table.TextHeaderCell>
              <Table.TextHeaderCell>Fecha</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
              {movimientos.map((movimiento) => (
                <Table.Row key={movimiento._id}>
                  <Table.TextCell>{movimiento.id_usuario}</Table.TextCell>
                  <Table.TextCell>{movimiento.Tarjeta}</Table.TextCell>
                  <Table.TextCell>{movimiento.Monto}</Table.TextCell>
                  <Table.TextCell>{movimiento.Concepto}</Table.TextCell>
                  <Table.TextCell>{movimiento.Tipo}</Table.TextCell>
                  <Table.TextCell>
                    {new Date(movimiento.createdAt).toLocaleString("es-ES")}
                  </Table.TextCell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Pane>
      </Pane>
    </Pane>
  );
};

export default Movimientos;
