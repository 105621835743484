import React from "react";
import {
  Pane,
  Heading,
  Paragraph,
  Dialog,
  TextInput,
  toaster,
  
} from "evergreen-ui";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";

const AddCardDialog = ({ setShowAddCardDialog, selectedUser, onUpdateCard }) => {
  const validationSchema = Yup.object({
    name: Yup.string().required("Campo requerido"),
  });

  const [isConfirmationDialogVisible, setConfirmationDialogVisible] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setConfirmationDialogVisible(true);
    },
  });

  const handleConfirm = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL +
          "/users/" +
          selectedUser._id +
          "/solicitar",
        {
          name: formik.values.name,
        }
      );

      setShowAddCardDialog(false);
      onUpdateCard(response.data.updatedUser);
    

    } catch (error) {
      console.log("error", error);
      if (error.response) {
        toaster.danger(error.response.data.message);
      } else {
        toaster.danger("Error al solicitar la tarjeta");
      }
    }
  };

  const handleCancel = () => {
    setConfirmationDialogVisible(false);
  };

  return (
    <React.Fragment>
      <Dialog
        isShown={isConfirmationDialogVisible}
        title="Confirmar solicitud"
        intent="success"
        onCloseComplete={handleCancel}
        confirmLabel="Confirmar"
        onConfirm={handleConfirm}
        cancelLabel="Cancelar"
      >
        ¿Estás seguro de que deseas solicitar la nueva tarjeta?
      </Dialog>

      <Dialog
        isShown={true}
        title="Solicitar nueva tarjeta"
        onCloseComplete={() => setShowAddCardDialog(false)}
        onConfirm={() => formik.handleSubmit()}
        confirmLabel="Solicitar"
        cancelLabel="Cancelar"
      >
        <Pane
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={20}
        >
          <Heading>Ingresa el nombre para la tarjeta nueva</Heading>
          <TextInput
            name="name"
            placeholder="Nombre"
            onChange={formik.handleChange}
            value={formik.values.name}
            marginBottom={20}
            isInvalid={formik.touched.name && formik.errors.name}
            validationMessage={formik.errors.name}
          />

          <Pane
            backgroundColor="#F5F5F5"
            width="100%"
            padding={20}
            borderRadius={5}
            boxShadow="0px 0px 5px rgba(0, 0, 0, 0.25)"
          >
            <Paragraph size={500} color="muted" textAlign="center">
              Nota: La tarjeta será enviada a la dirección PREDETERMINADA del usuario.
            </Paragraph>
          </Pane>
        </Pane>
      </Dialog>
    </React.Fragment>
  );
};

export default AddCardDialog;