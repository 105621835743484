import React, { useEffect, useState, useRef } from "react";
import {
  Pane,
  Button,
  Heading,
  toaster,
  Table,
  majorScale,
  Select
} from "evergreen-ui";
import { FaCheck, FaTimes } from "react-icons/fa";
import { motion } from "framer-motion";
import { useFormik } from "formik";
import axios from "axios";
import {
  initAxiosInterceptors,
  updateOnCloseSession,
} from "../../../util/authHelper";
import AcceptCardDialog from "./Dialogs/AceptarNuevaTarjeta";

updateOnCloseSession(() => (window.location.href = "/"));
initAxiosInterceptors();

const SolicitudesNuevasTarjetas = (props) => {
  const [solicitudes, setSolicitudes] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const windowWidthRef = useRef(window.innerWidth);
  const [selectedMonth, setSelectedMonth] = useState(
    new Date().getMonth()
  );
  const [selectedYear, setSelectedYear] = useState(null);
  const [uniqueYears, setUniqueYears] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      windowWidthRef.current = window.innerWidth;
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/solicitud/tarjetas/all`)
      .then((response) => {
        const sortedSolicitudes = response.data.sort(
          (a, b) => new Date(b.fecha_solicitud) - new Date(a.fecha_solicitud)
        );
        console.log("sortedSolicitudes", sortedSolicitudes);
        setSolicitudes(sortedSolicitudes);
        const uniqueYears = [...new Set(sortedSolicitudes.map(s => new Date(s.fecha_solicitud).getFullYear()))];
        setUniqueYears(uniqueYears);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedYear !== null && selectedMonth !== null) {
      // Aquí podrías filtrar las solicitudes por mes y año seleccionado
    }
  }, [selectedYear, selectedMonth]);

  const handleMonthChange = (month) => {
    setSelectedMonth(month);
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
  };

  const solicitudesAceptadas = solicitudes.filter(
    (solicitud) => solicitud.status !== "Rechazada"
  );

  const groupSolicitudesByMonth = () => {
    const groupedSolicitudes = {};
    solicitudesAceptadas.forEach((solicitud) => {
      const month = new Date(solicitud.fecha_solicitud).getMonth();
      if (!groupedSolicitudes[month]) {
        groupedSolicitudes[month] = [];
      }
      groupedSolicitudes[month].push(solicitud);
    });
    return groupedSolicitudes;
  };

  const groupedSolicitudes = groupSolicitudesByMonth();

  return (
    <Pane>
      <Pane
        elevation={3}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        padding={majorScale(3)}
        color="white"
        alignItems="center"
        borderRadius={10}
        zIndex={1}
        className="impuls-header"
      >
        <Heading size={800} color="white">
          Panel de administración de solicitudes
        </Heading>
      </Pane>
      <Pane
        padding={majorScale(2)}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <MonthSelector
          selectedMonth={selectedMonth}
          handleMonthChange={handleMonthChange}
        />
        <YearSelector
          selectedYear={selectedYear}
          uniqueYears={uniqueYears}
          handleYearChange={handleYearChange}
        />
        {groupedSolicitudes[selectedMonth]?.map((solicitud) => (
          <Card
            key={solicitud._id}
            solicitud={solicitud}
            windowWidth={windowWidthRef.current}
          />
        ))}
        {groupedSolicitudes[selectedMonth]?.length === 0 && (
          <Heading size={600} color="white">
            No hay solicitudes de tarjetas nuevas para este mes
          </Heading>
        )}
      </Pane>
    </Pane>
  );
};



const MonthSelector = ({ selectedMonth, handleMonthChange }) => {
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  return (
    <Pane display="flex" marginBottom={majorScale(2)}>
      {months.map((month, index) => (
        <Button
          key={index}
          marginRight={majorScale(1)}
          appearance="minimal"
          onClick={() => handleMonthChange(index)}
          background={
            selectedMonth===index ? "#fff":"#000"
          }
          color={
            selectedMonth===index ? "#000":"#fff"
          }
        >
          {month}
        </Button>
      ))}
    </Pane>
  );
};

const YearSelector = ({ selectedYear, uniqueYears, handleYearChange }) => {
  return (
    <Select
      width={120}
      marginBottom={majorScale(2)}
      value={selectedYear}
      onChange={(e) => handleYearChange(e.target.value)}
      background="#fff"
      height={50}
    >
      {uniqueYears.map((year, index) => (
        <option key={index} value={year}>
          {year}
        </option>
      ))}
    </Select>
  );
};

const Card = ({ solicitud, windowWidth }) => {
  const [showAddCard, setShowAddCard] = useState(false);
  const [collapse, setCollapse] = useState(false);

  useEffect(() => {
    if (solicitud.status === "Autorizada") {
      setCollapse(true);
    }
  }, []);

  return (
    <Pane
      elevation={1}
      marginBottom={majorScale(2)}
      padding={majorScale(2)}
      borderRadius={5}
      backgroundColor="white"
      width={windowWidth > 768 ? 700 : "100%"}
    >
      {showAddCard && (
        <AcceptCardDialog
          setShowAddCard={setShowAddCard}
          solicitud={solicitud}
        />
      )}
      {solicitud.status === "Autorizada" && (
        <Pane
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={majorScale(2)}
          backgroundColor="rgba(0, 255, 0, 0.1)"
          borderRadius={5}
          marginBottom={majorScale(2)}
        >
          <Heading size={600} color="green">
            {solicitud.usuarioNombre} ha sido aceptada
          </Heading>
          {
            // add collapse here
          }
          <Button
            appearance="minimal"
            intent="danger"
            iconBefore={FaTimes}
            onClick={() => setCollapse(!collapse)}
          >
            {collapse ? "Mostrar" : "Ocultar"}
          </Button>
        </Pane>
      )}

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        style={{
          display: collapse ? "none" : "block",
        }}
      >
        <Heading size={600} paddingBottom={majorScale(1)}>
          {solicitud.usuarioNombre}
        </Heading>
        <Table>
          <Table.Body>
            <Table.Row height={50}>
              <Table.TextCell>ID Del Usuario</Table.TextCell>
              <Table.TextCell>{solicitud.usuario}</Table.TextCell>
            </Table.Row>
            <Table.Row height={50}>
              <Table.TextCell>Dirección</Table.TextCell>
              <Table.TextCell>{solicitud.direccion}</Table.TextCell>
            </Table.Row>
            <Table.Row height={50}>
              <Table.TextCell>Nombre de la tarjeta</Table.TextCell>
              <Table.TextCell>{solicitud.nombre}</Table.TextCell>
            </Table.Row>
            <Table.Row height={50}>
              <Table.TextCell>Correo</Table.TextCell>
              <Table.TextCell>{solicitud.correo}</Table.TextCell>
            </Table.Row>
            <Table.Row height={50}>
              <Table.TextCell>Fecha de solicitud</Table.TextCell>
              <Table.TextCell>
                {solicitud.fecha_solicitud.substring(0, 10)}
              </Table.TextCell>
            </Table.Row>
            <Table.Row height={50}>
              <Table.TextCell>Status</Table.TextCell>
              <Table.TextCell>{solicitud.status}</Table.TextCell>
            </Table.Row>
            <Table.Row>
              <Table.TextCell>Acciones</Table.TextCell>
              <Table.TextCell>
                <Button
                  marginRight={majorScale(1)}
                  appearance="primary"
                  intent="success"
                  iconBefore={FaCheck}
                  onClick={() => {
                    if (solicitud.status === "Autorizada") {
                      toaster.warning("Esta tarjeta ya fue aceptada");
                      return;
                    } else {
                      setShowAddCard(true);
                    }
                  }}
                >
                  {windowWidth > 768 ? "Aceptar" : ""}
                </Button>
              </Table.TextCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </motion.div>
    </Pane>
  );
};

export default SolicitudesNuevasTarjetas;
