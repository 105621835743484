import React, { useEffect } from "react";
import { useState } from "react";
import Axios from "axios";
import {
  Combobox,
  Heading,
  Pane,
  TextInputField,
  Button,
  toaster,
  Tablist,
  Tab,
  Switch,
  Text,
  Label,
} from "evergreen-ui";

import { useFormik } from "formik";
import FormularioUtilidades from "../../utilidades";
import FormularioAportaciones from "../aportaciones";
import FormularioRetiros from "../retiros";

const Gestion = (props) => {
  const selectedContract = props.selectedContract;
  const formik = useFormik({
    initialValues: {
      contratopdf: "",
      status: "pendiente",
      balance: 0.0,
      balance_inicial: 0,
    },
    onSubmit: (values) => {
      //add checked to the values
      values.status = checked;
      console.log("values", values);
      Axios.put(
        process.env.REACT_APP_API_URL+"/etd/" + selectedContract._id,
        JSON.stringify(values),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          props.updateFunc();
          toaster.success("Contrato actualizado");
        })
        .catch((err) => {
          toaster.danger("Error al actualizar el contrato");
          props.updateFunc();
        });
    },
  });

  useEffect(() => {
    formik.setFieldValue("contratopdf", selectedContract.contratopdf);
    formik.setFieldValue("status", selectedContract.status);
    formik.setFieldValue("balance", selectedContract.balance);
    formik.setFieldValue("balance_inicial", selectedContract.balance_inicial);
    setLoading(false);
  }, [selectedContract.contratopdf, selectedContract.status, selectedContract.balance, selectedContract.balance_inicial]);


  const uploadUtilidad = (values) => {
    console.log("values", values);
    console.log("selectedContract", selectedContract._id);
    Axios.post(
      process.env.REACT_APP_API_URL+"/etd/utilidad/" + selectedContract._id,
      JSON.stringify(values),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        toaster.success("Utilidad agregada");
        props.updateFunc();
      })
      .catch((err) => {
        toaster.danger("Error al agregar la utilidad");
        props.updateFunc();
      });
  };

  const uploadAportacion = (values) => {
    console.log("values", values);
    console.log("selectedContract", selectedContract._id);
    Axios.post(
      process.env.REACT_APP_API_URL+"/etd/aportacion/" + selectedContract._id,
      JSON.stringify(values),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        toaster.success("Aportacion agregada");
        props.updateFunc();
      })
      .catch((err) => {
        toaster.danger("Error al agregar la aportacion");
        props.updateFunc();
      });
  };

  const uploadRetiro = (values) => {
    console.log("values", values);
    console.log("selectedContract", selectedContract._id);
    Axios.post(
      process.env.REACT_APP_API_URL+"/etd/retiro/" + selectedContract._id,
      JSON.stringify(values),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        toaster.success("Retiro agregado");
        props.updateFunc();
      })
      .catch((err) => {
        toaster.danger("Error al agregar el retiro");
        props.updateFunc();
      });
  };

  const [selectedTab, setSelectedTab] = useState("Contrato");
  const [checked, setChecked] = useState(false);
  const [tabs] = useState([
    "Contrato",
    "Rendimientos",
    "Aportaciones",
    "Retiros",
  ]);

  const meses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const [dbUsuarios, setDbUsuarios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nombre, setNombre] = useState("");
  const [mes, setMes] = useState("Enero");




  const handleSelectContract = (e) => {
    const nombre = e;
    setNombre(nombre);
  };

  const handleSelectMonth = (e) => {
    const mes = e;
    setMes(mes);
  };

  if (loading) {
    return <h1>Cargando...</h1>;
  } else {
    return (
      <div
        style={{
          
        }}
      >
        <Pane
          display="flex"
          flexDirection="column"
          style={{
            width: "100%",
            paddingRight: "20px",
          }}

        >
          <Pane
            display="flex"
            flexDirection="column"
          >
            {
              //Agregar campos editables con los valores obtenidos de la base de datos

              selectedContract ? (
                <div>
                  <Tablist
                    marginBottom={16}
                    marginTop={16}
                    width="100%"
                  >
                    {tabs.map((tab, index) => (
                      <Tab
                        key={tab}
                        id={tab}
                        onSelect={() => setSelectedTab(tab)}
                        isSelected={tab === selectedTab}
                        aria-controls={`panel-${tab}`}
                      >
                        {tab}
                      </Tab>
                    ))}
                  </Tablist>
                  <Pane
                    background="tint2"
                    elevation={1}
                    style={{
                      padding: "20px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    <Heading size={900} fontWeight={200} alignSelf="center">
                      {selectedTab}
                    </Heading>
                    <hr />
                    {selectedTab === "Contrato" ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                        }}
                      >
                        <form onSubmit={formik.handleSubmit}>
                          <TextInputField
                            id="contratopdf"
                            name="contratopdf"
                            label="Contrato"
                            value={formik.values.contratopdf}
                            onChange={formik.handleChange}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "40px",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Label>Estado</Label>
                            <Switch
                              checked={checked}
                              onChange={(e) => setChecked(e.target.checked)}
                              name="status"
                            />
                          </div>
                          <TextInputField
                            id="balance"
                            name="balance"
                            label="Balance"
                            disabled
                            value={formik.values.balance.toFixed(2)}
                            onChange={formik.handleChange}
                          />
                          <TextInputField
                            id="balance_inicial"
                            name="balance_inicial"
                            label="Balance inicial"
                            value={formik.values.balance_inicial}
                            onChange={formik.handleChange}
                          />
                          <TextInputField
                            label="Fecha de creacion"
                            value={selectedContract.createdAt}
                            onChange={formik.handleChange}
                            disabled
                          />
                          <Button appearance="primary" type="submit">
                            Guardar
                          </Button>
                        </form>
                      </div>
                    ) : selectedTab === "Rendimientos" ? (
                      <div>
                        <Pane flexDirection="column" gap={20}>
                          <Combobox
                            items={meses}
                            placeholder="Selecciona un mes"
                            key={meses._id}
                            value={mes}
                            onChange={handleSelectMonth}
                            marginBottom={20}
                            width="100%"
                          />
                          <FormularioUtilidades
                            utilidades={selectedContract.utilidades}
                            mes={mes}
                            uploadUtilidad={uploadUtilidad}
                          />
                        </Pane>
                      </div>
                    ) : selectedTab === "Aportaciones" ? (
                      <div>
                        <Pane flexDirection="column">
                          <Combobox
                            items={meses}
                            placeholder="Selecciona un mes"
                            key={meses._id}
                            value={mes}
                            onChange={handleSelectMonth}
                            marginBottom={20}
                            width="100%"
                          />
                          <FormularioAportaciones
                            aportaciones={selectedContract.aportaciones}
                            mes={mes}
                            uploadAportacion={uploadAportacion}
                          />
                        </Pane>
                      </div>
                    ) : selectedTab === "Retiros" ? (
                      <div>
                        <Pane flexDirection="column">
                          <Combobox
                            items={meses}
                            placeholder="Selecciona un mes"
                            key={meses._id}
                            value={mes}
                            onChange={handleSelectMonth}
                            marginBottom={20}
                            width="100%"
                          />
                          <FormularioRetiros
                            retiros={selectedContract.retiros}
                            mes={mes}
                            uploadRetiro={uploadRetiro}
                          />
                        </Pane>
                      </div>
                    ) : null}
                  </Pane>
                </div>
              ) : null
            }
          </Pane>
        </Pane>
      </div>
    );
  }
};

export default Gestion;