
import React from "react";
import { Tooltip, IconButton } from "@mui/material";
import { FaWhatsapp } from "react-icons/fa";

const WhatsappSoporte = () => {
    return (
        <Tooltip title="Contactar por Whatsapp" placement="left"
        >
          <IconButton
            href="https://wa.me/5213310004998"
            style={{
              position: "fixed",
              bottom: 20,
              right: 20,
              zIndex: 1,
              color: "white",
              fontSize: 40,
              backgroundColor: "#25D366",
              borderRadius: "50%",
              padding: 10,
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
            }}
          >
            <FaWhatsapp />
          </IconButton>
        </Tooltip>
    );
    }

export default WhatsappSoporte;