import React from "react";
import {Pane, Group } from "evergreen-ui";
import backImage from "../img/brand_white.png";
import LoginForm from "../components/loginForm";
import RegisterForm from "../components/registerForm";
import { ToggleButton, ToggleButtonGroup, Button, IconButton, Tooltip, Typography} from "@mui/material";
import { useState } from "react";
import { FaWhatsapp } from "react-icons/fa";

import { AnimatePresence, motion } from "framer-motion";

function Registro() {
  const [loginOrRegister, setLoginOrRegister] = useState(false);

  const handleButtonClick = (isLogin) => {
    setLoginOrRegister(isLogin);
    document.title = isLogin ? "Registrarse" : "Iniciar sesión";
  };

  return (
    <div className="App">
      <div>
        <Tooltip title="Contactar por Whatsapp" placement="left">
        <IconButton
          href="https://wa.me/5213310004998"
          style={{
            position: "absolute",
            bottom: 20,
            right: 20,
            zIndex: 1,
            color: "white",
            fontSize: 40,
            backgroundColor: "green",
            borderRadius: "50%",
            padding: 10,
          }}
        >
          <FaWhatsapp />
        </IconButton>
        </Tooltip>
        
      </div>
      <motion.img
        alt="background"
        src={backImage}
        style={{
          position: "absolute",
          top: loginOrRegister ? "85%" : "80%",
          left: "50%",
          transform: "translate(-50%, 50%)",
          zIndex: 0,
          filter: "drop-shadow(2px 4px 6px black)",
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      />

      <header className="App-header">
        <AnimatePresence
        mode="popLayout"
        >
          <motion.div
          layout
            key={loginOrRegister ? "register" : "login"}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Pane
              elevation={3}
              display="flex"
              justifyContent="center"
              flexDirection="column"
              padding={30}
              background="#030409"
              color="black"
              alignItems="center"
              borderRadius={10}
              zIndex={1}
            >
              <div
                style={{
                  display: "flex",
                  gap: 10,
                  flexDirection: "column",
                  marginTop: 40,
                  marginBottom: 20,
                  fontSize: 20,
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  
                }}
              >
                <Typography
                    variant="h5"
                    component="h2"
                    color="white"
                >
                    Comparte este enlace con tus amigos para que se registren
                </Typography>

              </div>
                
              <RegisterForm />
            </Pane>
          </motion.div>
        </AnimatePresence>
        
      </header>
    </div>
  );
}

export default Registro;
