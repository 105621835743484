import react from "react";
import { useState, useEffect } from "react";
import {
  Combobox,
  Heading,
  Pane,
  TextInputField,
  Button,
  toaster,
  Tablist,
  Paragraph,
  Tab,
  SearchInput,
  Table,
} from "evergreen-ui";
import { useFormik } from "formik";
import axios, { all } from "axios";
import { initAxiosInterceptors } from "../../../../util/authHelper";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ZAxis,
  ComposedChart,
  Bar,
  BarChart,
  AreaChart,
  ResponsiveContainer,
  Area,
} from "recharts";
import AportacionesYRetiros from "../../../etd/graficas/AportacionesRetiros";
import Gestion from "./gestion";
import RendimientoGeneral from "./rendimientogeneral";
import Historicos from "./Historicos";

initAxiosInterceptors();

const RendimientosGlobales = (props) => {
  const api = process.env.REACT_APP_API_URL;

  const [allETD, setAllETD] = useState([]);
  const [loading, setLoading] = useState(true);

  const [mes, setMes] = useState("Enero");
  const [user, setUser] = useState(0);
  const [utilidades, setUtilidades] = useState([]);
  const [data, setData] = useState([]);
  const [selectedYear, setSelectedYear] = useState("2023");
  const [selectedETD, setSelectedETD] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const meses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const handleChange = (e) => {
    console.log(e);
    setSelectedYear(e);
  };

  useEffect(() => {
    const toSearch = allETD;
    const usersSearch = toSearch.filter((user) => {
      if (user.nombre.toLowerCase().includes(search.toLowerCase())) {
        return true;
      }
      if (user.apellido.toLowerCase().includes(search.toLowerCase())) {
        return true;
      }
      if (user.correo.toLowerCase().includes(search.toLowerCase())) {
        return true;
      }
      return false;
    });
    setFilteredUsers(usersSearch);
  }, [search]);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });

  useEffect(() => {
    const filtered = utilidades
      .reduce((acc, item) => {
        if (item.year === selectedYear) {
          const newItem = {
            ...item,
            name: meses[item.mes],
            Monto: item.monto.toFixed(2),
            Porcentaje: item.porcentaje.toFixed(2),
          };
          acc.push(newItem);
        }
        return acc;
      }, [])
      .sort((a, b) => a.mes - b.mes);
    setData(filtered);
  }, [selectedYear, utilidades]);

  const getAllETD = async () => {
    try {
      const res = await axios.get(`${api}/etd`);
      const datos = res.data;
      for (let i = 0; i < datos.length; i++) {
        const res = await axios.get(`${api}/users/${datos[i].id_usuario}`);
        datos[i].nombre = res.data.nombre;
        datos[i].apellido = res.data.apellido;
        datos[i].correo = res.data.correo;
      }
      //format dato[x].balance to 2 decimals and money format
      
      console.log("datos  " + datos);
      setAllETD(datos);
      setLoading(false);
      setFilteredUsers(datos);
      setSelectedETD(datos[user]);
      setUtilidades(datos[user].utilidades);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getAllETD();
  }, []);

  if (loading) {
    return (
      <div>
        <Heading size={700} marginTop={20} marginBottom={20}>
          Cargando...
        </Heading>
      </div>
    );
  }

  if(allETD.length === 0){
    return (
      <div>
        <Heading size={700} marginTop={20} marginBottom={20}>
          No hay ETD registrados
        </Heading>
      </div>
    );
  }

  return (
    <div>
      <Pane
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <Heading
          size={1200}
          marginTop={20}
          marginBottom={20}
          fontVariant="simplified"
        >
          Panel de administracion de eTradeDirect
        </Heading>
        <Pane
          display="grid"
          gridTemplateColumns="1fr 4fr 2fr"
          gridGap={20}
          width="100%"
        >
          <Pane display="flex" flexDirection="column" alignItems="center">
            <RendimientoGeneral updateFunc={getAllETD} />

            <Pane
              background="tint2"
              display="flex"
              flexDirection="column"
              alignItems="center"
              elevation={1}
              width="100%"
              padding={20}
              marginTop={20}
            >
              <Heading size={700} marginTop={20} fontVariant="simplified">
                Usuarios
              </Heading>
              <Paragraph marginBottom={20}> Selecciona un usuario: </Paragraph>
              <SearchInput
                placeholder="Buscar usuario"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                marginBottom={20}
              />

              <Pane
                display="flex"
                flexDirection="column"
                justifyContent="center"
                gap={20}
              >
                {filteredUsers.map((etd) => {
                  etd.index = filteredUsers.indexOf(etd);
                  return (
                    <Pane
                      display="flex"
                      flexDirection="column"
                      width="200px"
                      style={{
                        cursor: "pointer",
                        border: "1px solid #E4E7EB",
                        borderRadius: "3px",
                        padding: "10px",
                      }}
                      boxShadow={
                        selectedETD.id_usuario === etd.id_usuario
                          ? "0 0 0 2px #137CBD"
                          : "none"
                      }
                      background={
                        selectedETD.id_usuario === etd.id_usuario
                          ? "white"
                          : "tint2"
                      }
                      onClick={() => {
                        setUtilidades(etd.utilidades);
                        setSelectedETD(etd);
                        setUser(etd.index);
                      }}
                    >
                      <Heading size={100}>{etd.id_usuario}</Heading>
                      <Heading size={400}>
                        {etd.nombre} {etd.apellido}
                      </Heading>
                      <Heading size={300}>{etd.correo}</Heading>
                    </Pane>
                  );
                })}
              </Pane>
            </Pane>
          </Pane>
          <Pane
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            background="tint2"
            elevation={1}
            padding={20}
          >
            <Heading>
              {selectedETD.nombre} {selectedETD.apellido}
            </Heading>
            <Heading size={300}>{selectedETD.correo}</Heading>
            <Heading size={900} color={
              selectedETD.balance < 0 ? "red" : "green"
            }> 
            
            {formatter.format(selectedETD.balance)}
            
            </Heading>
            <Heading size={1200} marginTop={20} marginBottom={20}>
              Rendimientos
            </Heading>
            <Paragraph> Selecciona el año que deseas ver: </Paragraph>
            <Pane
              display="flex"
              width="50%"
              padding={20}
              marginLeft={20}
            >
            <Combobox
              items={["2021", "2022", "2023"]}
              onChange={handleChange}
              initialSelectedItem={"2023"}
              alignSelf="center"
              margin={"auto"}
            />
            </Pane>
            <div
              style={{
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                width: "90%",
                }}
            > 
              {data.length <= 0 && (
                <Heading size={300}>
                  {" "}
                  No hay datos para mostrar en este momento
                </Heading>
              )}
              <ResponsiveContainer height={200}>
                <BarChart //Utilidades mensuales
                  data={data}
                  margin={{
                    top: 5,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey={"name"} />
                  <YAxis
                    dataKey="Monto"
                    orient="left"
                    allowDataOverflow
                    type="number"
                    tickFormatter={(label) => `$${label}`}
                  />
                  <Tooltip />
                  <Legend />
                  <Bar
                    type="monotone"
                    dataKey="Monto"
                    fill="#8884d8"
                    barSize={20}
                  />
                  <Line
                    type="monotone"
                    dataKey="Porcentaje"
                    stroke="#82ca9d"
                    fill="#8884d8"
                  />
                </BarChart>
              </ResponsiveContainer>
              <ResponsiveContainer height={200}>
                <AreaChart //Utilidades mensuales porcentual
                  height={200}
                  data={data}
                  margin={{
                    top: 5,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis
                    dataKey="Porcentaje"
                    orient="left"
                    allowDataOverflow
                    tickFormatter={(label) => `${label}%`}
                  />
                  <Tooltip />
                  <Legend />
                  <Area
                    type="monotone"
                    dataKey="Porcentaje"
                    stroke="#82ca9d"
                    fill="#8884d8"
                  />
                </AreaChart>
              </ResponsiveContainer>
              
            </div>
            <Pane //Aportaciones y retiros
              display="flex"
              alignItems="center"
              flexDirection="column"
              padding={20}
              paddingLeft={30}
              width="100%"
            >
              <Heading size={600}>Aportaciones y retiros</Heading>
              <Paragraph>
                En esta sección podrás ver el monto de tus aportaciones y
                retiros mensuales.
              </Paragraph>

              {data ? (
                <AportacionesYRetiros data={selectedETD} />
              ) : (
                <Heading size={300}>
                  {" "}
                  No hay datos para mostrar en este momento
                </Heading>
              )}
            </Pane>
            <Pane
              width="100%"
              display="flex"
              flexDirection="column"
              alignItems="center"

            >
                <Heading size={600}>Registros historicos</Heading>
                <Paragraph>
                  En esta sección podrás ver el historial de rendimientos de
                  cada usuario.
                </Paragraph>
                <Historicos
                  data={selectedETD}
                />

              </Pane>
          </Pane>
            <Gestion selectedContract={selectedETD} updateFunc={getAllETD} />
        </Pane>
      </Pane>
    </div>
  );
};

export default RendimientosGlobales;
