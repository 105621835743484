import React, { useEffect, useState } from "react";
import {
  Pane,
  Heading,
  TextInput,
  Dialog,
  toaster,
  FileUploader,
  FileCard,
  Button,
  Select,
  Paragraph,
} from "evergreen-ui";
import axios from "axios";
import { FaFileDownload } from "react-icons/fa";

import {
  initAxiosInterceptors,
  updateOnCloseSession,
} from "../../../util/authHelper";

const CambiarPoliza = ({ showChangePoliza, setShowChangePoliza, seguro, onUpdated }) => {

  updateOnCloseSession(() => (window.location.href = "/"));
  initAxiosInterceptors();

  return(
    <Dialog
      isShown={showChangePoliza}
      title="Cambiar póliza"
      onCloseComplete={() => setShowChangePoliza(false)}
      hasFooter={false}
    >
      <Pane display="flex" flexDirection="column" gap={20} padding={20}>
        <Pane display="flex" flexDirection="column" gap={10}>
          <Heading size={500}>Póliza actual</Heading>
          <Paragraph>
            {seguro.poliza ? "Póliza actual cargada" : "No hay póliza cargada"}
          </Paragraph>
          {seguro.poliza && (    
            <FileCard
              title="Póliza actual"
              icon={FaFileDownload}
              href={process.env.REACT_APP_API_URL + "/storage/poliza/" + seguro.poliza}
            />
          )}      
        </Pane>
        <Pane display="flex" flexDirection="column" gap={10}>
          <Heading size={500}>Cambiar póliza</Heading>
          <FileUploader
            width="100%"
            placeholder="Subir nueva póliza"
            accept="application/pdf"
            onChange={(files) => {

              const formData = new FormData();
              formData.append("file", files[0]);
              console.log("formData", formData);
              console.log("seguro", seguro);
              try {
                axios
                  .post(
                    process.env.REACT_APP_API_URL + "/seguro/cambiarPoliza/" + seguro._id,
                    formData,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    }
                  )
                  .then((res) => {
                    console.log(res);
                    toaster.success("Póliza cambiada");
                    setShowChangePoliza(null);
                    onUpdated();
                  });
              } catch (error) {
                toaster.danger("Error al cambiar póliza");
                console.log("error", error);
              }
            }}
          />
        </Pane>
      </Pane>
      
    </Dialog>
  )

};

export default CambiarPoliza;