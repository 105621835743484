import React, { useEffect, useState } from "react";
import {
  Pane,
  Heading,
  Paragraph,
  IconButton,
  Tooltip,
  toaster,
  Position,
  Dialog,
  TextInput,
} from "evergreen-ui";
import { motion, AnimatePresence } from "framer-motion";
import axios from "axios";
import { initAxiosInterceptors } from "../../../util/authHelper";
import {
  FaEdit,
  FaMoneyBillWave,
  FaTrash,
  FaEnvelope,
  FaPlusCircle,
  FaCheckCircle,
  FaTimesCircle,
} from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

initAxiosInterceptors();

const SucursalDetail = ({ sucursal, selectedTpv }) => {

  const [cajas, setCajas] = useState([]);
  const [isEditEmailShown, setIsEditEmailShown] = useState(false);
  const [isChangeNameShown, setIsChangeNameShown] = useState(false);
  const [isDeleteBoxShown, setIsDeleteBoxShown] = useState(false);
  const [selectedBox, setSelectedBox] = useState(null);
  const [isAddBoxShown, setIsAddBoxShown] = useState(false);
  const [isRenewBoxShown, setIsRenewBoxShown] = useState(false);
  const [dateAddBox, setDateAddBox] = useState(new Date());
  const [fechaRenovacion, setFechaRenovacion] = useState(
    new Date(dateAddBox.getTime() + 31536000000)
  );

  const [newEmail, setNewEmail] = useState("");
  const [newName, setNewName] = useState("");
  const [alias, setAlias] = useState("");
  const [correo, setCorreo] = useState("");

  useEffect(() => {
    if (sucursal && sucursal.cajas) {
      setCajas(sucursal.cajas);
    }
  }, [sucursal]);

  useEffect(() => {
    const newDate = new Date(dateAddBox);
    newDate.setFullYear(newDate.getFullYear() + 1);
    setFechaRenovacion(newDate);
  }, [dateAddBox]);

  const getEstadoStyle = (estado) => {
    switch (estado) {
      case "Aprobada":
        return {
          backgroundColor: "#28a745",
          color: "white",
        };
      case "Pendiente":
        return {
          backgroundColor: "#ffc107",
          color: "black",
        };
      case "Rechazada":
        return {
          backgroundColor: "#dc3545",
          color: "white",
        };
      default:
        return {
          backgroundColor: "#6c757d",
          color: "white",
        };
    }
  };

  const handleEditCorreo = (caja) => {
    setSelectedBox(caja);
    setNewEmail(caja.correo || "");
    setIsEditEmailShown(true);
  };


  const handleEditNombre = (caja) => {
    setSelectedBox(caja);
    setNewName(caja.nombre || "");
    setIsChangeNameShown(true);
  };

  const handleDeleteCaja = (caja) => {
    setSelectedBox(caja);
    setIsDeleteBoxShown(true);
  };

  const handleRenovarCaja = (caja) => {
    setSelectedBox(caja);
    setIsRenewBoxShown(true);
  };

  const handleAddCaja = () => {
    const newBox = {
      nombre: alias,
      correo,
      cajaId: Math.floor(Math.random() * 1000000),
      fecha_solicitud: dateAddBox,
      fecha_renovacion: fechaRenovacion,
      estado: "Aprobada",
    };
    setAlias("");
    setCorreo("");
    setDateAddBox(new Date());
    setFechaRenovacion(new Date(dateAddBox.getTime() + 31536000000));
    console.log("newBox", newBox);
    console.log("sucursal", sucursal);
    sucursal.cajas = [...sucursal.cajas, newBox];
    setCajas(sucursal.cajas);

    const newTpv = {
      ...selectedTpv,
      sucursales: selectedTpv.sucursales.map((s) => {
        if (s.sucursalId === sucursal.sucursalId) {
          return sucursal;
        }
        return s;
      }),
    };

    axios
      .put(`${process.env.REACT_APP_API_URL}/tpv/${selectedTpv._id}`, newTpv)
      .then((res) => {
        console.log("res", res);
        toaster.notify("Caja agregada", { position: Position.TOP_RIGHT });
      })
      .catch((error) => {
        console.log("error", error);
      });

    setIsAddBoxShown(false);
  };

  const updateEmail = () => {
    sucursal.cajas = sucursal.cajas.map((caja) => {
      if (caja.cajaId === selectedBox.cajaId) {
        caja.correo = newEmail;
      }
      return caja;
    });
    setCajas(sucursal.cajas);

    const newTpv = {
      ...selectedTpv,
      sucursales: selectedTpv.sucursales.map((s) => {
        if (s.sucursalId === sucursal.sucursalId) {
          return sucursal;
        }
        return s;
      }),
    };

    axios
      .put(`${process.env.REACT_APP_API_URL}/tpv/${selectedTpv._id}`, newTpv)
      .then((res) => {
        console.log("res", res);
        toaster.notify("Correo actualizado", { position: Position.TOP_RIGHT });
      })
      .catch((error) => {
        console.log("error", error);
      });

    setIsEditEmailShown(false);
  };

  const updateName = () => {
    sucursal.cajas = sucursal.cajas.map((caja) => {
      if (caja.cajaId === selectedBox.cajaId) {
        caja.nombre = newName;
      }
      return caja;
    });
    setCajas(sucursal.cajas);

    const newTpv = {
      ...selectedTpv,
      sucursales: selectedTpv.sucursales.map((s) => {
        if (s.sucursalId === sucursal.sucursalId) {
          return sucursal;
        }
        return s;
      }),
    };

    axios
      .put(`${process.env.REACT_APP_API_URL}/tpv/${selectedTpv._id}`, newTpv)
      .then((res) => {
        console.log("res", res);
        toaster.notify("Nombre actualizado", { position: Position.TOP_RIGHT });
      })
      .catch((error) => {
        console.log("error", error);
      });
    setIsChangeNameShown(false);
  };

  const deleteBox = () => {
    sucursal.cajas = sucursal.cajas.filter(
      (caja) => caja.cajaId !== selectedBox.cajaId
    );
    setCajas(sucursal.cajas);

    const newTpv = {
      ...selectedTpv,
      sucursales: selectedTpv.sucursales.map((s) => {
        if (s.sucursalId === sucursal.sucursalId) {
          return sucursal;
        }
        return s;
      }),
    };

    axios
      .put(`${process.env.REACT_APP_API_URL}/tpv/${selectedTpv._id}`, newTpv)
      .then((res) => {
        console.log("res", res);
        toaster.notify("Caja eliminada", { position: Position.TOP_RIGHT });
      })

      .catch((error) => {
        console.log("error", error);
      });
    setIsDeleteBoxShown(false);
  };

  const approveBox = (caja) => {
    sucursal.cajas = sucursal.cajas.map((box) => {
      if (box.cajaId === caja.cajaId) {
        box.estado = "Aprobada";
        const fechaRenovacion = new Date(box.fecha_solicitud);
        box.fecha_renovacion = new Date(fechaRenovacion.getTime() + 31536000000);
      }
      return box;
    });
    setCajas(sucursal.cajas);

    const newTpv = {
      ...selectedTpv,
      sucursales: selectedTpv.sucursales.map((s) => {
        if (s.sucursalId === sucursal.sucursalId) {
          return sucursal;
        }
        return s;
      }),
    };

    axios
      .put(`${process.env.REACT_APP_API_URL}/tpv/${selectedTpv._id}`, newTpv)
      .then((res) => {
        console.log("res", res);
        toaster.notify("Caja aprobada", { position: Position.TOP_RIGHT });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const rejectBox = (caja) => {
    sucursal.cajas = sucursal.cajas.map((box) => {
      if (box.cajaId === caja.cajaId) {
        box.estado = "Rechazada";
      }
      return box;
    });
    setCajas(sucursal.cajas);

    const newTpv = {
      ...selectedTpv,
      sucursales: selectedTpv.sucursales.map((s) => {
        if (s.sucursalId === sucursal.sucursalId) {
          return sucursal;
        }
        return s;
      }),
    };

    axios
      .put(`${process.env.REACT_APP_API_URL}/tpv/${selectedTpv._id}`, newTpv)
      .then((res) => {
        console.log("res", res);
        toaster.notify("Caja rechazada", { position: Position.TOP_RIGHT });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const renewBox = (caja) => {
    sucursal.cajas = sucursal.cajas.map((box) => {
      if (box.cajaId === caja.cajaId) {
        
        const fechaRenovacion = new Date(box.fecha_renovacion);

        box.fecha_renovacion = new Date(fechaRenovacion.getTime() + 31536000000);
      }
      return box;
    });
    setCajas(sucursal.cajas);

    const newTpv = {
      ...selectedTpv,
      sucursales: selectedTpv.sucursales.map((s) => {
        if (s.sucursalId === sucursal.sucursalId) {
          return sucursal;
        }
        return s;
      }),
    };

    axios
      .put(`${process.env.REACT_APP_API_URL}/tpv/${selectedTpv._id}`, newTpv)
      .then((res) => {
        console.log("res", res);
        toaster.notify("Caja renovada", { position: Position.TOP_RIGHT });
        setIsRenewBoxShown(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <Pane
      display="flex"
      flexDirection="column"
      padding={20}
      borderRadius={10}
      backgroundColor="#0A192F"
      marginTop={20}
    >
      <Heading size={700} marginBottom={20} color="#64FFDA">
        Cajas de {sucursal?.nombre}
      </Heading>
      <Pane
        display="flex"
        flexDirection="row"
        gap={20}
        overflowX="scroll"
        padding={20}
        style={{ scrollbarColor: "#fff #092230", scrollbarWidth: "thin" }}
      >
        <AnimatePresence mode="wait">
          {cajas.map((caja) => (
            <motion.div
              key={caja.cajaId}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: 20,
                borderRadius: 10,
                backgroundColor: "#112240",
                marginBottom: 10,
                gap: 5,
                minWidth: "200px",
              }}
              whileHover={{
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
              }}
              whileTap={{
                boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
              }}
            >
              <Paragraph size={500} color="white">
                {caja.nombre}
              </Paragraph>
              <Paragraph size={300} color="white">
                {caja.cajaId}
              </Paragraph>
              <Paragraph size={300} color="white">
                {caja.correo}
              </Paragraph>
              <Paragraph size={300} color="white">
                Fecha de solicitud:{" "}
                {caja.fecha_solicitud
                  ? new Date(caja.fecha_solicitud).toLocaleDateString()
                  : "No solicitada"}
              </Paragraph>
              <Paragraph size={300} color="white">
                Fecha de renovacion:{" "}
                {caja.fecha_renovacion
                  ? new Date(caja.fecha_renovacion).toLocaleDateString()
                  : "No aprobada"}
              </Paragraph>
              <Paragraph
                size={300}
                color={getEstadoStyle(caja.estado).color}
                style={{
                  ...getEstadoStyle(caja.estado),
                  padding: 5,
                  borderRadius: 5,
                  alignSelf: "center",
                  marginTop: 10,
                  margin: "auto",
                  border: "1px solid gray",
                  fontWeight: "bold",
                }}
              >
                {caja.estado}
              </Paragraph>
              <Pane display="flex" justifyContent="space-around" marginTop={10}>
                <Tooltip content="Cambiar correo" position={Position.TOP}>
                  <IconButton
                    icon={FaEnvelope}
                    onClick={() => handleEditCorreo(caja)}
                    appearance="minimal"
                    intent="none"
                    color="#007bff"
                  />
                </Tooltip>
                {caja.estado === "Aprobada" && (
                  <Tooltip content="Pagar anualidad" position={Position.TOP}>
                    <IconButton
                      icon={FaMoneyBillWave}
                      onClick={() => handleRenovarCaja(caja)}
                      appearance="minimal"
                      intent="none"
                      color="#28a745"
                    />
                  </Tooltip>
                )}
                <Tooltip content="Cambiar nombre" position={Position.TOP}>
                  <IconButton
                    icon={FaEdit}
                    onClick={() => handleEditNombre(caja)}
                    appearance="minimal"
                    intent="none"
                    color="#007bff"
                  />
                </Tooltip>
                <Tooltip content="Eliminar caja" position={Position.TOP}>
                  <IconButton
                    icon={FaTrash}
                    onClick={() => handleDeleteCaja(caja)}
                    appearance="minimal"
                    intent="danger"
                    color="#dc3545"
                  />
                </Tooltip>
                {caja.estado === "Pendiente" && (
                  <>
                    <Tooltip content="Aprobar caja" position={Position.TOP}>
                      <IconButton
                        icon={FaCheckCircle}
                        onClick={() => approveBox(caja)}
                        appearance="minimal"
                        intent="success"
                        color="#28a745"
                      />
                    </Tooltip>
                    <Tooltip content="Rechazar caja" position={Position.TOP}>
                      <IconButton
                        icon={FaTimesCircle}
                        onClick={() => rejectBox(caja)}
                        appearance="minimal"
                        intent="danger"
                        color="#dc3545"
                      />
                    </Tooltip>
                  </>
                )}
              </Pane>
            </motion.div>
          ))}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: 20,
              borderRadius: 10,
              backgroundColor: "#112240",
              marginBottom: 10,
              gap: 20,
              cursor: "pointer",
              alignItems: "center",
              userSelect: "none",
            }}
            whileHover={{
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
              scale: 1.1,
            }}
            whileTap={{
              boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
              scale: 0.9,
            }}
            onClick={() => setIsAddBoxShown(true)}
          >
            <Paragraph size={700} color="white" style={{ textAlign: "center" }}>
              Agregar caja
            </Paragraph>
            <FaPlusCircle size={70} color="white" />
          </motion.div>
        </AnimatePresence>
      </Pane>

      <Dialog
        isShown={isEditEmailShown}
        title="Editar correo"
        onCloseComplete={() => setIsEditEmailShown(false)}
        confirmLabel="Guardar"
        onConfirm={updateEmail}
      >
        <TextInput
          placeholder="Nuevo correo"
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
          width="100%"
        />
      </Dialog>

      <Dialog
        isShown={isChangeNameShown}
        title="Cambiar nombre"
        onCloseComplete={() => setIsChangeNameShown(false)}
        confirmLabel="Guardar"
        onConfirm={updateName}
      >
        <TextInput
          placeholder="Nuevo nombre"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          width="100%"
        />
      </Dialog>

      <Dialog
        isShown={isDeleteBoxShown}
        title="Eliminar caja"
        intent="danger"
        onCloseComplete={() => setIsDeleteBoxShown(false)}
        confirmLabel="Eliminar"
        onConfirm={deleteBox}
      >
        <Paragraph>¿Estás seguro de que quieres eliminar esta caja?</Paragraph>
      </Dialog>

      <Dialog
        isShown={isRenewBoxShown}
        title="Renovar caja"
        onCloseComplete={() => setIsRenewBoxShown(false)}
        confirmLabel="Renovar"
        onConfirm={() => renewBox(selectedBox)}
      >
        <Paragraph>
          ¿Estás seguro de que quieres renovar esta caja por un año más?
        </Paragraph>
      </Dialog>

      <Dialog
        isShown={isAddBoxShown}
        title="Agregar caja"
        onCloseComplete={() => setIsAddBoxShown(false)}
        confirmLabel="Agregar"
        onConfirm={handleAddCaja}
      >
        <Pane
          display="flex"
          flexDirection="column"
          gap={20}
          alignItems="center"
          justifyContent="center"
        >
          <TextInput
            placeholder="Alias"
            value={alias}
            onChange={(e) => setAlias(e.target.value)}
          />
          <TextInput
            placeholder="Correo"
            value={correo}
            onChange={(e) => setCorreo(e.target.value)}
          />
          <Pane
            display="flex"
            flexDirection="row"
            gap={20}
            justifyContent="center"
          >
            <Pane display="flex" flexDirection="column" gap={20}>
              <Paragraph>Fecha de solicitud</Paragraph>
              <DatePicker
                id="fecha_solicitud"
                name="fecha_solicitud"
                selected={dateAddBox}
                dateFormat={"dd/MM/yyyy"}
                onChange={(date) => {
                  setDateAddBox(date);
                }}
              />
            </Pane>
            <Pane display="flex" flexDirection="column" gap={20}>
              <Paragraph>Fecha de renovación</Paragraph>
              <DatePicker
                id="fecha_renovacion"
                name="fecha_renovacion"
                selected={fechaRenovacion}
                disabled
                dateFormat={"dd/MM/yyyy"}
              />
            </Pane>
          </Pane>
        </Pane>
      </Dialog>
    </Pane>
  );
};

export default SucursalDetail;
