import React, { useState } from "react";
import { Pane, Heading, Dialog, TextInput, Button } from "evergreen-ui";
import axios from "axios";

const DialogEditNameCaja = ({
  showEditNameCaja,
  setShowEditNameCaja,
  caja,
  tpvEntity,
  sucursal,
  setTpvEntity,
  setSelectedSucursal,
}) => {
  const [newName, setNewName] = useState(caja.nombre);

  const handleEditName = async (id) => {
    const nombre = newName;
    const sucursalId = sucursal.sucursalId;
    const cajaId = id;
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/tpv/caja/${tpvEntity._id}`,
        { nombre, sucursalId, cajaId }
      );
      const updatedTpv = response.data;

      setTpvEntity((prevTpv) => {
        const updatedSucursales = prevTpv.sucursales.map((suc) => {
          if (suc.sucursalId === sucursalId) {
            return {
              ...suc,
              cajas: suc.cajas.map((cj) =>
                cj.cajaId === cajaId ? { ...cj, nombre: nombre } : cj
              ),
            };
          }
          return suc;
        });
        return { ...prevTpv, sucursales: updatedSucursales };
      });

      setSelectedSucursal((prevSucursal) => {
        if (prevSucursal.sucursalId === sucursalId) {
          return {
            ...prevSucursal,
            cajas: prevSucursal.cajas.map((cj) =>
              cj.cajaId === cajaId ? { ...cj, nombre: nombre } : cj
            ),
          };
        }
        return prevSucursal;
      });

      console.log("Nombre de caja actualizado: ", updatedTpv);
    } catch (error) {
      console.error("Error actualizando nombre de caja: ", error);
    }
  };

  return (
    <Dialog
      isShown={showEditNameCaja}
      title="Editar nombre de caja"
      onCloseComplete={() => setShowEditNameCaja(false)}
      hasFooter={false}
    >
      <Pane>
        <Heading size={500}>Editar nombre de caja</Heading>
        <TextInput
          placeholder="Nuevo nombre"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          width="100%"
          marginBottom={20}
        />
        <Pane display="flex" justifyContent="flex-end">
          <Button
            appearance="primary"
            onClick={() => {
              handleEditName(caja.cajaId);
              setShowEditNameCaja(false);
            }}
          >
            Guardar
          </Button>
        </Pane>
      </Pane>
    </Dialog>
  );
};

export default DialogEditNameCaja;
