import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";

const AportacionesYRetiros = (props) => {
  const meses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const [data, setData] = useState([]);
  console.log(props.data);
  useEffect(() => {
    let aportaciones = props.data.aportaciones;
    let retiros = props.data.retiros.map((retiro) => {
      return { ...retiro, monto: retiro.monto * -1 };
    });
    let append = [];
    const aportacionesN = aportaciones.reduce((acc, aportacion) => {
      let mes = aportacion.mes;
      let monto = aportacion.monto;
      if (acc[mes]) {
        acc[mes].monto += monto;
      } else {
        acc[mes] = { mes, monto };
      }
      return acc;
    }, {});
    const retirosN = retiros.reduce((acc, retiro) => {
      let mes = retiro.mes;
      let monto = retiro.monto;
      if (acc[mes]) {
        acc[mes].monto += monto;
      } else {
        acc[mes] = { mes, monto };
      }
      return acc;
    }, {});
    aportaciones = Object.values(aportacionesN);
    retiros = Object.values(retirosN);
    meses.map((mes, key) => {
      let aportacion = aportaciones.find((aportacion) => {
        return aportacion.mes === key.toString();
      });
      let retiro = retiros.find((retiro) => {
        return retiro.mes === key.toString();
      });

      if (aportacion) {
        append.push({
          name: mes,
          aportaciones: aportacion.monto,
          retiros: retiro ? retiro.monto : 0,
        });
      } else {
        append.push({
          name: mes,
          aportaciones: 0,
          retiros: retiro ? retiro.monto : 0,
        });
      }
    });
    setData(append);
  }, [props.data]);

  if (!props.data ) {
    return <div>
      <h3>No hay datos para mostrar</h3>
    </div>;
  }
  return (
    <div
    style={{
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      width: "90%",
      }}
    >
      <ResponsiveContainer height={300} >
        <BarChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={"name"} />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar
            type="monotone"
            dataKey="aportaciones"
            fill="green"
            barSize={20}
            label="Aportaciones"
          />
          <Bar type="monotone" dataKey="retiros" fill="red" label="Retiros" barSize={20} />
          <ReferenceLine y={0} stroke="#000" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AportacionesYRetiros;
