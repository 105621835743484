import React, { useEffect, useState } from "react";
import { Button, Pane } from "evergreen-ui";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import logo from "../../img/logo blanco.png";
import { BsFillHouseDoorFill } from "react-icons/bs";
import { CiMoneyCheck1 } from "react-icons/ci";
import Retiros from "../etd/flows/Retiros";
import Aportaciones from "../etd/flows/Aportaciones";
import { Link, useLocation } from "react-router-dom";
import AdvertisementSlider from "./AdvertisementSlider";
import { BsShieldLock } from "react-icons/bs";
import { Tooltip } from "@mui/material";
import impulsSvg from "../../img/impuls.svg";
import { TbShieldPlus } from "react-icons/tb";
import { AiOutlineMenu } from "react-icons/ai";
import { RiSecurePaymentFill } from "react-icons/ri";
import { FaGlobe } from "react-icons/fa6";



const SideMenu = (props) => {
  const location = useLocation();
  console.log(props.options);
  const [showRetiros, setShowRetiros] = useState(false);
  const [showAportaciones, setShowAportaciones] = useState(false);
  const [isMobile, setIsMobile] = React.useState(false);
  const [toggled, setToggled] = React.useState(false);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
  }, []);

  const toggleAportaciones = () => {
    console.log("toggleAportaciones" + showAportaciones);
    setShowAportaciones(!showAportaciones);
  };

  const toggleRetiros = () => {
    console.log("toggleRetiros" + showRetiros);
    setShowRetiros(!showRetiros);
  };

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        position: "fixed",
        zIndex: "1",
      }}
    >
      <Button
        onClick={() => {
          console.log("CLICK");
          setToggled(true);
        }}
        style={{
          zIndex: "4",
          position: "fixed",
          left: "0px",
          top: "0px",
          backgroundColor: "#1b1b1f",
          color: "#fff",
          border: "none",
          display: isMobile ? "block" : "none",
          width: "90px",
          height: "90px",
        }}
      >
        <AiOutlineMenu
          size={50}
          style={{
            margin: "auto",
            display: "block",
          }}
        />
      </Button>
      <Sidebar
        backgroundColor="#1b1b1f"
        breakPoint="md"
        toggled={toggled}
        onBackdropClick={() => setToggled(false)}
        style={{
          position: "fixed",
          height: "100%",
          zIndex: 5,
          borderRight: "0px solid #333342",
          width: "250px",
        }}
      >
        {showAportaciones && (
          <Aportaciones
            showAportaciones={showAportaciones}
            toggleAportaciones={toggleAportaciones}
            user={props.user}
            etd={props.etd}
          />
        )}
        {showRetiros && (
          <Retiros
            showRetiros={showRetiros}
            toggleRetiros={toggleRetiros}
            user={props.user}
            etd={props.etd}
          />
        )}

        <Menu
          menuItemStyles={{
            button: ({ level, active, disabled }) => ({
              backgroundColor: active ? "#333342" : "#1b1b1f",
              color: "#fff",
              fontSize: "1em",
              fontWeight: "bold",
              height: "4vh",
              shadow: active ? "0px 0px 10px 0px rgba(0,0,0,0.75)" : "",
              ":hover": {
                backgroundColor: "#333342",
                shadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
              },
              userSelect: "none",
            }),
            icon: {
              color: "#fff",
            },
            text: {
              color: "#fff",
            },
          }}
        >
          <img
            src={logo}
            alt="logo"
            style={{
              padding: "5px",
              margin: "auto",
              display: "block",
              marginBottom: "10px",
              marginTop: "10px",
              height: "auto",
              width: "180px",
            }}
          />

          <MenuItem
            style={{
              color: "#fff",
            }}
            icon={<BsFillHouseDoorFill size={25} />}
            component={<Link to="/" />}
            active={location.pathname === "/"}
          >
            Inicio
          </MenuItem>

          <SubMenu
            icon={
              <img
                src={impulsSvg}
                alt="impulspay"
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />
            }
            style={{
              color: "#fff",
            }}
            label="Impulspay"
          >
            <MenuItem
              icon={<CiMoneyCheck1 size={25} />}
              component={<Link to="/impulspay/tarjetas" />}
              active={location.pathname === "/impulspay/tarjetas"}
              style={{
                color: "#fff",
                ":hover": {
                  backgroundColor: "#333342",
                },
              }}
            >
              Tarjetas
            </MenuItem>
            <MenuItem
              icon={<RiSecurePaymentFill size={25} />}
              style={{
                color: "#fff",
              }}
              component={<Link to="/tpv" />}
              active={location.pathname === "/tpv"}
            >
              TPV Impulspay
            </MenuItem>
            <MenuItem
              icon={<FaGlobe size={25} />}
              style={{
                color: "#fff",
              }}
              component={<Link to="https://portal-antares.kashplataforma.com" />}
              active={location.pathname === "/portaltpv"}
            >
              Portal KashPay TPV
            </MenuItem>
          </SubMenu>
          <SubMenu
            icon={<TbShieldPlus size={25} color="#fff" />}
            label="Protecc"
            style={{
              color: "#fff",
            }}
          >
            <MenuItem
              icon={<BsShieldLock size={25} />}
              component={<Link to="/protecc/seguros" />}
              active={location.pathname === "/protecc/seguros"}
              style={{
                color: "#fff",
                ":hover": {
                  backgroundColor: "#333342",
                },
              }}
            >
              Seguros
            </MenuItem>
            <Tooltip
              title="Próximamente"
              placement="right"
              followCursor
              style={{
                backgroundColor: "#333342",
                color: "#fff",
                fontSize: "1em",
              }}
            >
              <MenuItem
                icon={<BsShieldLock size={25} />}
                style={{
                  color: "#888",
                  ":hover": {
                    backgroundColor: "#333342",
                  },
                }}
              >
                Cotizador
              </MenuItem>
            </Tooltip>
          </SubMenu>
        </Menu>
        <Pane
          style={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            width: "100%",
            bottom: "0",
          }}
        >
          <AdvertisementSlider />
        </Pane>
      </Sidebar>
    </div>
  );
};

export default SideMenu;
