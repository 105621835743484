import React from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { initAxiosInterceptors } from '../util/authHelper';

const VerifyEmail = () => {
    initAxiosInterceptors();
    useEffect(() => {
        console.log(window.location.pathname.split('/')[2]);
        axios.get(process.env.REACT_APP_API_URL+"/verify-email/verify-email/"+window.location.pathname.split('/')[2],
            
        )
            .then(res => {
                console.log(res);
                window.location.href = "/";
                
            })
            .catch(err => {
                console.log(err);
            });
    }
    , []);



    return (
        <div>
            <h1>Verificando correo</h1>
        </div>
    );
};

export default VerifyEmail;

