import React from "react";
import { Pane, Heading, TextInput, Dialog, toaster } from "evergreen-ui";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";

const AcceptCardDialog = ({ setShowAddCard, solicitud }) => {
  //cardNumber is the last 4 digits of the card
  const validationSchema = Yup.object({
    cardNumber: Yup.string()
      .matches(/^[0-9]{4}$/, "El numero de tarjeta debe tener 4 digitos")
      .required("Campo requerido"),
    });

  const solicitudId = solicitud._id;

  const formik = useFormik({
    initialValues: {
      cardNumber: "",
      name: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      try {
        axios
        .post(`${process.env.REACT_APP_API_URL}/solicitudestarjetas/${solicitudId}/autorizar`, {
            card4Digits: values.cardNumber,
        })
        .then((res) => {
            console.log(res);
            toaster.success("Tarjeta agregada");
            solicitud.status = "Aceptada";
            
            setShowAddCard(false);
        }); 

      } catch (error) {
        toaster.danger("Error al agregar tarjeta");
        console.log("error", error);
      }
    },
  });

  return (
    <Dialog
      isShown={true}
      title="Agregar tarjeta"
      onCloseComplete={() => setShowAddCard(false)}
      onConfirm={() => formik.handleSubmit()}
      confirmLabel="Agregar"
      cancelLabel="Cancelar"
    >
      <Pane
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap={20}
      >
        <Pane display="flex" flexDirection="column" gap={20}>
          <Heading size={500}>Ultimos 4 digitos de la tarjeta</Heading>
          <TextInput
            placeholder="1234"
            id="cardNumber"
            name="cardNumber"
            onChange={formik.handleChange}
            value={formik.values.cardNumber}
            isInvalid={formik.errors.cardNumber}
            validationMessage={formik.errors.cardNumber}
            onBlur={formik.handleBlur}
          />
          {formik.touched.cardNumber && formik.errors.cardNumber ? (
            <div>{formik.errors.cardNumber}</div>
          ) : null}
          <Heading size={500}>Nombre de la tarjeta</Heading>
          <TextInput
            id="name"
            name="name"
            onChange={formik.handleChange}
            value={solicitud.nombre}
            isInvalid={formik.errors.name}
            validationMessage={formik.errors.name}
            onBlur={formik.handleBlur}
            disabled
          />
          {formik.touched.name && formik.errors.name ? (
            <div>{formik.errors.name}</div>
          ) : null}
        </Pane>
      </Pane>
    </Dialog>
  );
};

export default AcceptCardDialog;
