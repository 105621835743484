import React from "react";
import { Pane, Heading, TextInput, Dialog, toaster, Paragraph } from "evergreen-ui";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const AddCardDialog = ({ setShowAddCard, selectedUser }) => {
  //cardNumber is the last 4 digits of the card
  const validationSchema = Yup.object({
    cardNumber: Yup.string()
      .matches(/^[0-9]{4}$/, "El numero de tarjeta debe tener 4 digitos")
      .required("Campo requerido"),

    name: Yup.string().required("Campo requerido"),
    date: Yup.date().required("Campo requerido"),
  });

  const formik = useFormik({
    initialValues: {
      cardNumber: "",
      name: "",
      date: new Date(),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      try {
        axios
          .post(
            process.env.REACT_APP_API_URL +
              "/users/addcard/" +
              selectedUser._id,
            {
              cardNumber: values.cardNumber,
              name: values.name,
              date: values.date,
            }
          )
          .then((res) => {
            console.log(res);
            toaster.success("Tarjeta agregada");
            setShowAddCard(false);
          });
      } catch (error) {
        toaster.danger("Error al agregar tarjeta");
        console.log("error", error);
      }
    },
  });

  return (
    <Dialog
      isShown={true}
      title="Agregar tarjeta"
      onCloseComplete={() => setShowAddCard(false)}
      onConfirm={() => formik.handleSubmit()}
      confirmLabel="Agregar"
      cancelLabel="Cancelar"
    >
      <Pane
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap={20}
      >
        <Pane display="flex" flexDirection="column" gap={20}>
          <Heading size={500}>Ultimos 4 digitos de la tarjeta</Heading>
          <TextInput
            placeholder="1234"
            id="cardNumber"
            name="cardNumber"
            onChange={formik.handleChange}
            value={formik.values.cardNumber}
            isInvalid={formik.errors.cardNumber}
            validationMessage={formik.errors.cardNumber}
            onBlur={formik.handleBlur}
          />
          {formik.touched.cardNumber && formik.errors.cardNumber ? (
            <Paragraph>{formik.errors.cardNumber}</Paragraph>
          ) : null}
          <Heading size={500}>Nombre de la tarjeta</Heading>
          <TextInput
            placeholder="Tarjeta de Mi hijo pedro"
            id="name"
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
            isInvalid={formik.errors.name}
            validationMessage={formik.errors.name}
            onBlur={formik.handleBlur}
          />
          {formik.touched.name && formik.errors.name ? (
            <Paragraph>{formik.errors.name}</Paragraph>
          ) : null}
          <Heading size={500}>Fecha de inicio de contrato</Heading>
          <DatePicker
            selected={formik.values.date}
            onChange={(date) => formik.setFieldValue("date", date)}
            dateFormat="dd/MM/yyyy"
          />
          {formik.touched.date && formik.errors.date ? (
            <Paragraph>{formik.errors.date}</Paragraph>
          ) : null}

        </Pane>
      </Pane>
    </Dialog>
  );
};

export default AddCardDialog;
