import React, { useState, useEffect } from "react";
import axios from "axios";
import { Pane, Heading, Paragraph, Button } from "evergreen-ui";
import UserSeguro from "./userSeguro";
import { FaSearch } from "react-icons/fa";

import UserSeguroDetail from "./userSeguroDetail";

const ProteccSeguros = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [search, setSearch] = useState("");
  const [userSelected, setUserSelected] = useState(null);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "/users/")
      .then((res) => {
        const usuariosNoAdmin = res.data.filter(
          (usuario) => usuario.role !== "admin"
        );
        setUsuarios(usuariosNoAdmin);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  // Filtra los usuarios basándose en el término de búsqueda actual.
  // Esta lógica asegura que cuando 'search' esté vacío, se muestren todos los usuarios.
  const filteredUsers = usuarios.filter((usuario) =>
    usuario.nombre.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Pane>
      <Pane
        elevation={3}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        padding={40}
        color="white"
        alignItems="center"
        borderRadius={10}
        zIndex={1}
        className="protecc-header"
      >
        <Heading size={800} color="white">
          Protecc Seguros
        </Heading>
      </Pane>
      <Pane
        display="flex"
        flexDirection="column"
        marginTop={50}
        width="80vw"
        margin="auto"
        alignItems="center"
      >
        <Pane
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="row"
          marginTop={20}
          width="80%"
        >
          <input
            id="searchProtecc" // Añadimos el ID aquí
            type="text"
            placeholder="Buscar usuario..."
            onChange={handleSearch}
            style={{
              width: "60%", // Ancho del input menos el ancho del ícono
              padding: "10px",
              borderRadius: "5px",
              border: "none",
              backgroundColor: "#7E57C2", // Color de fondo púrpura/morado
              color: "#FFFFFF", // Color de texto blanco para contraste
            }}
          />
        </Pane>
        <Paragraph color="white" size={900} textAlign="center" padding="20px">
          <strong>Selecciona un usuario para ver sus seguros</strong>
        </Paragraph>
        <Pane
          display="flex"
          flexDirection="row"
          gap={20}
          overflowX="scroll"
          padding={20}
          width="90%"
          style={{
            scrollbarColor: "#fff #333342",
            scrollbarWidth: "thin",
          }}
        >
          {filteredUsers.map((usuario) => (
            <UserSeguro
              key={usuario._id}
              usuario={usuario}
              selected={userSelected?._id === usuario._id}
              onClick={() => {
                setUserSelected(usuario);
              }}
            />
          ))}
        </Pane>
        <Pane
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          marginTop={20}
        >
          {userSelected && (
            <Paragraph color="white" size={900} textAlign="center">
              <strong>
                Seguros de {userSelected.nombre} {userSelected.apellido}
              </strong>
            </Paragraph>
          )}
          {userSelected && <UserSeguroDetail usuario={userSelected} />}
        </Pane>
      </Pane>
      <Button
        appearance="primary"
        intent="success"
        margin={20}
        onClick={() => {
          axios.get(process.env.REACT_APP_API_URL + "/test/recordatorio");
          console.log("Recordatorio de pago enviado");

        }}
      >
        Ejecutar recordatorio de pago
        </Button>
    </Pane>
  );
};

export default ProteccSeguros;
