import React from "react";
import {
  Pane,
  Paragraph,
  Heading,
  Card,
  Table,
  Text,
  Strong,
} from "evergreen-ui";
import { useEffect } from "react";

const Historicos = (props) => {
  const [data, setData] = React.useState([]);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });

  useEffect(() => {
    console.log("Historicos");
    const arrayAportaciones = props.data.aportaciones;
    const sumaMontosAportaciones = arrayAportaciones.reduce(
      (a, b) => a + b.monto,
      0
    );
    const arrayRetiros = props.data.retiros;
    const sumaMontosRetiros = arrayRetiros.reduce((a, b) => a + b.monto, 0);
    const arrayRendimientos = props.data.utilidades;
    const sumaMontosRendimientos = arrayRendimientos.reduce(
      (a, b) => a + b.monto,
      0
    );
    const mediaPorcentajeRendimientos = arrayRendimientos.reduce(
      (a, b) => a + b.porcentaje,
      0
    );
    const promedio = mediaPorcentajeRendimientos / arrayRendimientos.length;

    const data = {
      aportaciones: sumaMontosAportaciones.toFixed(2),
      retiros: sumaMontosRetiros.toFixed(2),
      utilidades: sumaMontosRendimientos.toFixed(2),
      mediaPorcentajeRendimientos: promedio.toFixed(2),
    };
    console.log(data);
    setData(data);
  }, [props]);

  return (
    <Pane
      display="flex"
      flexDirection="column"
      padding={16}
      gap={16}
    >
      {props.data.aportaciones && (
        <Pane
          display="flex"
          flexDirection="column"
          gap={16}
          alignContent="center"
          alignItems="center"
          justifyContent="center"
          width="300px"
          background="white"
          elevation={1}
          padding={20}
          marginBottom={40}
        >
          <Pane
            display="flex"
            flexDirection="row"
            alignContent="center"
            width="100%"
            alignItems="center"
            justifyContent="space-between"
          >
            <Paragraph>Aportaciones totales:</Paragraph> <Strong color="green">{
              formatter.format(data.aportaciones)
            }</Strong>
          </Pane>
          <Pane
            display="flex"
            flexDirection="row"
            width="100%"
            alignContent="center"
            alignItems="center"
            justifyContent="space-between"

          >
            <Paragraph>Retiros totales: </Paragraph><Strong color="red" >{
              formatter.format(data.retiros)
            }</Strong>
          </Pane>
          <Pane
            display="flex"
            flexDirection="row"
            width="100%"
            alignContent="center"
            alignItems="center"
            justifyContent="space-between"
          >
            <Paragraph>
            Utilidades totales: 
            </Paragraph>
            <Strong color="blue">{
              formatter.format(data.utilidades)

            }</Strong>
          </Pane>
          <Pane
            display="flex"
            flexDirection="row"
            width="100%"
            alignContent="center"
            alignItems="center"
            justifyContent="space-between"  
          >
            <Paragraph>
            Media de porcentaje de utilidades:{" "}
            </Paragraph>

            <Strong color="yellowgreen">{data.mediaPorcentajeRendimientos}%</Strong>
          </Pane>

        </Pane>
      )}
    </Pane>
  );
};

export default Historicos;
