import React from "react";
import {
  Combobox,
  Heading,
  Pane,
  TextInputField,
  Button,
  toaster,
  Label,
} from "evergreen-ui";
import { useFormik } from "formik";
import { initAxiosInterceptors } from "../../../../util/authHelper";
import axios from "axios";

initAxiosInterceptors();

const RendimientoGeneral = (props) => {


  const formik = useFormik({
    initialValues: {
      porcentaje: 0,
      mes: "",
      year: "",
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const optionsMeses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];




  const ejecutarRendimiento = () => {
    const url = process.env.REACT_APP_API_URL+"/etd/rendimiento";
    const body = JSON.stringify(formik.values);
    const headers = { headers: { "Content-Type": "application/json" } };

    axios.post(url, body, headers)
      .then(() => {
        toaster.success("Rendimiento ejecutado");
        
        props.updateFunc();
        
      }).catch(() => {
        toaster.danger("Error al ejecutar rendimiento");
      });
  };

  return(
    <Pane
      background="tint2"
      elevation={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      padding={20}
      gap = {10}

    >
     <Heading size={600}>Rendimiento General</Heading>
    <TextInputField label="Porcentaje"  type="number" width="100%" marginBottom={0}
      onChange={(e) => {
        formik.setFieldValue("porcentaje", e.target.value);
      }}

    />
    <Combobox
      items={optionsMeses}
      placeholder="Mes"
      width="100%"
      onChange={(selected) => {
        //cast selected to number
        selected = optionsMeses.indexOf(selected);
        formik.setFieldValue("mes", selected.toString() );
      }}

    />

    <Combobox
      items={["2021", "2022", "2023"]}
      placeholder="Año"
      width="100%"
      onChange={(selected) => {
        formik.setFieldValue("year", selected);
      }}
    />
    <Label>El rendimiento se ejecutará para todos los usuarios que no cuenten con un rendimiento existente</Label>
    

    <Button
      appearance="primary"
      intent="success"
      width="100%"
      onClick={() => {
        
        ejecutarRendimiento();
      }}
      
    
    >Ejecutar Rendimiento</Button>
    </Pane>

  )

}

export default RendimientoGeneral;