import "./App.css";
import Login from "./pages/login";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import MainPage from "./pages/mainpage";
import AdminMainPage from "./pages/admin/adminMainPage";
import { initAxiosInterceptors, getToken } from "./util/authHelper";
import { useEffect, useState } from "react";
import axios from "axios";
import VerifyEmail from "./pages/verify-email";
import PasswordReset from "./pages/passwordreset";
import { Route, Routes, Navigate } from "react-router-dom";

import { Document } from "react-pdf";
import Registro from "./pages/registro";

initAxiosInterceptors();

function App() {
  const [auth, setAuth] = useState({
    _id: "",
  });

  useEffect(() => {
    async function fetchData() {
      if (!getToken()) {
        return;
      }

      try {
        const res = await axios.get(
          process.env.REACT_APP_API_URL + "/auth/verify"
        );
        if (res.data.data) {
          setAuth(res.data.data);
        } else {
          setAuth({
            _id: "",
            role: "",
          });
        }
      } catch (error) {}
    }
    fetchData();
    document.title = "APP | GRUPO ACTIUS";
  }, []);

  if (auth._id) {
    if (auth.role === "admin") {
      return <AdminMainPage auth={auth} />;
    } else {
      return <MainPage auth={auth} />;
    }
  } else {
    return (
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/verify-email/:token" element={<VerifyEmail />} />
        <Route path="passwordreset/:token" element={<PasswordReset />} />
        <Route
          path="/terminos"
          Component={() => {
            window.location.href =
              "https://drive.google.com/file/d/1LgnuQY0dwUqoDH9lKXgcr0L1X_B4TRCc/view?usp=sharing";
            return null;
          }}
        />
        <Route path="/registro" element={<Registro />} />
        <Route
          path="*"
          element={
            <Navigate
              to="/"
              replace
              state={{ from: window.location.pathname }}
            />
          }
        />
      </Routes>
    );
  }
}

export default App;
