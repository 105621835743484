import React from "react";
import {
  Button,
  TextInputField,
  Pane,
  Combobox,
  Alert,
  Heading,
  Paragraph,
  toaster,
} from "evergreen-ui";
import { initAxiosInterceptors } from "../../../util/authHelper";

import { useState, useEffect } from "react";

import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";

initAxiosInterceptors();

const EnviarCorreoInvitacion = () => {
  const [correo, setCorreo] = useState("");
  const [nombre, setNombre] = useState("");

  return (
    <Pane>
      <Heading
        size={800}
        color="white"
        textAlign="center"
        marginBottom={20}
        marginTop={20}
      >
        Enviar Correo de Invitacion
      </Heading>
      <TextInputField
        label="Correo"
        placeholder="Correo"
        type="email"
        required
        size={800}
        onChange={(e) => {
          setCorreo(e.target.value);
        }}
      />
      <TextInputField
        label="Nombre"
        placeholder="Nombre"
        type="text"
        required
        size={800}
        onChange={(e) => {
          setNombre(e.target.value);
        }}
      />
      <Button
        appearance="primary"
        intent="success"
        marginTop={20}
        marginBottom={20}
        onClick={() => {
            console.log(correo);
            console.log(nombre);
          axios
            .post(process.env.REACT_APP_API_URL + "/users/invite", {
                correo: correo,
                nombre: nombre,   
            })
            .then((res) => {
              console.log(res);
              toaster.success("Correo enviado");
            });
        }}
      >
        Enviar
      </Button>
    </Pane>
  );
};

export default EnviarCorreoInvitacion;
