import React from "react";
import {
  Button,
  Dialog,
  FileUploader,
  FileCard,
  toaster,
} from "evergreen-ui";

const AddFileAndSend = ({
  setShowAddFileAndSend,
  selectedUser,
  onUpdateFileAndSend,
}) => {
  const [files, setFiles] = React.useState([]);
  const [fileRejections, setFileRejections] = React.useState([]);
  const [isConfirmationDialogVisible, setConfirmationDialogVisible] = React.useState(false);

  const handleChange = React.useCallback((files) => setFiles([files[0]]), []);
  const handleRejected = React.useCallback(
    (fileRejections) => setFileRejections([fileRejections[0]]),
    []
  );
  const handleRemove = React.useCallback(() => {
    setFiles([]);
    setFileRejections([]);
  }, []);

  React.useEffect(() => {
    if (files.length > 0) {
      console.log(files[0]);
    }
  }, [files]);

  const handleConfirmSend = () => {
    setConfirmationDialogVisible(true);
  };

  const handleSend = () => {
    toaster.success("Se ha enviado tu comprobante de pago");
    toaster.notify("Favor de esperar a que el administrador valide tu pago");
    onUpdateFileAndSend(files);
    setShowAddFileAndSend(false);
  };

  const handleCancelSend = () => {
    setConfirmationDialogVisible(false);
  };

  return (
    <React.Fragment>
      <Dialog
        isShown={isConfirmationDialogVisible}
        title="Confirmar envío"
        intent="success"
        onCloseComplete={handleCancelSend}
        confirmLabel="Confirmar"
        onConfirm={handleSend}
        cancelLabel="Cancelar"
      >
        ¿Estás seguro de que deseas enviar el archivo adjunto?
      </Dialog>

      <Dialog
        isShown={true}
        title="Agregar archivo y enviar"
        onCloseComplete={() => setShowAddFileAndSend(false)}
        confirmLabel="Agregar"
        hasFooter={false}
      >
        <FileUploader
          label="Adjunta tu comprobante de pago"
          description=":)"
          maxSizeInBytes={2000000}
          multiple={false}
          onRejected={handleRejected}
          onChange={handleChange}
          renderFile={(file) => {
            const { name, size, type } = file;
            const fileRejection = fileRejections.find(
              (fileRejection) => fileRejection.file === file
            );
            const { errors } = fileRejection || {};
            return (
              <FileCard
                key={file.name}
                isInvalid={!!fileRejection}
                name={name}
                onRemove={handleRemove}
                sizeInBytes={size}
                type={type}
                validationMessage={errors && errors[0].message}
              />
            );
          }}
          values={files}
        />
        <Button
          onClick={handleConfirmSend}
          appearance="primary"
          intent="success"
          marginTop={16}
        >
          Enviar
        </Button>
      </Dialog>
    </React.Fragment>
  );
};

export default AddFileAndSend;
