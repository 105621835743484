import React from "react";
import { Button, TextInput, Pane } from "evergreen-ui";
import { useLocation } from "react-router-dom";
import { removeToken } from "../util/authHelper";
import { ProSidebarProvider } from "react-pro-sidebar";
import { useState, useEffect } from "react";
import axios from "axios";
import Etd from "../components/etd/etd";
import { Menu, Popover, Position, Heading } from "evergreen-ui";
import { AiOutlineUser, AiOutlineBarChart, AiFillLock } from "react-icons/ai";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import SideMenu from "../components/mainpage/sideMenu";
import Impulspay from "../components/impulspay/impulspay";

import { AnimatePresence } from "framer-motion";
import WhatsappSoporte from "../components/WhatsappSoporte";
import Bento from "../components/bento";
import Seguros from "../components/protecc/seguros";
import TPVMain from "../components/tpv/TPVMain";
import PortalTPV from "../components/tpv/PortalTPV";

const MainPage = (props) => {
  const location = useLocation();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [etd, setEtd] = useState([]);
  const [services, setServices] = useState([]);
  const api = process.env.REACT_APP_API_URL;
  const [margin, setMargin] = useState(
    window.innerWidth > 768 ? "250px" : "0px"
  );
  const [active, setActive] = useState("bento");

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await axios.get(api + "/etd/user/" + props.auth._id);

        setEtd(res.data);
        if (res.data._id.length > 0 && res.data._id !== undefined) {
          setServices(...services, { etd: false, qmc: false, impulspay: true });
          const res2 = await axios.get(api + "/users/" + props.auth._id);

          setUser(res2.data);
          setLoading(false);
        } else {
          setServices(...services, { etd: false, qmc: false, impulspay: true });
          const res2 = await axios.get(api + "/users/" + props.auth._id);
          setUser(res2.data);
          setLoading(false);
        }
      } catch (error) {
        setServices(...services, { etd: false, qmc: false });
        const res2 = await axios.get(api + "/users/" + props.auth._id);
        setUser(res2.data);
        setLoading(false);
      }
    }

    const handleResize = () => {
      setMargin(window.innerWidth > 768 ? "250px" : "0px");
    };
    window.addEventListener("resize", handleResize);

    fetchData();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleUserChange = (user) => {
    setUser(user);
  };

  const handleCerrrarSesion = () => {
    removeToken();
    window.location.href = "/";
  };

  if (loading) {
    return <h1>Cargando...</h1>;
  }

  return (
    <Pane display="flex">
      <WhatsappSoporte />
      <ProSidebarProvider>
        <SideMenu options={services} user={user} active={active}  />
        <main
          style={{
            backgroundColor: "#030409",
            height: "100%",
            width: "100%",
            marginLeft: margin,
          }}
        >
          <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname}>
              {/**
               * <Route path="/" element={<Etd etd={etd} user={user} />} />
               * <Route path="/etd" element={<Etd etd={etd} user={user} />} />
               */}

              <Route
                path="/"
                element={
                  <Bento user={user} />
                }
              />
              <Route
                path="/impulspay/tarjetas"
                element={
                  <Impulspay user={user} onUserChange={handleUserChange} />
                }
              />
              <Route
                path="/protecc/seguros"
                element={
                  <Seguros user={user} />
                }
              />
              <Route
                path="/tpv"
                element={
                  <TPVMain user={user} />
                }
              />              
           
              
            </Routes>
          </AnimatePresence>
          <div
            style={{
              top: "30px",
              right: "50px",
              position: "absolute",
            }}
          >
            <Popover
              position={Position.BOTTOM_RIGHT}
              content={
                <Menu>
                  <Menu.Group>
                    <Menu.Item disabled icon={<AiOutlineUser />}>
                      {" "}
                      Mi perfil
                    </Menu.Item>
                    <Menu.Item
                      icon={<AiFillLock color="red" />}
                      onSelect={handleCerrrarSesion}
                      intent="danger"
                    >
                      Cerrar sesión
                    </Menu.Item>
                  </Menu.Group>
                </Menu>
              }
            >
              <Button
                icon
                style={{
                  borderRadius: "50%",
                  width: "50px",
                  height: "50px",
                }}
              >
                <AiOutlineUser size={50} />
              </Button>
            </Popover>
          </div>
        </main>
      </ProSidebarProvider>
    </Pane>
  );
};

export default MainPage;
