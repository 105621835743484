import React, { useState, useEffect } from "react";
import axios from "axios";
import { Pane, Heading, Paragraph } from "evergreen-ui";
import { FaSearch } from "react-icons/fa";
import { motion } from "framer-motion";
import SegurosDetail from "./segurosDetail";

const Seguros = ({ user }) => {



  return (
    <motion.div
    key={"seguros"}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{}}
    >
      <Pane
        display="flex"
        justifyContent="center"
        flexDirection="column"
        padding={40}
        color="white"
        zIndex={1}
        className="protecc-header"
      >
        <Heading size={800} color="white">
          Protecc Seguros
        </Heading>
      </Pane>
      <Pane
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        marginTop={20}
        gap={20}
      >
        <Paragraph size={400} color="#e1e4f4"
            fontSize="1.5em"
            fontWeight="bold"
        >
          Bienvenido, {user.nombre} {user.apellido}
        </Paragraph>
        <Paragraph
            fontSize="1em"
            fontWeight="bold"
            color="#e1e4f4"
        >
            Aqui podrás revisar tus seguros contratados, así como los detalles de cada uno.
        </Paragraph>
      </Pane>
      <Pane>
        <SegurosDetail user={user} />
      </Pane>
    </motion.div>
  );
};

export default Seguros;
