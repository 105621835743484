import React from "react";

import { Pane, Button, Heading, toaster } from "evergreen-ui";

import { motion } from "framer-motion";

import { useFormik } from "formik";

import axios from "axios";

import {
  initAxiosInterceptors,
  updateOnCloseSession,
} from "../../../util/authHelper";

updateOnCloseSession(() => (window.location.href = "/"));

const CardDetails = (props) => {
  console.log(props);

  const handleDeleteCard = () => {
    initAxiosInterceptors();
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/users/deletecard/${props.usuario}`,
        {
          cardId: props.cardId,
        },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        console.log("res", res);
        toaster.success("Tarjeta eliminada");
        props.updateCards();
      })
      .catch((err) => {
        console.log("err", err);
        toaster.danger("Error al eliminar tarjeta");
        props.updateCards();
      });
  };
  const handlePagarCard = () => {
    initAxiosInterceptors();
    console.log("props", props);
    
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/users/cards/pago`,
        {
          idCard: props.cardId,
          idUser: props.usuario,
        },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        console.log("res", res);
        toaster.success("Pago realizado");
        props.updateCards();
      })
      .catch((err) => {
        console.log("err", err);
        toaster.danger("Error al realizar pago");
        props.updateCards();
      });
  };
  return (
    <motion.div
      style={{
        backgroundColor: "#9caae2",
        borderRadius: "5px",
        minWidth: "200px",
        userSelect: "none",
        padding: "10px",
        textAlign: "center",
        margin: "auto",
      }}
      initial={{ y: -100, zIndex: -1000, opacity: 0 }}
      animate={{ y: 0, zIndex: 1000, opacity: 1 }}
      exit={{ y: -100, opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Pane
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap={10}
      >
        <Heading size={800}>Detalles de la tarjeta</Heading>
        <Heading size={500}>Nombre: {props.name}</Heading>
        <Heading size={500}>Número: {props.last4}</Heading>
        <Heading size={500}>Contrato: {props.contrato}</Heading>
        <Heading size={500}>
          Fecha de expiración:{" "}
          {new Date(props.fecha_renovacion).toLocaleDateString()}
        </Heading>
      </Pane>
      <Pane display="flex" justifyContent="center" gap={10}>
        <Button
          onClick={handleDeleteCard}
          appearance="primary"
          intent="danger"
          marginTop="10px"
        >
          Eliminar tarjeta
        </Button>
        <Button
          onClick={handlePagarCard}
          appearance="primary"
          intent="success"
          marginTop="10px"
        >
          Pagar
        </Button>
      </Pane>
    </motion.div>
  );
};

export default CardDetails;
