import React, {useState} from "react";

import { Paragraph } from "evergreen-ui";

import facilCard from "../../../img/FacilCard.png";

import { AnimatePresence, motion } from "framer-motion";
import CardDetails from "./cardDetails";

const Card = (props) => {
  console.log(props);

  const [toggleCardDetails, setToggleCardDetails] = useState(false);

  const toggleCardDetailsHandler = () => {
    setToggleCardDetails(!toggleCardDetails);
  };



  return (
    <>
    <AnimatePresence>
      <motion.div
        key={props.index}
        layoutId={props.index}
        style={{
          borderRadius: "10px",
          minWidth: "189px",
          minHeight: "300px",
          backgroundColor: "white",
          backgroundImage: `url(${facilCard})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          userSelect: "none",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          padding: "20px",
          
        }}
        initial={{ opacity: 0 }}
        animate={{ scale: 1 , opacity: 1}}
        exit={{scale: 0, opacity: 0 }}
        whileHover={{ scale: 1.02, cursor: "pointer" }}
        whileTap={{ scale: 0.95 }}
        className="card"
        onClick={toggleCardDetailsHandler}
      >
        <Paragraph
          fontSize="15px"
          color="#000"
          textAlign="center"
          fontWeight="bold"
        >
          {props.name}
        </Paragraph>
        <Paragraph
          fontSize="15px"
          color="000"
          fontWeight="bold"
          
        >
          **** **** **** {props.last4}
        </Paragraph>
      </motion.div>
    </AnimatePresence>
    <AnimatePresence>
        {
          toggleCardDetails && <CardDetails {... props} />
        }
    </AnimatePresence>
    </>
  );
};

export default Card;
