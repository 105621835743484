import React, { useState, useCallback, useMemo } from "react";
import {
  Pane,
  Heading,
  TextInput,
  Dialog,
  FileUploader,
  FileCard,
  Paragraph,
  toaster,
} from "evergreen-ui";
import { motion } from "framer-motion";
import { IoAddCircleOutline } from "react-icons/io5";
import { FaEdit } from "react-icons/fa";
import DialogEditNameCaja from "./DialogEditNameCaja";
import axios from "axios";

const CajasDetalles = ({
  cajas,
  tpvEntity,
  sucursal,
  setTpvEntity,
  setSelectedSucursal,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showEditNameCaja, setShowEditNameCaja] = useState(false);
  const [selectedCaja, setSelectedCaja] = useState(null);
  const [showRequestCajaDialog, setShowRequestCajaDialog] = useState(false);
  const [newCajaNombre, setNewCajaNombre] = useState("");
  const [newCajaCorreo, setNewCajaCorreo] = useState("");
  const [files, setFiles] = useState([]);
  const [fileRejections, setFileRejections] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = useCallback((files) => setFiles([files[0]]), []);
  const handleRejected = useCallback((fileRejections) => setFileRejections([fileRejections[0]]), []);
  const handleRemove = useCallback(() => {
    setFiles([]);
    setFileRejections([]);
  }, []);

  const validateFields = () => {
    const newErrors = {};
    if (!newCajaNombre.trim()) newErrors.nombre = "El nombre es requerido";
    if (!newCajaCorreo.trim()) newErrors.correo = "El correo es requerido";
    if (!files.length) newErrors.file = "El comprobante de pago es requerido";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRequestCaja = useCallback(() => {
    if (!validateFields()) return;

    const formData = new FormData();
    formData.append("nombre", newCajaNombre);
    formData.append("correo", newCajaCorreo);
    formData.append("file", files[0]);
    formData.append("sucursalId", sucursal.sucursalId);

    axios
      .post(`${process.env.REACT_APP_API_URL}/tpv/solicitud/${tpvEntity._id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        toaster.success("Solicitud de caja enviada exitosamente");
        setTpvEntity(res.data.tpv);
        setSelectedSucursal(res.data.tpv.sucursales.find((s) => s.sucursalId === sucursal.sucursalId));
      })
      .catch((err) => {
        toaster.danger("Error al enviar la solicitud de caja");
      });

    setNewCajaNombre("");
    setNewCajaCorreo("");
    setFiles([]);
    setShowRequestCajaDialog(false);
    setShowConfirmDialog(false);
  }, [newCajaNombre, newCajaCorreo, files, sucursal.sucursalId, tpvEntity._id, setTpvEntity, setSelectedSucursal]);

  const filteredCajas = useMemo(() => {
    return cajas.filter(
      (caja) =>
        caja.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
        caja.correo.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [cajas, searchTerm]);

  const handleEditName = useCallback((caja) => {
    setSelectedCaja(caja);
    setShowEditNameCaja(true);
  }, []);

  return (
    <Pane display="flex" flexDirection="column" alignItems="center" padding={30} gap={20}>
      <TextInput
        placeholder="Buscar por nombre o correo..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        maxWidth={400}
        marginBottom={20}
        backgroundColor="rgba(255, 255, 255, 0.1)"
        fontSize={18}
        color="white"
      />
      <Pane display="flex" flexDirection="row" gap={20} flexWrap="wrap" justifyContent="center">
        {filteredCajas.length === 0 && (
          <Heading color="white">No se encontraron cajas con los criterios de búsqueda.</Heading>
        )}
        {filteredCajas.map((caja) => (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              backgroundColor: "rgba(0,0,0,0.3)",
              padding: 20,
              borderRadius: 10,
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
            key={caja._id}
          >
            <Heading size={900} color="white" marginBottom={10}>
              {caja.nombre}{" "}
              <FaEdit
                size={20}
                color="white"
                style={{ cursor: "pointer" }}
                onClick={() => handleEditName(caja)}
              />
            </Heading>
            <Heading size={500} color="white" marginBottom={10}>
              {caja.correo}
            </Heading>
            <Heading size={500} color="white" marginBottom={10}>
              Fecha de solicitud: {new Date(caja.fecha_solicitud).toLocaleDateString()}
            </Heading>
            <Heading size={500} color="white" marginBottom={10}>
              Estado:{" "}
              <span style={{ color: caja.estado === "Aprobada" ? "green" : "yellow" }}>
                {caja.estado}
              </span>
            </Heading>
            {caja.fecha_renovacion ? (
              <Heading size={500} color="white" marginBottom={10}>
                Fecha de renovación: {new Date(caja.fecha_renovacion).toLocaleDateString()}
              </Heading>
            ) : (
              <Heading size={500} color="white" marginBottom={10}>
                Pendiente de fecha de renovación
              </Heading>
            )}
          </motion.div>
        ))}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{
            backgroundColor: "rgba(0,0,0,0.3)",
            padding: 20,
            borderRadius: 10,
            userSelect: "none",
            cursor: "pointer",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          whileHover={{
            scale: 1.05,
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
          }}
          whileTap={{ scale: 0.95 }}
          onClick={() => setShowRequestCajaDialog(true)}
        >
          <Heading size={500} color="white" marginBottom={10}>
            Solicitar nueva caja
          </Heading>
          <IoAddCircleOutline size={40} color="white" />
        </motion.div>
      </Pane>

      {showEditNameCaja && selectedCaja && (
        <DialogEditNameCaja
          setShowEditNameCaja={setShowEditNameCaja}
          showEditNameCaja={showEditNameCaja}
          caja={selectedCaja}
          sucursal={sucursal}
          tpvEntity={tpvEntity}
          setTpvEntity={setTpvEntity}
          setSelectedSucursal={setSelectedSucursal}
        />
      )}
      <Dialog
        isShown={showRequestCajaDialog}
        title="Solicitar nueva caja"
        onCloseComplete={() => setShowRequestCajaDialog(false)}
        confirmLabel="Revisar solicitud"
        onConfirm={() => {
          if (validateFields()) setShowConfirmDialog(true);
        }}
      >
        <TextInput
          placeholder="Nombre de la caja"
          value={newCajaNombre}
          onChange={(e) => setNewCajaNombre(e.target.value)}
          marginBottom={20}
          isInvalid={!!errors.nombre}
          validationMessage={errors.nombre}
        />
        <TextInput
          placeholder="Correo de la caja"
          value={newCajaCorreo}
          onChange={(e) => setNewCajaCorreo(e.target.value)}
          marginBottom={20}
          isInvalid={!!errors.correo}
          validationMessage={errors.correo}
        />
        <FileUploader
          label="Subir comprobante de pago"
          description="Sube tu comprobante de pago para solicitar la caja."
          maxFiles={1}
          acceptedMimeTypes={["application/pdf"]}
          onAccepted={handleChange}
          onRejected={handleRejected}
          renderFile={(file) => {
            const { name, size, type } = file;
            return (
              <FileCard
                key={name}
                name={name}
                onRemove={handleRemove}
                sizeInBytes={size}
                type={type}
              />
            );
          }}
          values={files}
        />
        {!!errors.file && <Paragraph color="danger">{errors.file}</Paragraph>}
      </Dialog>
      <Dialog
        isShown={showConfirmDialog}
        title="Confirmar solicitud de nueva caja"
        intent="success"
        onCloseComplete={() => setShowConfirmDialog(false)}
        confirmLabel="Confirmar solicitud"
        onConfirm={handleRequestCaja}
      >
        <Paragraph>
          ¿Está seguro de que desea solicitar una nueva caja con los siguientes detalles?
        </Paragraph>
        <Paragraph>
          <strong>Nombre:</strong> {newCajaNombre}
        </Paragraph>
        <Paragraph>
          <strong>Correo:</strong> {newCajaCorreo}
        </Paragraph>
      </Dialog>
    </Pane>
  );
};

export default CajasDetalles;
