import React, { useEffect, useState } from "react";
import { Button, TextInput, Pane, Heading, TextInputField, SearchInput } from "evergreen-ui";
import axios from "axios";
import { initAxiosInterceptors } from "../../util/authHelper";
import UserDetail from "./users/UserDetail";

initAxiosInterceptors();

const AdminUsers = () => {
  const [users, setUsers] = React.useState([]);
  const [allUsers, setAllUsers] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState({});
  const [search, setSearch] = React.useState("");

  async function fetchData() {
    try {
      const res = await axios.get(process.env.REACT_APP_API_URL + "/users");
      const users = res.data;
      const justUsers = users.filter((user) => user.role !== "admin");
      setAllUsers(justUsers);
      setUsers(justUsers);
    } catch (error) {
      
    }
  }

  useEffect(() => {
    console.log("AdminUsers");
    
    fetchData();
  }, []);

  useEffect(() => {
    const toSearch = allUsers;
    const usersSearch = toSearch.filter((user) => {
      if (user.nombre.toLowerCase().includes(search.toLowerCase())) {
        return true;
      }
      if (user.apellido.toLowerCase().includes(search.toLowerCase())) {
        return true;
      }
      if (user.correo.toLowerCase().includes(search.toLowerCase())) {
        return true;
      }
      return false;
    });
    setUsers(usersSearch);
  }, [search]);

  useEffect(() => {
    console.log("selectedUser", selectedUser);
  }, [selectedUser]);

  return (
    <div>
      <Pane
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Heading>Panel de administración de usuarios</Heading>
        <Heading size={900}>Usuarios</Heading>
        <Pane
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          gap={20}
        >
          <Pane display="flex" flexDirection="column" gap={20}>
            <TextInputField
              label="Buscar"
              placeholder="Buscar"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />

            {users.map((user) => (
              <Pane
                key={user._id}
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                borderRadius={3}
                style={{ cursor: "pointer", userSelect: "none" }}
                height="100%"
                padding={20}
                elevation={2}
                onClick={() => setSelectedUser(user)}
                background={selectedUser._id === user._id ? "blue" : "tint2"}
              >
                <Pane display="flex" flexDirection="column" height="100%">
                  <Heading size={600}>
                    {user.nombre} {user.apellido}
                  </Heading>
                  <Heading size={400}>{user.correo}</Heading>
                </Pane>
              </Pane>
            ))}
          </Pane>
          <Pane
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            background="tint2"
            padding={20}
            borderRadius={3}
            elevation={2}
          >
            <UserDetail user={selectedUser} updateUsers={fetchData} />
          </Pane>
        </Pane>
      </Pane>
    </div>
  );
};

export default AdminUsers;
