import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Pane,
  Heading,
  toaster,
  TextInput,
  Select,
} from "evergreen-ui";
import axios from "axios";
import { FaCheckCircle, FaClock } from "react-icons/fa";

const SolicitudTable = () => {
  const [solicitudes, setSolicitudes] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [uniqueYears, setUniqueYears] = useState([]);
  const [uniqueMonths, setUniqueMonths] = useState([]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "/solicitud")
      .then((response) => {
        const sortedSolicitudes = response.data.sort(
          (a, b) => new Date(b.fecha) - new Date(a.fecha)
        );
        setSolicitudes(sortedSolicitudes);

        const yearsSet = new Set();
        const monthsSet = new Set();

        sortedSolicitudes.forEach((solicitud) => {
          const date = new Date(solicitud.fecha);
          yearsSet.add(date.getFullYear());
          monthsSet.add(date.getMonth());
        });

        setUniqueYears(Array.from(yearsSet));
        setUniqueMonths(Array.from(monthsSet));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleStatusChange = (id, newStatus) => {
    axios
      .put(process.env.REACT_APP_API_URL + "/solicitud/" + id, {
        status: newStatus,
        correo: solicitudes.find((solicitud) => solicitud._id === id).correo,
        tipo: solicitudes.find((solicitud) => solicitud._id === id).tipo,
        monto: solicitudes.find((solicitud) => solicitud._id === id).monto,
        pesos_depositados: solicitudes.find((solicitud) => solicitud._id === id)
          .pesos_depositados,
      })
      .then((response) => {
        setSolicitudes((prevSolicitudes) =>
          prevSolicitudes.map((solicitud) =>
            solicitud._id === id
              ? { ...solicitud, status: newStatus }
              : solicitud
          )
        );
        toaster.success("Status actualizado");
      })
      .catch((error) => {
        console.error("Error updating status:", error);
        toaster.danger("Error al actualizar status");
      });
  };

  const getMoneda = (tipo) => {
    return tipo === "spei" ? "MXN" : "USD";
  };

  const formatDate = (fecha) => {
    const dateObject = new Date(fecha);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };

    return dateObject.toLocaleDateString("es-MX", options);
  };

  const filteredSolicitudes = solicitudes.filter((solicitud) => {
    if (selectedYear !== null && selectedMonth !== null) {
      const date = new Date(solicitud.fecha);
      return (
        date.getFullYear() === selectedYear && date.getMonth() === selectedMonth
      );
    } else if (selectedYear !== null) {
      const date = new Date(solicitud.fecha);
      return date.getFullYear() === selectedYear;
    } else if (selectedMonth !== null) {
      const date = new Date(solicitud.fecha);
      return date.getMonth() === selectedMonth;
    }
    return true;
  });

  return (
    <Pane>
      <Pane
        elevation={3}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        padding={35}
        color="white"
        alignItems="center"
        borderRadius={10}
        zIndex={1}
        className="impuls-header"
      >
        <Heading size={800} color="white">
          {" "}
          Panel de administración de solicitudes{" "}
        </Heading>
      </Pane>
      <Pane padding={20} paddingLeft={50} paddingRight={50}>
        <Pane
          display="flex"
          marginBottom={20}
          gap={20}
          justifyContent="center"
          flexDirection="row"
          alignItems="center"
        >
          <Select
            width={120}
            onChange={(e) => setSelectedYear(parseInt(e.target.value))}
            value={selectedYear}
            defaultValue=""
            backgroundColor="white"
            size="large"
          >
            <option value="" disabled>
              Seleccionar Año
            </option>
            {uniqueYears.map((year, index) => (
              <option key={index} value={year}>
                {year}
              </option>
            ))}
          </Select>
          <Select
            width={120}
            onChange={(e) =>
              setSelectedMonth(
                e.target.value === "all" ? null : parseInt(e.target.value)
              )
            }
            value={selectedMonth === null ? "all" : selectedMonth}
            defaultValue=""
            backgroundColor="white"
            size="large"
          >
            <option value="" disabled>
              Seleccionar Mes
            </option>
            <option value="all">Todos los meses</option>
            {uniqueMonths.map((monthIndex, index) => (
              <option key={index} value={monthIndex}>
                {new Date(0, monthIndex).toLocaleString("es-MX", {
                  month: "long",
                })}
              </option>
            ))}
          </Select>
        </Pane>
        <Table>
          <Table.Head>
            <Table.TextHeaderCell fontSize={16}>
              ID Usuario
            </Table.TextHeaderCell>
            <Table.TextHeaderCell fontSize={16}>Correo</Table.TextHeaderCell>
            <Table.TextHeaderCell fontSize={16}>Moneda</Table.TextHeaderCell>
            <Table.TextHeaderCell fontSize={16}>Monto</Table.TextHeaderCell>
            <Table.TextHeaderCell fontSize={16}>
              Ultimos 4 digitos
            </Table.TextHeaderCell>
            <Table.TextHeaderCell fontSize={16}>Tipo</Table.TextHeaderCell>
            <Table.TextHeaderCell fontSize={16}>Fecha</Table.TextHeaderCell>
            <Table.TextHeaderCell fontSize={16}>Status</Table.TextHeaderCell>
            <Table.TextHeaderCell fontSize={16}>
              Pesos mexicanos depositados
            </Table.TextHeaderCell>
            <Table.TextHeaderCell fontSize={16}>Acciones</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {filteredSolicitudes.map((solicitud) => (
              <Table.Row key={solicitud._id}>
                <Table.TextCell fontSize={14}>
                  {solicitud.id_usuario}
                </Table.TextCell>
                <Table.TextCell fontSize={14}>
                  {solicitud.correo}
                </Table.TextCell>
                <Table.TextCell fontSize={14}>
                  {getMoneda(solicitud.tipo)}
                </Table.TextCell>
                <Table.TextCell fontSize={14}>{solicitud.monto}</Table.TextCell>
                <Table.TextCell fontSize={14}>{solicitud.last4}</Table.TextCell>
                <Table.TextCell fontSize={14}>{solicitud.tipo}</Table.TextCell>
                <Table.TextCell fontSize={14}>
                  {formatDate(solicitud.fecha)}
                </Table.TextCell>
                <Table.TextCell fontSize={14}>
                  {solicitud.status === "Pendiente" ? (
                    <span style={{ color: "orange" }}>
                      <FaClock /> Pendiente
                    </span>
                  ) : (
                    <span style={{ color: "green" }}>
                      <FaCheckCircle /> Terminado
                    </span>
                  )}
                </Table.TextCell>
                <Table.TextCell fontSize={14}>
                  <TextInput
                    width={100}
                    value={solicitud.pesos_depositados}
                    disabled={solicitud.status === "Terminado"}
                    onChange={(e) => {
                      setSolicitudes((prevSolicitudes) =>
                        prevSolicitudes.map((solicitudMap) =>
                          solicitudMap._id === solicitud._id
                            ? {
                                ...solicitudMap,
                                pesos_depositados: e.target.value,
                              }
                            : solicitudMap
                        )
                      );
                    }}
                  />
                </Table.TextCell>
                <Table.Cell>
                  {solicitud.status === "Pendiente" && (
                    <Button
                      onClick={() =>
                        handleStatusChange(solicitud._id, "Terminado")
                      }
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      Marcar como Terminado
                    </Button>
                  )}
                  {solicitud.status === "Terminado" && (
                    <Button
                      onClick={() =>
                        handleStatusChange(solicitud._id, "Pendiente")
                      }
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      Marcar como Pendiente
                    </Button>
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Pane>
    </Pane>
  );
};

export default SolicitudTable;
