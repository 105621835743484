import React from "react";
import { Paragraph } from "evergreen-ui";
import { motion } from "framer-motion";

const UserSeguro = ({ usuario, selected, onClick }) => {
  return (
    <motion.div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px",
        backgroundColor: selected ? "#7E57C2" : "#673AB7", // Morado para seleccionado, Púrpura para no seleccionado
        borderRadius: "10px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)", // Sombra sutil
        height: "100px",
        cursor: "pointer",
      }}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={onClick}
    >
      <Paragraph size={500} color={"#FFFFFF"} textAlign="center"> {/* Texto blanco para contraste */}
        {usuario.nombre} {usuario.apellido}
      </Paragraph>
    </motion.div>
  );
};

export default UserSeguro;