import React, { useState } from "react";
import { Pane, Heading, TextInput } from "evergreen-ui";
import { motion } from "framer-motion";
import { IoAddCircleOutline } from "react-icons/io5";

const TerminalesSelector = ({
  tpvEntity,
  setSelectedSucursal,
  selectedSucursal,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const sucursales = tpvEntity.sucursales;

  const filteredSucursales = sucursales.filter(
    (sucursal) =>
      sucursal.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
      sucursal.correo.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Pane
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding={30}
      gap={20}
    >
      <TextInput
        placeholder="Buscar por nombre o correo..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        maxWidth={400}
        marginBottom={20}
        backgroundColor="rgba(255, 255, 255, 0.1)"
        fontSize={18}
        color="white"
      />
      <Pane
        display="flex"
        flexDirection="row"
        gap={20}
        flexWrap="wrap"
        justifyContent="center"
      >
        {filteredSucursales.length === 0 && (
          <Heading
            size={500}
            color="white"
            marginBottom={10}
            textAlign="center"
          >
            No cuentas con terminales asignadas, por favor, contacta a un
            administrador
          </Heading>
        )}
        {filteredSucursales.map((sucursal) => (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              backgroundColor:
                selectedSucursal?.sucursalId === sucursal.sucursalId
                  ? "rgba(0,0,0,0.8)"
                  : "rgba(0,0,0,0.3)",
              padding: 20,
              borderRadius: 10,
              userSelect: "none",
              cursor: "pointer",
            }}
            whileHover={{
              scale: 1.05,
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
            }}
            whileTap={{ scale: 0.95 }}
            key={sucursal._id}
            onClick={() => setSelectedSucursal(sucursal)}
          >
            <Heading
              size={900}
              color={
                selectedSucursal?.sucursalId === sucursal.sucursalId
                  ? "white"
                  : "gray"
              }
              marginBottom={10}
            >
              {sucursal.nombre}
            </Heading>
            <Heading
              size={500}
              color={
                selectedSucursal?.sucursalId === sucursal.sucursalId
                  ? "white"
                  : "gray"
              }
              marginBottom={10}
            >
              {sucursal.correo}
            </Heading>
          </motion.div>
        ))}
        {filteredSucursales.length > 0 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              backgroundColor: "rgba(0,0,0,0.3)",
              padding: 20,
              borderRadius: 10,
              userSelect: "none",
              cursor: "pointer",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
            whileHover={{
              scale: 1.05,
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
            }}
            whileTap={{ scale: 0.95 }}
          >
            <Heading size={500} color="white" marginBottom={10}>
              Agregar nueva sucursal
            </Heading>
            <IoAddCircleOutline size={40} color="white" />
          </motion.div>
        )}
      </Pane>
    </Pane>
  );
};

export default TerminalesSelector;
