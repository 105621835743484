import React, { useEffect, useState } from "react";
import {
  Pane,
  Heading,
  TextInput,
  Dialog,
  toaster,
  FileUploader,
  FileCard,
  Button,
  Select,
  Paragraph,
} from "evergreen-ui";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AddSeguro = ({ showAddSeguro, setShowAddSeguro, usuario, onUpdated }) => {
  const tipos = ["Vida", "Salud", "Daños"];
  const frecuenciasPago = ["Mensual", "Trimestral", "Semestral", "Anual"];
  const aseguradoras = [
    "CHUBB",
    "SEGUROS ANA",
    "GNP",
    "HDI",
    "MAPFRE",
    "EL POTOSÍ",
    "PRIMERO SEGUROS",
    "SURA",
    "QUALITAS",
    "ZURICH",
    "IMSS",
    "AXA",
    "ALLIANZ",
    "SEGUROS MONTERREY",
    "MUTUUS"
  ];
  

  const [fechaInicio, setFechaInicio] = useState(new Date());
  const [frecuenciaPago, setFrecuenciaPago] = useState("");

  const calculateNextPayment = () => {
    const nextPaymentDate = new Date(fechaInicio);
    switch (frecuenciaPago) {
      case "Mensual":
        nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 1);
        break;
      case "Trimestral":
        nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 3);
        break;
      case "Semestral":
        nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 6);
        break;
      case "Anual":
        nextPaymentDate.setFullYear(nextPaymentDate.getFullYear() + 1);
        break;
      default:
        break;
    }
    formik.setFieldValue("siguiente_pago", nextPaymentDate);
  };

  const validationSchema = Yup.object({
    tipo: Yup.string().required("Campo requerido"),
    aseguradora: Yup.string().required("Campo requerido"),
    producto: Yup.string().required("Campo requerido"),
    contratante: Yup.string().required("Campo requerido"),
    asegurado_titular: Yup.string().required("Campo requerido"),
    suma_asegurada: Yup.number().required("Campo requerido"),
    fecha_inicio: Yup.date().required("Campo requerido"),
    siguiente_pago: Yup.date().required("Campo requerido"),
    frecuencia_pago: Yup.string().required("Campo requerido"),
    file: Yup.mixed().required("Campo requerido"),
  });

  const formik = useFormik({
    initialValues: {
      tipo: "Vida",
      aseguradora: "",
      producto: "",
      contratante: "",
      asegurado_titular: "",
      suma_asegurada: "N/A",
      fecha_inicio: new Date(),
      siguiente_pago: new Date(),
      frecuencia_pago: "",
      file: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      try {
        axios
          .post(process.env.REACT_APP_API_URL + "/seguro/", {
            usuario: usuario._id,
            tipo: values.tipo,
            aseguradora: values.aseguradora,
            producto: values.producto,
            contratante: values.contratante,
            asegurado_titular: values.asegurado_titular,
            suma_asegurada: values.suma_asegurada,
            fecha_inicio: values.fecha_inicio,
            siguiente_pago: values.siguiente_pago,
            frecuencia_pago: values.frecuencia_pago,
            file: values.file,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
          )
          .then((res) => {
            console.log(res);
            toaster.success("Seguro agregado");
            setShowAddSeguro(false);
            onUpdated();

          });
      } catch (error) {
        toaster.danger("Error al agregar seguro");
        console.log("error", error);
      }
    },
  });

  useEffect(() => {
    calculateNextPayment();
  }, [fechaInicio, frecuenciaPago]);

  const renderField = (name, label, type = "text") => {
    if (type === "file") {
      return (
        <div>
          <Heading size={500}>{label}</Heading>
          <FileUploader
            accept="application/pdf"
            onChange={(acceptedFiles) => {
              formik.setFieldValue(name, acceptedFiles[0]);
            }}
          >
            {({ browseFiles }) => (
              <Button onClick={browseFiles}>Subir archivo</Button>
            )}
          </FileUploader>
          {formik.values[name] && (
            <FileCard
              marginTop={16}
              name={formik.values[name].name}
              size={formik.values[name].size}
            />
          )}
        </div>
      );
    }
    if (type === "date") {
      return (
        <div>
          <Heading size={500}>{label}</Heading>
          <DatePicker
            id={name}
            name={name}
            dateFormat={"dd/MM/yyyy"}
            selected={formik.values[name]}
            onChange={(date) => {
              setFechaInicio(date);
              formik.setFieldValue(name, date);
            }}
            onBlur={formik.handleBlur}
          />
        </div>
      );
    }

    if (type === "date-disabled") {
      return (
        <div>
          <Heading size={500}>{label}</Heading>
          <DatePicker
            id={name}
            name={name}
            dateFormat={"dd/MM/yyyy"}
            selected={formik.values[name]}
            disabled
          />
        </div>
      );
    }
    if (type === "number") {
      return (
        <div>
          <Heading size={500}>{label}</Heading>
          <TextInput
            placeholder={label}
            id={name}
            name={name}
            type="number"
            onChange={formik.handleChange}
            value={formik.values[name]}
            isInvalid={formik.errors[name] && formik.touched[name]}
            validationMessage={
              formik.errors[name] && formik.touched[name]
                ? formik.errors[name]
                : null
            }
            onBlur={formik.handleBlur}
          />
        </div>
      );
    }
    if (type === "option") {
      return (
        <div>
          <Heading size={500}>{label}</Heading>
          <Select
            width="100%"
            id={name}
            name={name}
            onChange={(event) => {
              formik.setFieldValue(name, event.target.value);
            }}
            value={formik.values[name]}
            onBlur={formik.handleBlur}
          >
            {tipos.map((tipo) => (
              <option key={tipo} value={tipo}>
                {tipo}
              </option>
            ))}
          </Select>
        </div>
      );
    }
    if (type === "frequency") {
      return (
        <div>
          <Heading size={500}>{label}</Heading>
          <Select
            width="100%"
            id={name}
            name={name}
            onChange={(event) => {
              setFrecuenciaPago(event.target.value);
              formik.setFieldValue(name, event.target.value);
            }}
            value={formik.values[name]}
            onBlur={formik.handleBlur}
          >
            {frecuenciasPago.map((tipo) => (
              <option key={tipo} value={tipo}>
                {tipo}
              </option>
            ))}
          </Select>
        </div>
      );
    }
    if (type === "aseguradora") {
      return (
        <div>
          <Heading size={500}>{label}</Heading>
          <Select
            width="100%"
            id={name}
            name={name}
            onChange={(event) => {
              formik.setFieldValue(name, event.target.value);
            }}
            value={formik.values[name]}
            onBlur={formik.handleBlur}
          >
            {aseguradoras.map((tipo) => (
              <option key={tipo} value={tipo}>
                {tipo}
              </option>
            ))}
          </Select>
        </div>
      );
    }
    return (
      <div>
        <Heading size={500}>{label}</Heading>
        <TextInput
          placeholder={label}
          id={name}
          name={name}
          type={type}
          onChange={formik.handleChange}
          value={formik.values[name]}
          isInvalid={formik.errors[name] && formik.touched[name]}
          validationMessage={
            formik.errors[name] && formik.touched[name]
              ? formik.errors[name]
              : null
          }
          onBlur={formik.handleBlur}
        />
      </div>
    );
  };

  return (
    <Dialog
      isShown={true}
      title="Agregar dirección para el usuario"
      onConfirm={() => formik.handleSubmit()}
      confirmLabel="Agregar"
      cancelLabel="Cancelar"
      onCloseComplete={() => setShowAddSeguro(false)}
    >
      <Pane
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap={20}
      >
        <Pane display="flex" flexDirection="column" gap={20}>
          <Paragraph>
            Agrega un seguro para el usuario {usuario.nombre} {usuario.apellido}
          </Paragraph>
          {renderField("tipo", "Tipo", "option")}
          {renderField("aseguradora", "Aseguradora", "aseguradora")}
          {renderField("producto", "Producto")}
          {renderField("contratante", "Contratante")}
          {renderField("asegurado_titular", "Asegurado Titular")}
          {renderField("suma_asegurada", "Suma Asegurada", "number")}
          {renderField("fecha_inicio", "Fecha de Inicio", "date")}
          {renderField(
            "frecuencia_pago",
            "Frecuencia de Pago formato DD/MM/AAAA",
            "frequency"
          )}
          {renderField("siguiente_pago", "Siguiente Pago", "date-disabled")}
          {renderField("file", "Poliza", "file")}

        </Pane>
      </Pane>
    </Dialog>
  );
};

export default AddSeguro;
