import React from "react";
import { Pane, Heading, Paragraph, Li } from "evergreen-ui";
import { motion } from "framer-motion";
import tpvimg from "../img/tpv.webp";
import tarjeta from "../img/tarjeta.webp";
import cotizador from "../img/cotizador.jpg";
import seguros from "../img/seguros.jpg";
import whatsapp from "../img/whatsapp.jpg";
import { Link } from "react-router-dom";

const BentoItem = ({ children, backgroundImage, linkto }) => {
  // Estilos condicionales para el fondo
  const itemStyle = backgroundImage
    ? {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center", // Centrar el fondo
      }
    : {};

  return (
    <motion.div
      className="bento-item"
      style={{
        ...itemStyle,
        display: "flex",
        flexDirection: "column",
        justifyContent: "end",
        position: "relative", // Para el contenedor de fondo
        overflow: "hidden", // Oculta el exceso del fondo al transformar
      }}
      whileHover={{ scale: 1.05, zIndex: 1 }} // Efecto al pasar el mouse
      transition={{ type: "just" }} // Suaviza la transición
      whileDrag={{ scale: 1.05 }} // Efecto al arrastrar
    >
      <Link to={linkto}>
        <Pane
          className="bento-item-container"
          display="flex"
          flexDirection="column"
          justifyContent="end"
          padding={20}
          gap={10}
        >
          {children}
        </Pane>
      </Link>
    </motion.div>
  );
};

const Bento = ({ user }) => (
  <motion.div
  key={"bento"}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    style={{ height: "100vh" }}
  >
    <Pane
      className="main-header"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      padding={40}
      color="white"
      zIndex={1}
    >
      <Heading size={800} color="white">
        Pagina principal
      </Heading>
    </Pane>
    <Pane
      display="flex"
      flexDirection="column"
      alignItems="left"
      justifyContent="center"
      gap={20}
      padding={20}
    >
      <Paragraph size={400} color="#e1e4f4" fontSize="1.5em" fontWeight="bold">
        Bienvenido, {user.nombre} {user.apellido}
      </Paragraph>
    </Pane>
    <Pane
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={20}
      padding={20}
      height="80%"
    >
      <Pane className="bento-container">
        <BentoItem backgroundImage={tarjeta} linkto="/impulspay/tarjetas">
          <Heading size={600} color="white">
            ImpulsPay T-BLACK
          </Heading>
          <Paragraph size={200} color="#e1e4f4" fontSize="1em">
            Solicita tarjetas de debito
          </Paragraph>
          <Paragraph size={200} color="#e1e4f4">
            Solicita recargas de saldo
          </Paragraph>
        </BentoItem>
        <BentoItem backgroundImage={whatsapp}>
          <Heading size={600} color="white">
            Envíanos un whatsapp
          </Heading>
          <Paragraph size={200} color="#e1e4f4" fontSize="1em">
            Conoce nuestros productos
          </Paragraph>
        </BentoItem>
        <BentoItem backgroundImage={tpvimg}>
          <Heading size={600} color="white">
            ImpulsPay terminales PDV
          </Heading>
          <Paragraph size={200} color="#e1e4f4" fontSize="1em">
            Solicita terminales de pago
          </Paragraph>
          <span className="proximamente">Próximamente</span>
        </BentoItem>
        <BentoItem backgroundImage={cotizador}>
          <Heading size={600} color="white">
            Cotizador protecc
          </Heading>
          <Paragraph size={200} color="#e1e4f4" fontSize="1em">
            Utiliza nuestro cotizador
          </Paragraph>
          <span className="proximamente">Próximamente</span>
        </BentoItem>
        <BentoItem backgroundImage={seguros} linkto="/protecc/seguros">
          <Heading size={600} color="white">
            Protecc Seguros
          </Heading>
          <Paragraph size={200} color="#e1e4f4" fontSize="1em">
            Revisa tus seguros
          </Paragraph>
          <Paragraph size={200} color="#e1e4f4">
            Revisa los detalles de tus seguros contratados
          </Paragraph>
        </BentoItem>
      </Pane>
    </Pane>
  </motion.div>
);

export default Bento;
