import React from "react";
import { Button, Pane, Heading, SearchInput, toaster } from "evergreen-ui";
import { initAxiosInterceptors, updateOnCloseSession } from "../../../util/authHelper";
import { useState, useEffect } from "react";
import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";
import Card from "./card";
import AddCardDialog from "./Dialogs/AddCardDialog";
import AddAddressDialog from "./Dialogs/AddAddressDialog";
import checkImg from "../../../img/check.jpg";
import { throttle } from "lodash";

initAxiosInterceptors();
updateOnCloseSession(
  () => window.location.href = "/"
  );

const ImpulsPay = () => {
  const [users, setUsers] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState({});
  const [showAddCard, setShowAddCard] = useState(false);
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [isMobile, setIsMobile] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchedCards, setSearchedCards] = useState([]);

  const fetchUsers = async () => {
    try {
      var users = {};
      const res = await axios.get(process.env.REACT_APP_API_URL + "/users/");
      users = res.data;
      //filter users eliminar role admin
      users = users.filter((user) => user.role !== "admin");
      setUsers(users);
      setLoading(false);
      setSearchedCards(selectedUser.cards);    
      
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", throttle(handleResize, 200));
    return () => {
      window.removeEventListener("resize", throttle(handleResize, 200));
    };
  }, []);


  const getUsers = async () => {
    try {
      var users = {};
      const res = await axios.get(process.env.REACT_APP_API_URL + "/users/");
      users = res.data;
      //filter users eliminar role admin
      users = users.filter((user) => user.role !== "admin");
      return users;
    } catch (error) {
      console.log("error", error);
    }
  };

  const updateCards = async () => {
    getUsers().then((users) => {
      setUsers(users);
      // Actualiza el usuario seleccionado con las nuevas tarjetas
      if (selectedUser._id) {
        const user = users.find((user) => user._id === selectedUser._id);

        // Compara la longitud de la lista de tarjetas antes y después de llamar a getUsers
        if (user.cards.length !== selectedUser.cards.length) {
          setSelectedUser(user);
        }

        // Actualiza la lista de usuarios
        setUsers(users);
        setSearchedCards(selectedUser.cards);
        handleCardSearcher({ target: { value: searchTerm } });

      }
    });
  };

  const handleSearcher = (e) => {
    var users = {};
    axios.get(process.env.REACT_APP_API_URL + "/users/").then((res) => {
      users = res.data;
      //filter users eliminar role admin
      users = users.filter((user) => user.role !== "admin");
      users = users.filter(
        (user) =>
          user.nombre.toLowerCase().includes(e.target.value.toLowerCase()) ||
          user.apellido.toLowerCase().includes(e.target.value.toLowerCase()) ||
          user.correo.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setUsers(users);
    });
  };

  const handleCardSearcher = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === "") {
      setSearchedCards(selectedUser.cards);
    } else {
      var cards = selectedUser.cards.filter((card) =>
        card.last4.includes(e.target.value)
      );
      console.log(cards);
      setSearchedCards(cards);
    }
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setSearchedCards(user.cards);
  };

  const closeAddCard = () => {
    setShowAddCard(false);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleSetDefaultAddress = (address) => {
    axios
      .post(
        process.env.REACT_APP_API_URL +
          "/users/" +
          selectedUser._id +
          "/addressess/" +
          address._id +
          "/setdefault"
      )
      .then((res) => {
        setSelectedUser(res.data.user);
        const updatedUsers = users.map((user) => {
          if (user._id === res.data.user._id) {
            return res.data.user;
          } else {
            return user;
          }
        }
        );
        setUsers(updatedUsers);
      });
  };

  useEffect(() => {
    getUsers().then((users) => {
      setUsers(users);      // Actualiza el usuario seleccionado con las nuevas tarjetas
      if (selectedUser._id) {
        const user = users.find((user) => user._id === selectedUser._id);
        // Compara la longitud de la lista de tarjetas antes y después de llamar a getUsers
        
        setSelectedUser(user);
        setSearchedCards(user.cards);
      }
    });
  }, [
    showAddCard,
    showAddAddress,
    selectedAddress,
    
  ]);

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <Pane background="paper">
      <Pane
        elevation={3}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        padding={35}
        color="white"
        alignItems="center"
        borderRadius={10}
        zIndex={1}
        className="impuls-header"
      >
        <Heading size={
          isMobile ? 500 : 800
        } color="#e1e4f4">
          {" "}
          Panel de administración de ImpulsPay{" "}
        </Heading>
      </Pane>

      <Pane
        display= {isMobile ? "flex" : "grid"}
        gridTemplateColumns= {isMobile ? "1fr" : "1fr 1fr 1fr"}
        flexDirection= {isMobile ? "column" : "row"}
        gap={20}
        paddingTop={40}
        height= {isMobile ? "auto" : "90vh"}
        width="80%"
        margin="auto"
        marginBottom={isMobile ? 40 : 0}
      >
        <Pane
          elevation={3}
          display="flex"
          backgroundColor="#1d3286"
          flexDirection="column"
          gap={20}
          padding={35}
          textAlign="center"
          borderRadius={10}
          height={isMobile ? "auto" : "80vh"}
        >
          <Heading size={600} color="#e1e4f4"> Usuarios </Heading>
          <SearchInput
            placeholder="Buscar usuario"
            onChange={handleSearcher}
            width="100%"
            
          />
          <Pane display="flex" flexDirection="column" overflowY="auto" gap={20}>
            {users?.map((user, index) => {
              return (
                <Pane
                  layout
                  key={index}
                  onClick={() => {
                    handleUserSelect(user);
                  }}
                  style={{
                    backgroundColor:
                      selectedUser._id === user._id ? "#4a5f8b" : "#9caae2",
                      borderRadius: 10,
                    padding: 20,
                    color: selectedUser._id === user._id ? "#e1e4f4" : "#1d3286",
                    display: "flex",
                    flexDirection: "column",
                    gap: 10,
                    userSelect: "none",
                  }}
                >
                  <Heading size={500}
                    style={{
                      color: selectedUser._id === user._id ? "#e1e4f4" : "#1d3286",
                    }}
                  >
                    {" "}
                    {user.nombre} {user.apellido}{" "}
                  </Heading>
                  <Heading size={300}
                    style={{
                      color: selectedUser._id === user._id ? "#e1e4f4" : "#1d3286",
                    }}
                  > {user.correo} </Heading>
                  <Heading size={100}
                    style={{
                      color:  selectedUser._id === user._id ? "#e1e4f4" : "#1d3286",
                    }}
                  > {user._id} </Heading>
                </Pane>
              );
            })}
          </Pane>
        </Pane>

        <Pane
          elevation={3}
          display="flex"
          flexDirection="column"
          gap={20}
          padding={35}
          backgroundColor="#1d3286"
          textAlign="center"
          borderRadius={10}
          height= {isMobile ? "auto" : "80vh"}
        >
          <Heading size={600}
            color="#e1e4f4"
          > Tarjetas </Heading>
          <SearchInput
            placeholder="Buscar tarjeta"
            onChange={handleCardSearcher}
            width="100%"
          />
          {selectedUser &&
            selectedUser.cards &&
            selectedUser.cards.length === 0 && (
              <Heading size={300}
                color="#e1e4f4"
              > Este usuario no tiene tarjetas </Heading>
            )}
          {selectedUser &&
            selectedUser.cards &&
            selectedUser.cards.length > 0 && (
              <Heading size={300}
                color="#e1e4f4"
              >
                {" "}
                Este usuario tiene {selectedUser.cards.length} tarjetas{" "}
              </Heading>
            )}
          {!selectedUser.cards && (
            <Heading size={300}
              color="#e1e4f4"
            > Selecciona un usuario </Heading>
          )}
          {searchedCards && (
            <>
              <Heading size={500}
                color="#e1e4f4"
              >
                {" "}
                {selectedUser.nombre} {selectedUser.apellido}{" "}
              </Heading>
              <Heading size={300}
                color="#e1e4f4"
              > {selectedUser.correo} </Heading>
              <Pane
                display="flex"
                flexDirection="column"
                overflowY="auto"
                gap={20}
                padding={20}
                alignItems="center"
              >
                <AnimatePresence mode="popLayout">
                  {searchedCards?.map((card, index) => {
                    return (
                      <Card
                        usuario={selectedUser._id}
                        key={card._id}
                        last4={card.last4}
                        index={index}
                        name={card.nombre}
                        cardId={card._id}
                        updateCards={updateCards}
                        contrato={card.contrato}
                        fecha_renovacion={card.fecha_renovacion}
                      />
                    );
                  })}
                </AnimatePresence>
                  <Button
                    appearance="primary"
                    intent="success"
                    onClick={() => {
                      setShowAddCard(true);
                    }}
                  >
                    Agregar tarjeta
                  </Button>
                  {showAddCard && (
                    <AddCardDialog
                      setShowAddCard={closeAddCard}
                      selectedUser={selectedUser}
                    />
                  )}
              </Pane>
            </>
          )}
        </Pane>
        <Pane
          elevation={3}
          display="flex"
          flexDirection="column"
          gap={20}
          padding={35}
          textAlign="center"
          borderRadius={10}
          height= {isMobile ? "auto" : "80vh"}
          backgroundColor="#1d3286"
          overflowY="auto"
        >
          <Heading size={600}
            color="#e1e4f4"
          > Direcciones </Heading>
          {selectedUser.addressess?.map((address, index) => {
            return (
              <motion.div
                style={{
                  backgroundColor: "#9caae2",
                  borderRadius: 10,
                  padding: 20,
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                  userSelect: "none",
                }}
                key={index}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                transition={{ duration: 0.2 }}
                exit={{ opacity: 0 }}
              >
                <div>
                  <Heading size={500}>
                    {" "}
                    {address.nombre} {address.apellido}{" "}
                  </Heading>
                  <Heading size={500}> {address.direccion}</Heading>
                  <Heading size={300}> {address.colonia} </Heading>
                  <Heading size={300}> {address.codigo_postal} </Heading>
                  <Heading size={300}> {address.ciudad} </Heading>
                  <Heading size={300}> {address.estado} </Heading>
                  <Heading size={300}> {address.pais} </Heading>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    
                  }}
                >
                  {address.predeterminada && (
                    <Heading size={300}>
                      {" "}
                      Predeterminada{" "}
                      {
                        <img
                          src={checkImg}
                          style={{
                            width: 30,
                            height: 30,
                            display: "inline-block",
                            transform: "translateY(10px)",
                          }}
                          alt="check"
                        />
                      }{" "}
                    </Heading>
                  )}
                  <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    appearance="primary"
                    intent="danger"
                    onClick={() => {
                      axios
                        .delete(
                          process.env.REACT_APP_API_URL +

                            "/users/" +
                            selectedUser._id +
                            "/addressess/" +
                            address._id
                        )
                        .then((res) => {
                          console.log(res);
                          setSelectedUser(res.data.user);
                          const updatedUsers = users.map((user) => {
                            if (user._id === res.data.user._id) {
                              return res.data.user;
                            } else {
                              return user;
                            }
                          }
                          );
                          setUsers(updatedUsers);
                          toaster.success("Dirección eliminada");
                        });
                    }}
                  >
                    Eliminar
                  </Button>
                  {!address.predeterminada && (
                    <Button
                      appearance="secondary"
                      intent="success"
                      
                      onClick={() => {
                        handleSetDefaultAddress(address);
                        toaster.success("Dirección predeterminada");
                      }}
                    >
                      Hacer predeterminada
                    </Button>
                  )}
                </div>
                </div>
                
              </motion.div>
            );
          })}
          {selectedUser.addressess?.length === 0 && (
            <Heading size={300}
              color="#e1e4f4"
            > Este usuario no tiene direcciones </Heading>
          )}

          <Button
            appearance="primary"
            intent="success"
            onClick={() => {
              setShowAddAddress(true);
            }}
          >
            {selectedUser.addressess?.length === 0
              ? "Agregar dirección"
              : "Agregar otra dirección"}
          </Button>
          {showAddAddress && (
            <AddAddressDialog
              setShowAddAddress={setShowAddAddress}
              selectedUser={selectedUser}
            />
          )}
        </Pane>
        
      </Pane>
    </Pane>
  );
};

export default ImpulsPay;
