import React, { useEffect } from "react";

import { Heading, TextInputField, Pane, Button, toaster } from "evergreen-ui";
import axios from "axios";
import { initAxiosInterceptors } from "../../../util/authHelper";

initAxiosInterceptors();

const UserDetail = ({ user, updateUsers }) => {
  console.log("UserDetail", user);
  const [selectedUser, setSelectedUser] = React.useState({});

  useEffect(() => {
    setSelectedUser(user);
  }, [user]);

  return (
    <Pane
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <Pane display="flex" flexDirection="column" gap={20}>
        <TextInputField
          label="Nombre"
          placeholder="Nombre"
          value={selectedUser.nombre}
          onChange={(e) =>
            setSelectedUser({ ...selectedUser, nombre: e.target.value })
          }
        />
        <TextInputField
          label="Apellido"
          placeholder="Apellido"
          value={selectedUser.apellido}
          onChange={(e) =>
            setSelectedUser({
              ...selectedUser,
              apellido: e.target.value,
            })
          }
        />
        <TextInputField
          label="Correo"
          placeholder="Correo"
          value={selectedUser.correo}
          onChange={(e) =>
            setSelectedUser({ ...selectedUser, correo: e.target.value })
          }
        />
        <TextInputField
          label="Contraseña"
          placeholder="Contraseña"
          value={selectedUser.contraseña}
          onChange={(e) =>
            setSelectedUser({
              ...selectedUser,
              contraseña: e.target.value,
            })
          }
        />
        <TextInputField
          label="Rol"
          placeholder="Rol"
          value={selectedUser.role}
          onChange={(e) =>
            setSelectedUser({ ...selectedUser, role: e.target.value })
          }
        />
        <Button
          onClick={async () => {
            try {
              const res = await axios.put(
                process.env.REACT_APP_API_URL + "/users/" + selectedUser._id,
                JSON.stringify(selectedUser),
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );
              toaster.success("Usuario actualizado");

              updateUsers();
            } catch (error) {
              toaster.danger("Error al actualizar usuario");
            }
          }}
        >
          Guardar
        </Button>
      </Pane>
    </Pane>
  );
};

export default UserDetail;
