import React from "react";
import { useFormik } from "formik";

import {
  Button,
  TextInputField,
  Pane,
  Combobox,
  Alert,
  Heading,
  Paragraph,
  toaster,
} from "evergreen-ui";
import { initAxiosInterceptors } from "../../../util/authHelper";
import { useState, useEffect } from "react";
import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";
import EnviarCorreoInvitacion from "./EnviarCorreoInvitacion";

initAxiosInterceptors();

const Settings = () => {
  const [btcSelected, setBtcSelected] = useState(false);
  const [usdtSelected, setUsdtSelected] = useState(false);
  const [usdtercSelected, setUsdtercSelected] = useState(false);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "/settings/")
      .then((res) => {
        console.log(res.data);
        setBtcSelected(res.data.btc_wallet);
        setUsdtSelected(res.data.usdt_wallet);
        setUsdtercSelected(res.data.usdterc_wallet);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Pane>
      <Pane
        elevation={3}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        padding={35}
        color="white"
        alignItems="center"
        borderRadius={10}
        zIndex={1}
        className="impuls-header"
      >
        <Heading size={800} color="white">
          Configuración
        </Heading>
      </Pane>
      <Pane
        display="grid"
        gridTemplateColumns="1fr 1fr 1fr"
        gridTemplateRows="1fr"
        paddingTop={20}
        gap={20}
        height="100%"
      >
        <Pane
          backgroundColor="#1d3286"
          borderRadius={10}
          padding={15}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Heading size={600} color="white">
            Administración de billeteras
          </Heading>
          <Pane
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Heading size={500} color="white" marginTop={20} marginBottom={10}>
              BTC
            </Heading>
            <Pane display="flex" flexDirection="">
              <Pane
                backgroundColor={btcSelected === 1 ? "#9DD7E3" : "#9caae2"}
                borderRadius={10}
                padding={15}
                marginRight={20}
                cursor="pointer"
                onClick={() => {
                  axios
                    .post(process.env.REACT_APP_API_URL + "/settings/", {
                      usdt_wallet: usdtSelected,
                      btc_wallet: 1,
                      usdterc_wallet: usdtercSelected,
                    })
                    .then((res) => {
                      console.log(res.data);
                      setBtcSelected(1);
                      toaster.success("Billetera 1 seleccionada");
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                <Paragraph size={500}>Billetera 1</Paragraph>
                <Paragraph size={500}>
                  {process.env.REACT_APP_BTC_ADDRESS1}
                </Paragraph>
              </Pane>
              <Pane
                backgroundColor={btcSelected === 2 ? "#9DD7E3" : "#9caae2"}
                borderRadius={10}
                padding={15}
                cursor="pointer"
                onClick={() => {
                  axios
                    .post(process.env.REACT_APP_API_URL + "/settings/", {
                      usdt_wallet: usdtSelected,
                      btc_wallet: 2,
                      usdterc_wallet: usdtercSelected,
                    })
                    .then((res) => {
                      console.log(res.data);
                      setBtcSelected(2);
                      toaster.success("Billetera 2 seleccionada");
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                <Paragraph size={500}>BTC Bitso Luis (Jorge)</Paragraph>
                <Paragraph size={500}>
                  {process.env.REACT_APP_BTC_ADDRESS2}
                </Paragraph>
              </Pane>
            </Pane>
          </Pane>
          <Pane
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Heading size={500} color="white" marginTop={20} marginBottom={10}>
              USDT
            </Heading>
            <Pane display="flex" flexDirection="">
              <Pane
                backgroundColor={usdtSelected === 1 ? "#9DD7E3" : "#9caae2"}
                borderRadius={10}
                padding={15}
                marginRight={20}
                cursor="pointer"
                onClick={() => {
                  axios
                    .post(process.env.REACT_APP_API_URL + "/settings/", {
                      usdt_wallet: 1,
                      btc_wallet: btcSelected,
                      usdterc_wallet: usdtercSelected,
                    })
                    .then((res) => {
                      console.log(res.data);
                      setUsdtSelected(1);
                      toaster.success("Billetera 1 seleccionada");
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                <Paragraph size={500}>TRC20 Luis (Jorge)</Paragraph>
                <Paragraph size={500}>
                TUiZEaPf1gsxYQEV8Lpbegfrk36gddWHDb
                </Paragraph>
              </Pane>
              <Pane
                backgroundColor={usdtSelected === 2 ? "#9DD7E3" : "#9caae2"}
                borderRadius={10}
                padding={15}
                cursor="pointer"
                onClick={() => {
                  axios
                    .post(process.env.REACT_APP_API_URL + "/settings/", {
                      usdt_wallet: 2,
                      btc_wallet: btcSelected,
                      usdterc_wallet: usdtercSelected,
                    })
                    .then((res) => {
                      console.log(res.data);
                      setUsdtSelected(2);
                      toaster.success("Billetera 2 seleccionada");
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                <Paragraph size={500}>Billetera 2</Paragraph>
                <Paragraph size={500}>
                  {process.env.REACT_APP_USDT_ADDRESS2}
                </Paragraph>
              </Pane>
            </Pane>
          </Pane>
          <Pane
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Heading size={500} color="white" marginTop={20} marginBottom={10}>
              USDT ERC20
            </Heading>
            <Pane display="flex" flexDirection="">
              <Pane
                backgroundColor={usdtercSelected === 1 ? "#9DD7E3" : "#9caae2"}
                borderRadius={10}
                padding={15}
                marginRight={20}
                cursor="pointer"
                onClick={() => {
                  axios
                    .post(process.env.REACT_APP_API_URL + "/settings/", {
                      usdt_wallet: usdtSelected,
                      btc_wallet: btcSelected,
                      usdterc_wallet: 1,
                    })
                    .then((res) => {
                      console.log(res.data);
                      setUsdtercSelected(1);
                      toaster.success("Billetera 1 seleccionada");
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                <Paragraph size={500}>Billetera 1</Paragraph>
                <Paragraph size={500}>
                  {process.env.REACT_APP_USDTERC_ADDRESS1}
                </Paragraph>
              </Pane>
              <Pane
                backgroundColor={usdtercSelected === 2 ? "#9DD7E3" : "#9caae2"}
                borderRadius={10}
                padding={15}
                cursor="pointer"
                onClick={() => {
                  axios
                    .post(process.env.REACT_APP_API_URL + "/settings/", {
                      usdt_wallet: usdtSelected,
                      btc_wallet: btcSelected,
                      usdterc_wallet: 2,
                    })
                    .then((res) => {
                      console.log(res.data);
                      setUsdtercSelected(2);
                      toaster.success("Billetera 2 seleccionada");
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                <Paragraph size={500}>Billetera 2</Paragraph>
                <Paragraph size={500}>
                  {process.env.REACT_APP_USDTERC_ADDRESS2}
                </Paragraph>
              </Pane>
            </Pane>
          </Pane>
        </Pane>
        <Pane
          backgroundColor="#1d3286"
          borderRadius={10}
          padding={15}
          display="flex"
          flexDirection="column"
        >
        <EnviarCorreoInvitacion/>
        </Pane>
      </Pane>
    </Pane>
  );
};

export default Settings;
