import React from "react";
import {
  Pane,
  Heading,
  Dialog,
  TextInput,
} from "evergreen-ui";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";




const EditAddress = ({
  setShowEditAddress,
  selectedUser,
  selectedAddress,
  onUpdateAddress,
  showEditAddress,
}) => {
  const validationSchema = Yup.object({
    nombre: Yup.string().required("Campo requerido"),
    apellido: Yup.string().required("Campo requerido"),
    direccion: Yup.string().required("Campo requerido"),
    ciudad: Yup.string().required("Campo requerido"),
    estado: Yup.string().required("Campo requerido"),
    pais: Yup.string().required("Campo requerido"),
    codigoPostal: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      nombre: selectedAddress.nombre,
      apellido: selectedAddress.apellido,
      direccion: selectedAddress.direccion,
      ciudad: selectedAddress.ciudad,
      estado: selectedAddress.estado,
      pais: selectedAddress.pais,
      codigoPostal: selectedAddress.codigo_postal,
      predeterminada: selectedAddress.predeterminada,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      try {
        axios
          .post(
            process.env.REACT_APP_API_URL +
              "/users/" +
              selectedUser._id +
              "/addressess/" +
              selectedAddress._id +
              "/edit",
            {
              nombre: values.nombre,
              apellido: values.apellido,
              direccion: values.direccion,
              ciudad: values.ciudad,
              estado: values.estado,
              pais: values.pais,
              codigoPostal: values.codigoPostal,
              predeterminada: values.predeterminada,
            }
          )
          .then((res) => {
            console.log(res);
            onUpdateAddress(res.data.user);
            setShowEditAddress(false);
          });
      } catch (error) {
        console.log("error", error);
      }
    },
  });

  const renderFields = (name, label, type) => {
    return (
      <Pane marginBottom={24} width={400}>
        <Heading size={600} marginBottom={5}>
            {label}
        </Heading>
        <TextInput
          name={name}
          label={label}
          type={type}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[name]}
          validationMessage={formik.touched[name] && formik.errors[name]}
        />
      </Pane>
    );
  };

  return (
    <Dialog
      isShown={showEditAddress}
      title="Editar dirección"
      onCloseComplete={() => setShowEditAddress(false)}
      onConfirm={() => formik.handleSubmit()}
      confirmLabel="Editar"
      cancelLabel="Cancelar"
    >
      <Pane
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        {renderFields("nombre", "Nombre", "text")}
        {renderFields("apellido", "Apellido", "text")}
        {renderFields("direccion", "Dirección", "text")}
        {renderFields("ciudad", "Ciudad", "text")}
        {renderFields("estado", "Estado", "text")}
        {renderFields("pais", "País", "text")}
        {renderFields("codigoPostal", "Código postal", "text")}
      </Pane>
    </Dialog>
  );
};

export default EditAddress;
