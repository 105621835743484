import React, { useEffect } from "react";
import { Pane, Group } from "evergreen-ui";
import backImage from "../img/brand_white.png";
import LoginForm from "../components/loginForm";
import RegisterForm from "../components/registerForm";
import img1 from "../img/1.png";
import RECARGAS from "../img/RECARGAS.png";
import TPV from "../img/TPV.png";
import AUTO from "../img/AUTO.png";
import HOGAR from "../img/HOGAR.png";
import TBLACK from "../img/TBLACK.png";
import GMM from "../img/GMM.png";

import Carousel from "react-material-ui-carousel";


import {
  ToggleButton,
  ToggleButtonGroup,
  Button,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { FaWhatsapp } from "react-icons/fa";

import { AnimatePresence, motion } from "framer-motion";
import WhatsappSoporte from "../components/WhatsappSoporte";

function Login() {

  const isMobile = window.innerWidth < 768;
  console.log("isMobile", isMobile);

  const imgs = [
    img1,
    RECARGAS,
    TPV,
    AUTO,
    HOGAR,
    TBLACK,
    GMM,
  ]
  const [loginOrRegister, setLoginOrRegister] = useState(false);

  const handleButtonClick = (isLogin) => {
    setLoginOrRegister(isLogin);
    document.title = isLogin ? "REGISTRO" : "INICIAR SESIÓN";
  };

  const [showInstallPromotion, setShowInstallPromotion] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  // TODO: Disparar una alerta para instalar la PWA si es posible

  
  const handleBeforeInstallPrompt = (e) => {
    e.preventDefault();
    console.log("beforeinstallprompt fired");
    setDeferredPrompt(e);
    setShowInstallPromotion(true);
  }

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    }
  }
  , []);

  const showInstallDiv = showInstallPromotion && deferredPrompt;

  return (
    <div className="App">
      <div>
        <WhatsappSoporte />
      </div>
      <a href="https://www.grupoactius.com/home">
        <img
          alt="background"
          src={backImage}
          style={{
            position: "absolute",
            top: "80%",
            left: "75%",
            transform: "translate(-50%, 50%)",
            zIndex: 0,
          }}
        />
      </a>

      <header className="App-header">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#020120",
          }}
          className="border-inner"
        >
          <Carousel
            sx={{
              width: "95%",
              padding: 5
            }}
            autoPlay={true}
            animation="fade"
            interval={5000}
            duration={2000}
            stopAutoPlayOnHover={true}
            fullHeightHover={true}
            indicators={false}
          >
            {imgs.map((img, index) => (
              <img
                key={index}
                src={img}
                alt="img"
                style={{ width: "100%", height: "100%", aspectRatio: "1/1", borderRadius: 10,
                boxShadow: "1px 0px 25px 16px rgba(139,150,186,0.25)",
                WebkitBoxShadow: "1px 0px 25px 16px rgba(139,150,186,0.25)",
                MozBoxShadow: "1px 0px 25px 16px rgba(139,150,186,0.25)",
              }}
              />
            ))}
          </Carousel>

        </div>
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        >
          <AnimatePresence mode="wait">
            <motion.div
              layout
              key={loginOrRegister ? "register" : "login"}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pane
                elevation={3}
                display="flex"
                justifyContent="center"
                flexDirection="column"
                padding={30}
                background="
                linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('https://www.wallpapertip.com/wmimgs/1-15791_black-and-white-abstract-wallpaper-4k.jpg')
                "
                color="black"
                alignItems="center"
                borderRadius={10}
                zIndex={1}
              >
                <div
                  style={{
                    display: "flex",
                    gap: 10,
                    flexDirection: "column",
                    marginTop: 40,
                    marginBottom: 20,
                    fontSize: 20,
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ToggleButtonGroup
                    value={loginOrRegister}
                    exclusive
                    onChange={(event, newValue) => handleButtonClick(newValue)}
                  >
                    <ToggleButton value={false}>Iniciar sesión</ToggleButton>
                    <ToggleButton value={true}>Registrarse</ToggleButton>
                  </ToggleButtonGroup>
                </div>

                {loginOrRegister ? <RegisterForm /> : <LoginForm />}
              </Pane>
            </motion.div>
            {showInstallDiv && (
              <motion.div
                style={{
                  position: "fixed",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  backgroundColor: "#333333",
                  color: "white",
                  padding: "1rem",
                  display: "flex",
                  justifyContent: "space-between",
                  zIndex: 1000,
                }}

                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1">
                    ¿Quieres instalar esta aplicación en tu dispositivo?
                  </Typography>
                </div>
                <div>
                  <Button
                    onClick={() => {
                      deferredPrompt.prompt();
                      deferredPrompt.userChoice.then((choiceResult) => {
                        if (choiceResult.outcome === "accepted") {
                          console.log("User accepted the A2HS prompt");
                        } else {
                          console.log("User dismissed the A2HS prompt");
                        }
                        setDeferredPrompt(null);
                        setShowInstallPromotion(false);
                      });
                    }}
                  >
                    Instalar
                  </Button>
                  <Button
                    onClick={() => {
                      setDeferredPrompt(null);
                      setShowInstallPromotion(false);
                    }}
                  >
                    No, gracias
                  </Button>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </header>
    </div>
  );
}

export default Login;
