import React from "react";
import { Pane, Heading, TextInput, Dialog, toaster } from "evergreen-ui";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";

const AddAddressDialog = ({ setShowAddAddress, selectedUser, onUpdateAddress }) => {
    const validationSchema = Yup.object({
        nombre: Yup.string().required("Campo requerido"),
        apellido: Yup.string().required("Campo requerido"),
        direccion: Yup.string().required("Campo requerido"),
        ciudad: Yup.string().required("Campo requerido"),
        estado: Yup.string().required("Campo requerido"),
        pais: Yup.string().required("Campo requerido"),
        codigoPostal: Yup.string().required("Campo requerido"),
        telefono: Yup.string().required("Campo requerido"),
      });
    
      const formik = useFormik({
        initialValues: {
          nombre: "",
          apellido: "",
          direccion: "",
          ciudad: "",
          estado: "",
          pais: "",
          codigoPostal: "",
          predeterminada: false,
          telefono: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
          console.log(values);
          try {
            axios
              .post(
                process.env.REACT_APP_API_URL +
                  "/users/addaddress/" +
                  selectedUser._id,
                {
                  nombre: values.nombre,
                  apellido: values.apellido,
                  direccion: values.direccion,
                  ciudad: values.ciudad,
                  estado: values.estado,
                  pais: values.pais,
                  codigo_postal: values.codigoPostal,
                  predeterminada: values.predeterminada,
                  telefono: values.telefono,
                }
              )
              .then((res) => {
                console.log(res);
                onUpdateAddress(res.data.user);
                toaster.success("Dirección agregada");
                setShowAddAddress(false);
              });
          } catch (error) {
            toaster.danger("Error al agregar dirección");
            console.log("error", error);
          }
        },
      });
    
      const renderField = (name, label, type = "text") => (
        <div>
          <Heading size={500}>{label}</Heading>
          <TextInput
            placeholder={label}
            id={name}
            name={name}
            type={type}
            onChange={formik.handleChange}
            value={formik.values[name]}
            isInvalid={formik.errors[name] && formik.touched[name]}
            validationMessage={
              formik.errors[name] && formik.touched[name] ? formik.errors[name] : null
            }
            onBlur={formik.handleBlur}
          />
        </div>
      );
    
      return (
        <Dialog
          isShown={true}
          title="Agregar dirección"
          onCloseComplete={() => setShowAddAddress(false)}
          onConfirm={formik.handleSubmit}
          confirmLabel="Agregar"
          cancelLabel="Cancelar"
        >
          <Pane
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={20}
          >
            <Pane display="flex" flexDirection="column" gap={20}>
              {renderField("nombre", "Nombre(s)")}
              {renderField("apellido", "Apellido(s)")}
              {renderField("direccion", "Dirección")}
              {renderField("ciudad", "Ciudad")}
              {renderField("estado", "Estado")}
              {renderField("pais", "País")}
              {renderField("codigoPostal", "Código postal")}
              {renderField("telefono", "Teléfono", "number")}
              
            </Pane>
          </Pane>
        </Dialog>
      );
    };

export default AddAddressDialog;