import React from "react";
import { useFormik } from "formik";
import { Pane, Alert, Paragraph, toaster } from "evergreen-ui";
import {
  InputLabel,
  InputAdornment,
  TextField,
  Button,
  OutlinedInput,
  FormControl,
  Box,
  Alert as MuiAlert,
} from "@mui/material";
import { FaUserAlt } from "react-icons/fa";
import * as Yup from "yup";
import Axios from "axios";
import { initAxiosInterceptors, setToken } from "../util/authHelper";
import { useState } from "react";
import { motion } from "framer-motion";
import { FaEye, FaEyeSlash } from "react-icons/fa";

initAxiosInterceptors();
const validationSchema = Yup.object({
  correo: Yup.string()
    .email("Formato de correo invalido")
    .required("Requerido"),
  password: Yup.string()
    .min(4, "La contraseña debe tener al menos 6 caracteres")
    .required("Requerido"),
});

const WithEvergreen = () => {
  const [error, setError] = useState("");
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [mailForgot, setMailForgot] = useState("");

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSendForgotPassword = () => {
    setMailForgot(mailForgot.toLowerCase());
    const data = {
      email: mailForgot,
    };
    Axios.post(process.env.REACT_APP_API_URL + "/users/forgotpassword", data)
      .then((res) => {
        toaster.success("Favor de revisar tu correo");
        setShowForgotPassword(!showForgotPassword);
      })
      .catch((err) => {
        MuiAlert.danger(err);
      });
  };


  const handleForgotPassword = () => {
    setShowForgotPassword(!showForgotPassword);
  };

  const formik = useFormik({
    initialValues: {
      correo: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values.correo = values.correo.toLowerCase();
      Axios.post(
        process.env.REACT_APP_API_URL + "/auth/login",
        JSON.stringify(values),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.data.data.user.status === "VERIFIED") {
            setToken(res.data.data.token);
            //refresh page
            window.location.reload();
          } else {
            setError("Verifica tu correo");
          }
        })
        .catch((err) => {
          setError(err.response.data.error);
        });
    },
  });

  return (
    <Pane>
      {showForgotPassword ? (
        <motion.div
          key="forgot"
          initial={{ x: 100, opacity: 0 }}
          animate={{ x: 0, opacity: 1,}}
          exit={{ x: -100, opacity: 0 }}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 20,
            width: "300px",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Paragraph
            fontWeight="bold"
            color="white"
            textAlign="center"
          >Ingresa tu correo para recuperar tu contraseña</Paragraph>
          <TextField
            label="Correo electronico"
            variant="outlined"
            value={mailForgot}
            onChange={(e) => setMailForgot(e.target.value)}
            style={{ marginBottom: 20 }}
          />
          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            gap={10}
          >
            <Button
              color="secondary"
              variant="contained"
            onClick={handleForgotPassword}>Cancelar</Button>
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={handleSendForgotPassword}
            >
              Enviar
            </Button>

            
          </Box>
        </motion.div>
      ) : (
        <motion.div
          key="login"
          initial={{ x: 100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -100, opacity: 0 }}
        >
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
              width: "300px",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
            onSubmit={formik.handleSubmit}
          >
            <Pane
              style={{
                display: "flex",
                padding: 15,
                width: 120,
                height: 120,
                position: "relative",
                backgroundColor: "#1d3286",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 100,
                marginTop: -200,
              }}
            >
              <FaUserAlt size={50} color="white" />
            </Pane>
            <div
              style={{
                display: "flex",
                gap: 10,
                flexDirection: "column",
                marginTop: 50,
                textAlign: "left",
                fontSize: 20,
                width: "100%",
              }}
            >
              <FormControl>
                <InputLabel>Correo</InputLabel>
                <OutlinedInput
                  id="correo"
                  name="correo"
                  type="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.correo}
                  label="Correo"
                  helperText={formik.touched.correo && formik.errors.correo}
                />
              </FormControl>
              <FormControl>
                <InputLabel>Contraseña</InputLabel>
                <OutlinedInput
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  label="Contraseña"
                  helperText={formik.touched.password && formik.errors.password}
                  endAdornment={
                    <InputAdornment position="end">
                      {
                        showPassword ? (
                          <FaEyeSlash
                            style={{ cursor: "pointer" }}
                            onClick={handleShowPassword}
                          />
                        ) : (
                          <FaEye
                            style={{ cursor: "pointer" }}
                            onClick={handleShowPassword}
                          />
                        )
                      }
                    </InputAdornment>
                  }
                />
              </FormControl>

              <Paragraph
                style={{ cursor: "pointer" }}
                onClick={() => handleForgotPassword()}
                textAlign="center"
                fontWeight="bold"
                color="white"
              >
                Olvidaste tu contraseña?{" "}
              </Paragraph>
              {error && (
                <Alert
                  style={{ marginBottom: "20px" }}
                  intent="danger"
                  title={error}
                />
              )}
              <Button
                type="submit"
                style={{ width: "100%" }}
                color="primary"
                variant="contained"
                size="large"
              >
                Iniciar sesión
              </Button>
            </div>
          </form>
        </motion.div>
      )}
    </Pane>
  );
};

export default WithEvergreen;
