import { Button, Heading } from "evergreen-ui";
import React from "react";
import { useState, useEffect, useLayoutEffect } from "react";
import {
  Overlay,
  Pane,
  Dialog,
  toaster,
  Paragraph,
  RadioGroup,
  TextInputField,
} from "evergreen-ui";
import { useFormik } from "formik";
import * as Yup from "yup";
import imgtrc from "../../../img/imgtrc.jpeg";
import { Tooltip } from "recharts";
import axios from "axios";
import { initAxiosInterceptors } from "../../../util/authHelper";

initAxiosInterceptors();
const Aportaciones = (props) => {
  const pasos = [
    {
      id: 1,
      title: "Tipo de aportacion",
      description: "Ingresa los datos de la aportación",
      active: true,
      completed: false,
    },
    {
      id: 2,
      title: "Datos de la aportación",
      description: "Ingresa los datos de la aportación",
      active: false,
      completed: false,
    },
    {
      id: 3,
      title: "Confirmación",
      description: "Confirma los datos de la aportación",
      active: false,
      completed: false,
    },
  ];

  const [options] = useState([
    { label: "Transferencia SPEI (Pesos Mexicanos)", value: "spei" },
    { label: "Transferencia criptomonedas (USDT)", value: "criptomonedas" },
  ]);
  const [selected, setSelected] = useState("spei");
  const api = process.env.REACT_APP_API_URL;
  const [solicitarAportacion, setSolicitarAportacion] = useState(false);

  const handleSolicitarAportacion = () => {
    try {
      const data = {
        monto: monto,
        metodo: selected
      }
      axios.post(api + "/etd/solicitar/aportacion", JSON.stringify(data), {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((res)=>{
        console.log(res)
        setSolicitarAportacion(true);
      })

    } catch (error) {
      console.log(error);
    }

    toaster.notify("Se ha solicitado correctamente la aportación");
  };


  

  const [monto, setMonto] = useState(0);

  return (
    <Pane>
      <Dialog
      topOffset={50}
        isShown={props.showAportaciones}
        title="Solicitar aportación"
        onCloseComplete={() => props.toggleAportaciones()}
        preventBodyScrolling
        footer={
          <Pane
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            padding={16}
          >
            <Button
              appearance="primary"
              intent="none"
              onClick={() => props.toggleAportaciones()}
            >
              Cancelar
            </Button>
          </Pane>
        }
      >
        <Pane display="flex" flexDirection="column" gap={20}>
          <Pane
            display="flex"
            flexDirection="row"
            height="100%"
            gap={20}
            elevation={1}
            padding={20}
            background="tint2"
          >
            <Pane>
              <TextInputField
                placeholder="Monto"
                type="number"
                label="Ingresa el monto de la aportación"
                value={monto}
                onChange={(e) => setMonto(e.target.value)}
              />
            </Pane>
            <Pane>
              <Paragraph>Selecciona el metodo de aportación</Paragraph>
              <RadioGroup
                
                options={options}
                value={selected}
                onChange={(e) => setSelected(e.target.value)}
              />
            </Pane>
          </Pane>
          <Pane
            display="flex"
            flexDirection="column"
            alignItems="center"
            elevation={1}
            padding={20}
            background="tint2"
          >
            {selected === "spei" ? (
              <Pane display="flex" flexDirection="column" gap={20}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Paragraph>Banco</Paragraph>
                  <Paragraph>Banamex</Paragraph>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Paragraph>Clabe interbancaria</Paragraph>
                  <Paragraph>012180000000000000</Paragraph>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Paragraph>Monto</Paragraph>
                  <Paragraph>${monto}</Paragraph>
                </div>
              </Pane>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Pane display="flex" gap={20}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Paragraph>Red</Paragraph>
                    <Paragraph>Ethereum (ERC-20)</Paragraph>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      userSelect: "none",
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        "0x506b89C709700E24FCCebE365ae5844c009f6f94"
                      );
                      toaster.success("Direccion copiada");
                    }}
                  >
                    <Paragraph>Direccion</Paragraph>
                    <Paragraph>
                      0x506b89C709700E24FCCebE365ae5844c009f6f94
                    </Paragraph>
                  </div>
                </Pane>
                <img src={imgtrc} alt="trc" width={200} />
                <Paragraph>Monto</Paragraph>
                <Paragraph>${monto}</Paragraph>
              </div>
            )}
            <Button
              appearance="primary"
              intent="success"
              onClick={() => handleSolicitarAportacion()}
              marginTop={20}
            >
              Solicitar aportación
            </Button>
          </Pane>
        </Pane>
      </Dialog>
    </Pane>
  );
};

export default Aportaciones;
