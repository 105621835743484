import React, { useEffect, useState } from "react";
import { Pane, Heading, Paragraph } from "evergreen-ui";
import { motion } from "framer-motion";
import TerminalesSelector from "../tpv/TerminalesSelector";
import axios from "axios";
import CajasDetalles from "./CajasDetalles";

const TPVMain = ({ user }) => {
  const [selectedSucursal, setSelectedSucursal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tpvEntity, setTpvEntity] = useState({});

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tpv/${user._id}`
      );
      setTpvEntity(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user]);

  return (
    <motion.div
    key={"tpv"}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{ backgroundColor: "#112240" }}
    >
      <Pane
        display="flex"
        justifyContent="center"
        flexDirection="column"
        padding={40}
        color="white"
        zIndex={1}
        className="impulstpv-header"
      >
        <Heading size={800} color="white">
          TPV Impulspay
        </Heading>
      </Pane>
      <Pane padding={30} display="flex" justifyContent="center" flexDirection="column">
        <Pane>
          <Heading size={600} color="white">
            Bienvenido a la sección de TPV Impulspay. Aquí podrás revisar tus terminales de pago y solicitar nuevas.
          </Heading>
        </Pane>
        <Pane
          display="flex"
          justifyContent="center"
          flexDirection="column"
          padding={20}
          marginTop={20}
          backgroundColor="rgba(255, 255, 255, 0.1)"
          borderRadius={20}
        >
          <Heading size={900} color="white" marginBottom={10}>
            Sucursales
          </Heading>
          <Paragraph color="white" size={500}>
            Tus sucursales son los lugares donde tienes tus terminales de pago.
            <br />
            Selecciona una sucursal para ver sus terminales.
          </Paragraph>
          {loading ? (
            <Paragraph color="white" size={500}>
              Cargando...
            </Paragraph>
          ) : (
            <TerminalesSelector
              tpvEntity={tpvEntity}
              selectedSucursal={selectedSucursal}
              setSelectedSucursal={setSelectedSucursal}
            />
          )}
          {selectedSucursal && (
            <Pane>
              <Heading size={900} color="white" marginBottom={10}>
                Terminales de {selectedSucursal.nombre}
              </Heading>
              <Paragraph color="white" size={500}>
                Aquí podrás ver las terminales de pago de esta sucursal
              </Paragraph>
              <CajasDetalles
                cajas={selectedSucursal.cajas}
                sucursal={selectedSucursal}
                tpvEntity={tpvEntity}
                setTpvEntity={setTpvEntity}
                setSelectedSucursal={setSelectedSucursal}
              />
            </Pane>
          )}
        </Pane>
      </Pane>
    </motion.div>
  );
};


export default TPVMain;