import React, { useEffect, useState, useCallback } from "react";
import {
  Pane,
  Heading,
  Paragraph,
  TextInput,
  Dialog,
  toaster,
} from "evergreen-ui";
import { motion, AnimatePresence } from "framer-motion";
import axios from "axios";
import { initAxiosInterceptors } from "../../../util/authHelper";
import SucursalDetail from "./sucursarDetail";
import { FaPlusCircle, FaTrash } from "react-icons/fa";

initAxiosInterceptors();

const TPVMain = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [userSelected, setUserSelected] = useState(null);
  const [selectedTpv, setSelectedTpv] = useState(null);
  const [tpvs, setTpvs] = useState([]);
  const [selectedSucursal, setSelectedSucursal] = useState(null);
  const [sucursalSearch, setSucursalSearch] = useState("");
  const [isDialogShown, setIsDialogShown] = useState(false);
  const [newSucursalCorreo, setNewSucursalCorreo] = useState("");
  const [newSucursalAlias, setNewSucursalAlias] = useState("");
  const [isDeleteDialogShown, setIsDeleteDialogShown] = useState(false);
  const [sucursalToDelete, setSucursalToDelete] = useState(null);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleSucursalSearch = (e) => {
    setSucursalSearch(e.target.value);
  };

  const filteredUsers = usuarios.filter((usuario) =>
    usuario.nombre.toLowerCase().includes(search.toLowerCase())
  );

  const filteredSucursales =
    selectedTpv?.sucursales?.filter((sucursal) =>
      sucursal.nombre.toLowerCase().includes(sucursalSearch.toLowerCase())
    ) || [];

  const getUsers = useCallback(async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/users/`);
      const usuariosNoAdmin = res.data.filter(
        (usuario) => usuario.role !== "admin"
      );
      setUsuarios(usuariosNoAdmin);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchTpvs = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/tpv`);
      setTpvs(response.data);
    } catch (error) {
      console.error("Error fetching tpvs:", error);
    }
  }, []);

  useEffect(() => {
    getUsers();
    fetchTpvs();
  }, [getUsers, fetchTpvs]);

  useEffect(() => {
    if (userSelected) {
      const userTpv = tpvs.find((tpv) => tpv.usuario === userSelected._id);
      if (!userTpv) {
        // Si no existe un TPV para este usuario, lo creamos
        handleCreateNewTpv(userSelected);
      } else {
        setSelectedTpv(userTpv);
      }
    }
  }, [userSelected, tpvs]);

  const handleCreateSucursal = async () => {
    const newSucursal = {
      sucursalId: `sucursal-${Date.now()}`,
      nombre: newSucursalAlias,
      correo: newSucursalCorreo,
      cajas: [],
    };

    selectedTpv.sucursales.push(newSucursal);
    setSelectedTpv({ ...selectedTpv });

    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/tpv/${selectedTpv._id}`,
        selectedTpv
      );
      toaster.success("Sucursal creada con éxito");
      setIsDialogShown(false);
      setNewSucursalCorreo("");
      setNewSucursalAlias("");
    } catch (error) {
      console.error("Error creating new sucursal:", error);
    }
  };

  const handleCreateNewTpv = async (user) => {
    const newTpv = {
      usuario: user._id,
      sucursales: [],
      nombre: user.nombre,
      correo: user.correo,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/tpv`,
        newTpv
      );
      console.log("TPV creado:", response.data);
      setTpvs([...tpvs, response.data]);
      setSelectedTpv(response.data);
      toaster.success("TPV creado con éxito");
    } catch (error) {
      console.error("Error creating new tpv:", error);
    }
  };

  const createSucursal = async () => {
    const newSucursal = {
      sucursalId: `sucursal-${Date.now()}`,
      nombre: newSucursalAlias,
      correo: newSucursalCorreo,
      cajas: [],
    };
    console.log(selectedTpv);
    selectedTpv.sucursales.push(newSucursal);
    setSelectedTpv({ ...selectedTpv });

    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/tpv/${selectedTpv._id}`,
        selectedTpv
      );
      toaster.success("Sucursal creada con éxito");
      setIsDialogShown(false);
      setNewSucursalCorreo("");
      setNewSucursalAlias("");
    } catch (error) {
      console.error("Error creating new sucursal:", error);
    }
  };

  const handleDeleteSucursal = async () => {
    const updatedSucursales = selectedTpv.sucursales.filter(
      (sucursal) => sucursal.sucursalId !== sucursalToDelete.sucursalId
    );
    setSelectedTpv({ ...selectedTpv, sucursales: updatedSucursales });

    try {
      await axios
        .put(`${process.env.REACT_APP_API_URL}/tpv/${selectedTpv._id}`, {
          ...selectedTpv,
          sucursales: updatedSucursales,
        })
        .then(() => {
          console.log("Sucursal eliminada");
          toaster.success("Sucursal eliminada con éxito");
        });

      if (selectedSucursal?.sucursalId === sucursalToDelete.sucursalId) {
        setSelectedSucursal(null);
      }
      setIsDeleteDialogShown(false);
      setSucursalToDelete(null);
    } catch (error) {
      console.error("Error deleting sucursal:", error);
    }
  };

  return (
    <motion.div style={{ backgroundColor: "#0A192F", minHeight: "100vh" }}>
      <Pane
        display="flex"
        justifyContent="center"
        flexDirection="column"
        padding={40}
        color="white"
        zIndex={1}
        className="impulstpv-header"
        borderRadius={10}
      >
        <Heading size={800} color="#64FFDA">
          TPV Impulspay
        </Heading>
      </Pane>
      <Pane
        padding={30}
        display="flex"
        justifyContent="center"
        flexDirection="column"
      >
        <Heading
          size={600}
          color="white"
          display="flex"
          justifyContent="center"
          flexDirection="column"
          marginBottom={20}
        >
          Bienvenido administrador, aquí podrás revisar las terminales de cada
          cliente
        </Heading>
        <Paragraph
          color="white"
          size={500}
          display="flex"
          justifyContent="center"
          flexDirection="column"
          marginBottom={20}
        >
          Selecciona un usuario para ver sus terminales de pago
        </Paragraph>
        <TextInput
          placeholder="Buscar usuario..."
          onChange={handleSearch}
          width="60%"
          marginBottom={20}
          marginTop={20}
          marginLeft="auto"
          marginRight="auto"
          fontSize={18}
          backgroundColor="#112240"
          color="white"
          borderRadius={5}
          border="none"
          padding={10}
        />
        {loading ? (
          <Paragraph color="white" size={500}>
            Cargando...
          </Paragraph>
        ) : (
          <Pane
            display="flex"
            flexDirection="row"
            gap={20}
            overflowX="scroll"
            padding={20}
            style={{ scrollbarColor: "#fff #092230", scrollbarWidth: "thin" }}
          >
            <AnimatePresence mode="popLayout" presenceAffectsLayout>
              {filteredUsers.map((usuario) => (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  style={{
                    backgroundColor:
                      userSelected?._id === usuario._id ? "#64FFDA" : "#112240",
                    padding: 20,
                    borderRadius: 10,
                    userSelect: "none",
                    cursor: "pointer",
                    minWidth: "200px",
                  }}
                  whileHover={{
                    scale: 1.05,
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
                  }}
                  whileTap={{ scale: 0.95 }}
                  key={usuario._id}
                  onClick={() => {
                    setUserSelected(usuario);
                    setSelectedSucursal(null);
                  }}
                >
                  <Heading
                    size={700}
                    color={
                      userSelected?._id === usuario._id ? "#0A192F" : "white"
                    }
                    marginBottom={10}
                  >
                    {usuario.nombre}
                  </Heading>
                  <Heading
                    size={500}
                    color={
                      userSelected?._id === usuario._id ? "#0A192F" : "white"
                    }
                    marginBottom={10}
                  >
                    {usuario.apellido}
                  </Heading>
                  <Paragraph
                    fontSize={12}
                    fontWeight={400}
                    color={
                      userSelected?._id === usuario._id ? "#0A192F" : "white"
                    }
                    marginBottom={10}
                  >
                    {usuario._id}
                  </Paragraph>
                  <Heading
                    size={500}
                    color={
                      userSelected?._id === usuario._id ? "#0A192F" : "white"
                    }
                    marginBottom={10}
                  >
                    {usuario.correo}
                  </Heading>
                </motion.div>
              ))}
            </AnimatePresence>
          </Pane>
        )}
        {userSelected && (
          <Pane
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            marginTop={20}
          >
            <Paragraph color="#64FFDA" size={900} textAlign="center">
              <strong>
                Sucursales de {userSelected.nombre} {userSelected.apellido}
              </strong>
            </Paragraph>
            <TextInput
              placeholder="Buscar sucursal..."
              onChange={handleSucursalSearch}
              width="60%"
              marginBottom={20}
              marginTop={20}
              marginLeft="auto"
              marginRight="auto"
              fontSize={18}
              backgroundColor="#112240"
              color="white"
              borderRadius={5}
              border="none"
              padding={10}
            />
            <Pane
              display="flex"
              flexDirection="row"
              marginTop={20}
              padding={20}
              gap={20}
              borderRadius={20}
            >
              {filteredSucursales.map((sucursal) => (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  style={{
                    backgroundColor:
                      selectedSucursal?.sucursalId === sucursal.sucursalId
                        ? "#64FFDA"
                        : "#112240",
                    padding: 20,
                    borderRadius: 10,
                    userSelect: "none",
                    cursor: "pointer",
                    minWidth: "200px",
                  }}
                  whileHover={{
                    scale: 1.05,
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
                  }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => {
                    console.log("sucursal", sucursal);
                    setSelectedSucursal(sucursal);
                  }}
                  key={sucursal.sucursalId}
                >
                  <Heading
                    size={700}
                    color={
                      selectedSucursal?.sucursalId === sucursal.sucursalId
                        ? "#0A192F"
                        : "white"
                    }
                    marginBottom={10}
                  >
                    {sucursal.nombre}
                  </Heading>
                  <Heading
                    size={500}
                    color={
                      selectedSucursal?.sucursalId === sucursal.sucursalId
                        ? "#0A192F"
                        : "white"
                    }
                    marginBottom={10}
                  >
                    {sucursal.correo}
                  </Heading>
                  <motion.div
                    style={{
                      display: "flex",
                      alignItems: "right",
                      justifyContent: "flex-end",
                      marginTop: 10,
                    }}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSucursalToDelete(sucursal);
                      setIsDeleteDialogShown(true);
                    }}
                  >
                    <FaTrash size={20} color="#FF6347" />
                  </motion.div>
                </motion.div>
              ))}
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                style={{
                  backgroundColor: "#112240",
                  padding: 20,
                  borderRadius: 10,
                  userSelect: "none",
                  cursor: "pointer",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 20,
                  display: "flex",
                }}
                whileHover={{
                  scale: 1.05,
                  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
                }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setIsDialogShown(true)}
              >
                <Heading size={700} color="white">
                  Nueva sucursal
                </Heading>
                <FaPlusCircle size={30} color="white" />
              </motion.div>
            </Pane>
          </Pane>
        )}
        {selectedSucursal && (
          <SucursalDetail
            sucursal={selectedSucursal}
            selectedTpv={selectedTpv}
          />
        )}
      </Pane>
      <Dialog
        isShown={isDialogShown}
        title="Nueva Sucursal"
        onCloseComplete={() => setIsDialogShown(false)}
        confirmLabel="Crear"
        onConfirm={handleCreateSucursal}
      >
        <TextInput
          placeholder="Correo de la sucursal"
          value={newSucursalCorreo}
          onChange={(e) => setNewSucursalCorreo(e.target.value)}
          marginBottom={20}
        />
        <TextInput
          placeholder="Alias de la sucursal"
          value={newSucursalAlias}
          onChange={(e) => setNewSucursalAlias(e.target.value)}
        />
      </Dialog>
      <Dialog
        isShown={isDeleteDialogShown}
        title="Confirmar eliminación"
        intent="danger"
        onCloseComplete={() => setIsDeleteDialogShown(false)}
        confirmLabel="Eliminar"
        onConfirm={handleDeleteSucursal}
      >
        <Paragraph>
          ¿Estás seguro de que quieres eliminar la sucursal{" "}
          <strong>{sucursalToDelete?.nombre}</strong>?<br />
          Esta acción no se puede deshacer.
        </Paragraph>
      </Dialog>
    </motion.div>
  );
};

export default TPVMain;
