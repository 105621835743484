import React, { useEffect, useState } from "react";
import axios from "axios";
import { Pane, Heading, Paragraph, Button } from "evergreen-ui";
import { motion } from "framer-motion";
import { FaFileDownload } from "react-icons/fa";

const SegurosDetail = ({ user }) => {
  const [seguros, setSeguros] = useState([]);
  const [loading, setLoading] = useState(false);

  const groupSegurosByType = () => {
    const segurosByType = {};
    seguros.forEach((seguro) => {
      if (!segurosByType[seguro.tipo]) {
        segurosByType[seguro.tipo] = [];
      }
      segurosByType[seguro.tipo].push(seguro);
    });
    return segurosByType;
  };

  const linkPoliza = (seguro) => {
    return process.env.REACT_APP_API_URL + "/storage/poliza/" + seguro.poliza;
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(process.env.REACT_APP_API_URL + "/seguro/usuario/" + user._id)
      .then((res) => {
        setSeguros(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  }, [user._id]);

  return (
    <Pane>
      {loading ? (
        <Pane
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
          padding={20}
        >
          <Heading size={600}>Cargando seguros...</Heading>
        </Pane>
      ) : (
        <Pane
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
          padding={20}
        >
          {seguros.length > 0 ? (
            Object.entries(groupSegurosByType()).map(([tipo, seguros]) => (
              <Pane
                display="flex"
                flexDirection="column"
                width="100%"
                padding={20}
                margin={10}
                borderRadius={5}
                gap={10}
              >
                <Heading
                  color="#7e57c2"
                  textAlign="left"
                  fontWeight="bold"
                  size={800}
                  marginBottom={10}
                  fontSize={25}
                >
                  {tipo}
                </Heading>
                <Pane
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  gap={10}
                  flexWrap="wrap"
                >
                  {seguros.map((seguro) => (
                    <SeguroCard seguro={seguro} linkPoliza={linkPoliza} />
                  ))}
                </Pane>
              </Pane>
            ))
          ) : (
            <Pane
              display="flex"
              flexDirection="column"
              alignItems="center"
              width="100%"
              padding={20}
            >
              <Heading size={600}>No tienes seguros contratados</Heading>
            </Pane>
          )}
        </Pane>
      )}
    </Pane>
  );
};

const SeguroCard = ({ seguro, linkPoliza, deleteSeguro }) => {
  const fechaProximaAVencer = new Date(seguro.siguiente_pago);
  const diasParaProximoPago = Math.floor(
    (fechaProximaAVencer - new Date()) / (1000 * 60 * 60 * 24)
  );


  return (
    <Pane
      key={seguro._id}
      width="300px"
      padding={10}
      borderRadius={5}
      display="flex"
      flexDirection="column"
      background="
    linear-gradient(180deg, #f6f6f6 60%, #7e57c2 100%)
    "
      boxShadow="0px 0px 5px 0px rgba(0,0,0,0.75)"
      minHeight="200px"
      gap={10}
      justifyContent="space-around"
    >
      <Pane display="flex" flexDirection="column" gap={5}>
        <Paragraph size={400} color="#000">
          Aseguradora: {seguro.aseguradora}
        </Paragraph>
        <Paragraph size={400} color="#000">
          Producto: {seguro.producto}
        </Paragraph>
        <Paragraph size={400} color="#000">
          Inicio de vigencia:{" "}
          {new Date(seguro.fecha_inicio).toLocaleDateString()}
        </Paragraph>
        <Paragraph
          size={400}
          color={fechaProximaAVencer < new Date() ? "red" : "#000"}
        >
          Proximo pago: {new Date(seguro.siguiente_pago).toLocaleDateString()}
        </Paragraph>
        <Paragraph
          size={400}
          color={diasParaProximoPago < 7 ? "red" : "#000"}
        >
            {diasParaProximoPago < 0
                ? "Pago vencido"
                : `Días para el próximo pago: ${diasParaProximoPago}`}
        </Paragraph>
        
      </Pane>
      <Pane
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        gap={10}
      >
        <Button
          iconBefore={FaFileDownload}
          onClick={() => {
            window.open(linkPoliza(seguro), "_blank");
          }}
          color="green"
        >
          Descargar Póliza
        </Button>
      </Pane>
    </Pane>
  );
};

export default SegurosDetail;
