import React from "react";
import { useState, useEffect, useLayoutEffect } from "react";
import {
  Overlay,
  Pane,
  Dialog,
  toaster,
  Paragraph,
  RadioGroup,
  TextInputField,
  Button,
} from "evergreen-ui";
import axios from "axios";

import { useFormik } from "formik";
import * as Yup from "yup";

const Retiros = (props) => {
  const api = process.env.REACT_APP_API_URL;

  const options = [
    {
      label: "Transferencia SPEI (Pesos Mexicanos)",
      value: "spei",
      moneda: "MXN",
    },
    { label: "Tether (USDT)", value: "criptomonedas", moneda: "USD" },
    { label: "Bitcoin (BTC)", value: "btc", moneda: "USD" },
    { label: "Tarjeta ImpulsPay", value: "impulspay", moneda: "MXN" },
  ];

  const [selected, setSelected] = useState("spei");
  const [monto, setMonto] = useState(0);
  const [solicitarRetiro, setSolicitarRetiro] = useState(false);

  const handleSolicitarRetiro = () => {
    try {
      const data = {
        monto: monto,
        metodo: selected,
        clabe: props.clabe,
        numeroTarjeta: props.numeroTarjeta,
        direccionBilletera: props.direccionBilletera,
      };
      console.log(data);
      axios
        .post(api + "/etd/solicitar/retiro", JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response.status === 200) {
            toaster.success("Retiro solicitado correctamente");
            setSolicitarRetiro(false);
          }
        });
    } catch (error) {
      console.log(error);
      toaster.danger("Error al solicitar retiro");
    }
  };

  return (
    <Pane>
      <Dialog
        topOffset={50}
        isShown={props.showRetiros}
        title="Solicitar retiro"
        onCloseComplete={() => props.toggleRetiros()}
        confirmLabel="Solicitar retiro"
        footer={
          <Pane
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            padding={16}
          >
            <Button
              appearance="primary"
              intent="none"
              onClick={() => props.toggleRetiros()}
            >
              Cancelar
            </Button>
            <Button
              appearance="primary"
              intent="success"
              onClick={() => handleSolicitarRetiro()}
              marginLeft={16}
            >
              Solicitar retiro
            </Button>
          </Pane>
        }
      >
        <Pane
          display="flex"
          flexDirection="column"
          padding={16}
          elevation={1}
          background="tint2"
        >
          <Paragraph>Ingresa el monto que deseas retirar</Paragraph>
          <TextInputField
            label="Monto"
            placeholder="Monto"
            value={monto}
            onChange={(e) => setMonto(e.target.value)}
          />
          <RadioGroup
            label="Método de retiro"
            value={selected}
            options={options}
            onChange={(e) => setSelected(e.target.value)}
          />
        </Pane>

        <Pane
          display="flex"
          flexDirection="column"
          padding={16}
          elevation={1}
          background="tint2"
          marginTop={16}
        >
          {selected === "spei" ? (
            <Pane>
              <Paragraph>
                El monto deberá ser capturado en pesos mexicanos (MXN)
              </Paragraph>
              <TextInputField
                label="CLABE"
                placeholder="CLABE"
                value={props.clabe}
                onChange={(e) => props.setClabe(e.target.value)}
              />
            </Pane>
          ) : null}
          {selected === "impulspay" ? (
            <Pane>
              <Paragraph>
                El monto deberá ser capturado en pesos mexicanos (MXN)
              </Paragraph>
              <TextInputField
                label="Número de tarjeta"
                placeholder="Número de tarjeta"
                value={props.numeroTarjeta}
                onChange={(e) => props.setNumeroTarjeta(e.target.value)}
              />
            </Pane>
          ) : null}
          {selected === "btc" ? (
            <Pane>
              <Paragraph>
                El monto deberá ser capturado en dólares (USD)
              </Paragraph>
              <TextInputField
                label="Dirección de billetera"
                placeholder="Dirección de billetera"
                value={props.direccionBilletera}
                onChange={(e) => props.setDireccionBilletera(e.target.value)}
              />
            </Pane>
          ) : null}
          {selected === "criptomonedas" ? (
            <Pane>
              <Paragraph>
                El monto deberá ser capturado en dólares (USD)
              </Paragraph>
              <TextInputField
                label="Dirección de billetera"
                placeholder="Dirección de billetera"
                value={props.direccionBilletera}
                onChange={(e) => props.setDireccionBilletera(e.target.value)}
              />
            </Pane>
          ) : null}
        </Pane>
      </Dialog>
    </Pane>
  );
};

export default Retiros;
