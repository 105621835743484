import React from "react";
import { Button, TextInput, Pane } from "evergreen-ui";
import { removeToken } from "../../util/authHelper";
import { ProSidebarProvider } from "react-pro-sidebar";
import { useState, useEffect } from "react";
import axios from "axios";
import {
  initAxiosInterceptors,
  updateOnCloseSession,
} from "../../util/authHelper";

import Etd from "../../components/etd/etd";
import { Menu, Popover, Position, Heading } from "evergreen-ui";
import { AiOutlineUser, AiOutlineBarChart, AiFillLock } from "react-icons/ai";
import AdminSideMenu from "../../components/admin/adminSideMenu";
import AdminETD from "../../components/admin/etd/etd";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AdminUsers from "../../components/admin/users";
import RendimientosGlobales from "../../components/admin/etd/rendimientosGlobales/rendimientos";
import Login from "../login";
import ImpulsPay from "../../components/admin/impulspay/ImpulsPay";
import { AnimatePresence } from "framer-motion";
import Movimientos from "../../components/admin/impulspay/Movimientos";
import SolicitudTable from "../../components/admin/impulspay/Solicitudes";
import Settings from "../../components/admin/impulspay/Settings";
import SolicitudesNuevasTarjetas from "../../components/admin/impulspay/NuevasTarjetas";
import ProteccSeguros from "../../components/admin/protecc/proteccSeguros";
import TPVMain from "../../components/admin/tpv/tpvMain";
import PagosTPV from "../../components/admin/tpv/Pagos";

initAxiosInterceptors();
updateOnCloseSession(() => (window.location.href = "/"));

const AdminMainPage = (props) => {
  const [margin, setMargin] = useState(
    window.innerWidth > 768 ? "250px" : "0px"
  );
  const [user, setUser] = useState({});

  async function fetchData() {
    console.log("VERIFICANDO TOKEN");
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/users/" + props.auth._id
      );
      console.log(res.data);
      setUser(res.data);
    } catch (error) {
      console.log("error", error);
    }
  }

  const handleCerrrarSesion = () => {
    removeToken();
    window.location.href = "/";
  };

  useEffect(() => {
    const handleResize = () => {
      setMargin(window.innerWidth > 768 ? "250px" : "0px");
    };
    window.addEventListener("resize", handleResize);
    fetchData();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <Pane display="flex"
        margin={0}
        padding={0}
      >
        <AdminSideMenu options={{ etd: true, qmc: true }} />

        <main
          style={{
            width: "100%",
            backgroundColor: "#030409",
            marginLeft: margin,
            height: "100%",
            marginTop: "0px",
            padding: "0px",
            
          }}
        >
          <Pane marginLeft="" flexDirection="column">
            <div
              style={{
                top: "20px",
                right: "20px",
                position: "fixed",
              }}
            >
              <Popover
                position={Position.BOTTOM_RIGHT}
                content={
                  <Menu>
                    <Menu.Group>
                      <Menu.Item icon={<AiOutlineUser />}> Mi perfil</Menu.Item>
                      <Menu.Item
                        icon={<AiFillLock color="red" />}
                        onSelect={handleCerrrarSesion}
                        intent="danger"
                      >
                        Cerrar sesión
                      </Menu.Item>
                    </Menu.Group>
                  </Menu>
                }
              >
                <Button
                  icon
                  style={{
                    borderRadius: "50%",
                    width: "50px",
                    height: "50px",
                  }}
                >
                  <AiOutlineUser size={50} />
                </Button>
              </Popover>
            </div>
            <AnimatePresence mode="wait">
              <Routes>
                <Route path="/" element={<AdminUsers />} />
                <Route path="/etd" element={<AdminETD />} />
                <Route
                  path="/rendimientosG"
                  element={<RendimientosGlobales />}
                />
                <Route path="/impulspay" element={<ImpulsPay />} />
                <Route
                  path="/impulspay/movimientos"
                  element={<Movimientos />}
                />
                <Route
                  path="/impulspay/solicitudes"
                  element={<SolicitudTable />}
                />
                <Route path="/impulspay/tarjetasnuevas" element={<SolicitudesNuevasTarjetas />} />
                <Route path="/impulspay/settings" element={<Settings />} />
                <Route path="/protecc" element={<ProteccSeguros/>} />
                <Route path="/impulspay/tpv" element={<TPVMain />} />
                <Route path="/impulspay/tpv/pagos" element={<PagosTPV />} />
              </Routes>
            </AnimatePresence>
          </Pane>
        </main>
      </Pane>
    </div>
  );
};

export default AdminMainPage;
