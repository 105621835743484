import React from "react";
import { useFormik } from "formik";
import { Pane, Alert, toaster,  } from "evergreen-ui";
import {
  InputLabel,
  InputAdornment,
  TextField,
  Button,
  FormControl,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import Axios from "axios";
import { motion } from "framer-motion";
import { useState } from "react";
import { FaEye } from "react-icons/fa";
import { AiFillEdit } from "react-icons/ai";

const validationSchema = Yup.object().shape({
  nombre: Yup.string().required("Requerido"),
  apellido: Yup.string().required("Requerido"),
  patrocinador: Yup.string(),
  correo: Yup.string()
    .email("Formato de correo invalido")
    .required("Requerido"),
  correo2: Yup.string()
    .oneOf([Yup.ref("correo"), null], "Los correos no coinciden")
    .required("Requerido"),
  telefono: Yup.string().required("Requerido"),
  password: Yup.string().required("Requerido"),
  password2: Yup.string()
    .oneOf([Yup.ref("password"), null], "Las contraseñas no coinciden")
    .required("Requerido"),
});

const isMobile = window.innerWidth <= 500;


const WithEvergreen = () => {
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showVerificarMensaje, setShowVerificarMensaje] = useState(false);

  const handleVerificarMensaje = () => {
    setShowVerificarMensaje(!showVerificarMensaje);
    //clear form
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      nombre: "",
      apellido: "",
      patrocinador: "",
      correo: "",
      telefono: "",
      role: "usuario",
      estado: "",
      pais: "",
      password: "",
      password2: "",
      terminos: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values.role = "usuario";
      values.correo = values.correo.toLowerCase();
      try {
        console.log(values);
        Axios.post(
          process.env.REACT_APP_API_URL + "/auth/register",
          JSON.stringify(values),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => {
            //refresh page
            toaster.notify("Favor de verificar su correo electrónico");
            handleVerificarMensaje();
          })
          .catch((err) => {
            setError(err.response.data.error);

            console.log(err);
          });
      } catch (error) {
        console.log("error" + error);
      }
    },
  });

  const handleCheckboxChange = (e) => {
    formik.setFieldValue("terminos", e.target.checked);
  };

  return (
    <motion.div
      key="register"
      initial={{ x: -100, opacity: 0 }}
      animate={{ x: 0, opacity: 1}}
      exit={{ x: 100, opacity: 0 }}
    >
      {showVerificarMensaje ? (
        <Box>
          <Alert
            style={{ marginBottom: "20px" }}
            intent="warning"
            title="Favor de verificar su correo electrónico"
          />
          <Button
            appearance="primary"
            intent="success"
            onClick={handleVerificarMensaje}
          >
            Aceptar
          </Button>
        </Box>
      ) : (
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%", // Cambiado a ancho completo
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            gap: -20,
          }}
          onSubmit={formik.handleSubmit}
        >
          <Pane
            style={{
              display: "flex",
              padding: 15,
              width: 120,
              height: 120,
              position: "relative",
              backgroundColor: "#1d3286",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 100,
              marginTop: -200,
            }}
          >
            <AiFillEdit style={{ fontSize: 60, color: "white" }} />
          </Pane>

          <Box
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: 20,
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 80,
            }}
          >
            <TextField
              label="Nombre"
              variant="outlined"
              name="nombre"
              value={formik.values.nombre}
              onChange={formik.handleChange}
              error={formik.touched.nombre && Boolean(formik.errors.nombre)}
              helperText={formik.touched.nombre && formik.errors.nombre}
            />
            <TextField
              label="Apellido"
              variant="outlined"
              name="apellido"
              value={formik.values.apellido}
              onChange={formik.handleChange}
              error={formik.touched.apellido && Boolean(formik.errors.apellido)}
              helperText={formik.touched.apellido && formik.errors.apellido}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: 20,
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <TextField
              label="¿Quién te recomendó?"
              variant="outlined"
              name="patrocinador"
              value={formik.values.patrocinador}
              onChange={formik.handleChange}
              error={
                formik.touched.patrocinador &&
                Boolean(formik.errors.patrocinador)
              }
              helperText={
                formik.touched.patrocinador && formik.errors.patrocinador
              }
            />
            <TextField
              label="Correo"
              variant="outlined"
              name="correo"
              value={formik.values.correo}
              onChange={formik.handleChange}
              error={formik.touched.correo && Boolean(formik.errors.correo)}
              helperText={formik.touched.correo && formik.errors.correo}
            />
            <TextField
              label="Confirmar Correo"
              variant="outlined"
              name="correo2"
              value={formik.values.correo2}
              onChange={formik.handleChange}
              error={formik.touched.correo2 && Boolean(formik.errors.correo2)}
              helperText={formik.touched.correo2 && formik.errors.correo2}
            />
          </Box>

          <Box
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: 20,
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <TextField
              label="Telefono"
              variant="outlined"
              name="telefono"
              value={formik.values.telefono}
              onChange={formik.handleChange}
              error={formik.touched.telefono && Boolean(formik.errors.telefono)}
              helperText={formik.touched.telefono && formik.errors.telefono}
            />
            <FormControl>
              <InputLabel>País</InputLabel>
              <TextField
                id="pais"
                name="pais"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.pais}
                label="País"
                helperText={formik.touched.pais && formik.errors.pais}
              />
            </FormControl>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: 20,
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <FormControl>
              <TextField
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                onChange={formik.handleChange}
                value={formik.values.password}
                label="Contraseña"
                helperText={
                  (formik.touched.password && formik.errors.password) ||
                  (formik.touched.password2 && formik.errors.password2)
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <FaEye
                        onClick={() => setShowPassword(!showPassword)}
                        style={{ cursor: "pointer" }}
                      />
                    </InputAdornment>
                  ),
                  
                }}
              />
            </FormControl>
            <FormControl>
              <TextField
                id="password2"
                name="password2"
                type="password"
                onChange={formik.handleChange}
                value={formik.values.password2}
                label="Confirmar Contraseña"
                helperText={
                  (formik.touched.password2 && formik.errors.password2) ||
                  (formik.touched.password && formik.errors.password2)
                }
              />
            </FormControl>
          </Box>
          <FormControlLabel
            style={{ marginTop: 20 }}
            control={
              <Checkbox
                checked={formik.values.terminos}
                onChange={handleCheckboxChange}
                name="terminos"
              />
            }
            label={
              <Typography
                variant="body1"
                style={{ fontSize: 15, color: "white", fontWeight: "bold" }}
              >
                Acepto los{" "}
                <a href="/terminos" style={{ color: "white" }} target="_blank">
                  términos y condiciones
                </a>
              </Typography>
            }
          />

          {error && (
            <Alert
              style={{ marginBottom: "20px" }}
              intent="danger"
              title={error}
            />
          )}
          <Button appearance="primary" intent="success" type="submit">
            Registrar
          </Button>
        </form>
      )}
    </motion.div>
  );
};

export default WithEvergreen;
