import React from "react";
import { Paper } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import asegura from "../../img/asegura.webp";
import housesallers from "../../img/housesallers.webp";
import masterclass from "../../img/masterclass.webp";
import protecc from "../../img/protecc.webp";
import protecc2 from "../../img/protecc2.webp";
import proteccauto from "../../img/proteccauto.webp";
import visitanos from "../../img/visitanos.webp";

const AdvertisementSlider = () => {
  const items = [
    {
      name: "asegura",
      description: "Seguros de vida, autos, gastos médicos, etc.",
      img: asegura,
      linkto: "mailto:operaciones@actius.mx",
    },
    {
      name: "housesallers",
      description: "Compra, vende o renta tu casa",
      img: housesallers,
    },
    {
      name: "masterclass",
      description: "Clases de cocina, música, etc.",
      img: masterclass,
    },
    {
      name: "protecc",
      description: "Protección de datos personales",
      img: protecc,
    },
    {
      name: "protecc2",
      description: "Protección de datos personales",
      img: protecc2,
    },
    {
      name: "proteccauto",
      description: "Seguros de autos",
      img: proteccauto,
    },
    {
      name: "visitanos",
      description: "Visítanos en nuestras oficinas",
      img: visitanos,
    },
  ];

  return (
    <Paper
      style={{
        marginBottom: "0px",
      }}
    >
      <Carousel
        autoPlay={true}
        animation="fade"
        indicators={false}
        timeout={5000}
        swipe={true}
        navButtonsAlwaysInvisible={true}
        cycleNavigation={true}
      >
        {items.map((item, i) => (
          <Item key={i} item={item} />
        ))}
      </Carousel>
    </Paper>
  );
};

function Item(props) {
  return (
    <Paper onClick={() => console.log("Click")}>
      <img
        src={props.item.img}
        alt="Advertisement"
        style={{
          width: "100%", // Cambiar el ancho a 100% para adaptarse al contenedor
          maxHeight: "50vh", // Establecer una altura máxima relativa a la altura de la pantalla
          objectFit: "cover",
          objectPosition: "top",
        }}
      />
    </Paper>
  );
}

export default AdvertisementSlider;
