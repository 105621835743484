import React, { useEffect, useState, useCallback } from "react";
import {
  Pane,
  Heading,
  Paragraph,
  Table,
  Select,
  Badge,
  Button,
  Dialog,
  Card,
  Label,
  IconButton
} from "evergreen-ui";
import { motion } from "framer-motion";
import axios from "axios";
import { initAxiosInterceptors } from "../../../util/authHelper";
import dayjs from "dayjs";
import "dayjs/locale/es";
import { FaSyncAlt, FaTrashAlt } from "react-icons/fa";
import { FaAngleLeft } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa";


initAxiosInterceptors();

const PagosTPV = ({ user }) => {
  const [tpvs, setTpvs] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(dayjs().month());
  const [selectedYear, setSelectedYear] = useState(dayjs().year());
  const [cajas, setCajas] = useState([]);
  const [showRenewModal, setShowRenewModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCaja, setSelectedCaja] = useState(null);

  dayjs.locale("es");

  const fetchTpvs = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/tpv`);
      setTpvs(response.data);
    } catch (error) {
      console.error("Error fetching tpvs:", error);
    }
  }, []);

  const getUsers = useCallback(async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/users/`);
      const usuariosNoAdmin = res.data.filter(
        (usuario) => usuario.role !== "admin"
      );
      setUsuarios(usuariosNoAdmin);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  }, []);

  useEffect(() => {
    fetchTpvs();
    getUsers();
  }, [fetchTpvs, getUsers]);

  const getFilteredAndSortedCajas = useCallback(() => {
    let cajas = [];
    tpvs.forEach((tpv) => {
      const user = usuarios.find((usuario) => usuario._id === tpv.usuario);
      tpv.sucursales.forEach((sucursal) => {
        sucursal.cajas.forEach((caja) => {
          const fechaRenovacion = caja.fecha_renovacion
            ? dayjs(caja.fecha_renovacion)
            : null;
          if (
            fechaRenovacion &&
            fechaRenovacion.month() === selectedMonth &&
            fechaRenovacion.year() === selectedYear
          ) {
            const remainingDays = fechaRenovacion.diff(dayjs(), "day");
            cajas.push({
              cajaId: caja.cajaId,
              nombre: caja.nombre,
              correo: caja.correo,
              fecha_renovacion: fechaRenovacion.format("DD-MM-YYYY"),
              estado: caja.estado,
              sucursal: sucursal.nombre,
              usuario: user ? user.nombre : "Desconocido",
              remainingDays,
            });
          } else if (!fechaRenovacion) {
            cajas.push({
              cajaId: caja.cajaId,
              nombre: caja.nombre,
              correo: caja.correo,
              fecha_renovacion: "Pendiente",
              estado: caja.estado,
              sucursal: sucursal.nombre,
              usuario: user ? user.nombre : "Desconocido",
              remainingDays: null,
            });
          }
        });
      });
    });

    return cajas.sort((a, b) => {
      if (a.fecha_renovacion === "Pendiente") return 1;
      if (b.fecha_renovacion === "Pendiente") return -1;
      return new Date(a.fecha_renovacion) - new Date(b.fecha_renovacion);
    });
  }, [tpvs, usuarios, selectedMonth, selectedYear]);

  useEffect(() => {
    if (tpvs.length && usuarios.length) {
      setCajas(getFilteredAndSortedCajas());
    }
  }, [tpvs, usuarios, selectedMonth, selectedYear, getFilteredAndSortedCajas]);

  const getBadgeColor = (days) => {
    if (days === null) return "neutral";
    if (days <= 7) return "red";
    if (days <= 30) return "orange";
    return "green";
  };

  const renewBox = async () => {
    try {
      // Buscar el TPV que contiene la caja a renovar
      const tpvToUpdate = tpvs.find((tpv) =>
        tpv.sucursales.some((sucursal) =>
          sucursal.cajas.some((c) => c.cajaId === selectedCaja.cajaId)
        )
      );

      // Verificar si se encontró el TPV
      if (tpvToUpdate) {
        // Actualizar la fecha de renovación de la caja
        tpvToUpdate.sucursales.forEach((sucursal) => {
          const cajaIndex = sucursal.cajas.findIndex(
            (c) => c.cajaId === selectedCaja.cajaId
          );
          if (cajaIndex !== -1) {
            sucursal.cajas[cajaIndex].fecha_renovacion = dayjs()
              .add(1, "year")
              .toISOString();
          }
        });
        console.log("TPV actualizado:", tpvToUpdate);
        // Enviar solo el TPV actualizado al servidor
        await axios.put(
          `${process.env.REACT_APP_API_URL}/tpv/${tpvToUpdate._id}`,
          tpvToUpdate
        );

        // Actualizar los datos locales
        fetchTpvs();
        getUsers();
        setShowRenewModal(false);
      } else {
        console.error("No se encontró el TPV que contiene la caja a renovar.");
      }
    } catch (error) {
      console.error("Error al renovar la caja:", error);
    }
  };

  return (
    <motion.div
      key={"tpv"}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{ backgroundColor: "#112240", minHeight: "100vh" }}
    >
      <Pane
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        padding={40}
        color="white"
        className="impulstpv-header"
      >
        <Heading size={800} color="white">
          TPV Impulspay
        </Heading>
      </Pane>
      <Pane
        padding={30}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Pane>
          <Heading size={600} color="white" textAlign="center">
            En esta sección podrás ver por mes las fechas de renovación de las
            cajas de todos los usuarios
          </Heading>
        </Pane>
        <Card
          display="flex"
          justifyContent="center"
          flexDirection="column"
          padding={20}
          marginTop={20}
          backgroundColor="rgba(255, 255, 255, 0.1)"
          borderRadius={20}
          width="100%"
          maxWidth={1200}
        >
          <Pane marginBottom={20} display="flex" justifyContent="space-between"
            alignItems="center"
          >
            <Label htmlFor="month-select" marginRight={10} color="white">
              Mes:
            </Label>
            <Select
              id="month-select"
              width={240}
              onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
              value={selectedMonth}
              marginBottom={20}
              backgroundColor="white"
              style={{
                fontSize: "20px",
                alignSelf: "center",
              }}
            >
              {Array.from({ length: 12 }).map((_, monthIndex) => (
                <option key={monthIndex} value={monthIndex}
                  style={{ backgroundColor: "#112240", color: "white",
                    fontSize: "20px"

                   }}
                >
                  {dayjs().month(monthIndex).format("MMMM")}
                </option>
              ))}
            </Select>
            <Pane display="flex" alignItems="center"
              justifyContent="center"
            >
              <IconButton
                icon={FaAngleLeft}
                onClick={() => setSelectedYear(selectedYear - 1)}
              />
              <Heading size={500} color="white" marginX={10}>
                {selectedYear}
              </Heading>
              <IconButton
                icon={FaAngleRight}
                onClick={() => setSelectedYear(selectedYear + 1)}
              />
            </Pane>
          </Pane>
          <Table>
            <Table.Head>
              <Table.TextHeaderCell>Usuario</Table.TextHeaderCell>
              <Table.TextHeaderCell>Sucursal</Table.TextHeaderCell>
              <Table.TextHeaderCell>Caja</Table.TextHeaderCell>
              <Table.TextHeaderCell>Correo</Table.TextHeaderCell>
              <Table.TextHeaderCell>Fecha Renovación</Table.TextHeaderCell>
              <Table.TextHeaderCell>Estado</Table.TextHeaderCell>
              <Table.TextHeaderCell>Días Restantes</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
              {cajas.map((caja, index) => (
                <Table.Row key={index}>
                  <Table.TextCell>{caja.usuario}</Table.TextCell>
                  <Table.TextCell>{caja.sucursal}</Table.TextCell>
                  <Table.TextCell>{caja.nombre}</Table.TextCell>
                  <Table.TextCell>{caja.correo}</Table.TextCell>
                  <Table.TextCell>{caja.fecha_renovacion}</Table.TextCell>
                  <Table.TextCell>{caja.estado}</Table.TextCell>
                  <Table.TextCell>
                    {caja.remainingDays !== null ? (
                      <Badge color={getBadgeColor(caja.remainingDays)}>
                        {caja.remainingDays} días
                      </Badge>
                    ) : (
                      <Badge color="neutral">Pendiente</Badge>
                    )}
                  </Table.TextCell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Card>
      </Pane>
      <Dialog
        isShown={showRenewModal}
        title="Renovar Caja"
        onCloseComplete={() => setShowRenewModal(false)}
        confirmLabel="Renovar"
        onConfirm={() => renewBox()}
      >
        <Paragraph>¿Estás seguro de que quieres renovar esta caja?</Paragraph>
      </Dialog>
      <Dialog
        isShown={showDeleteModal}
        title="Eliminar Caja"
        onCloseComplete={() => setShowDeleteModal(false)}
        confirmLabel="Eliminar"
        intent="danger"
      >
        <Paragraph>¿Estás seguro de que quieres eliminar esta caja?</Paragraph>
      </Dialog>
    </motion.div>
  );
};

export default PagosTPV;
